<template>
  <div :class="className" :style="{ height: height, width: width }"></div>
</template>

<script>
import * as echarts from 'echarts';
require('echarts/theme/macarons'); // echarts theme
import { debounce, LightenDarkenColor } from '@/utils';
import { colorlist } from '@/utils/defaultConstant';

export default {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '350px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      chart: null,
      sidebarElm: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val);
      }
    }
  },
  mounted() {
    this.initChart();
    if (this.autoResize) {
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize();
        }
      }, 100);
      window.addEventListener('resize', this.__resizeHandler);
    }

    // 监听侧边栏的变化
    this.sidebarElm = document.getElementsByClassName('sidebar-container')[0];
    this.sidebarElm &&
      this.sidebarElm.addEventListener(
        'transitionend',
        this.sidebarResizeHandler
      );
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    if (this.autoResize) {
      window.removeEventListener('resize', this.__resizeHandler);
    }

    this.sidebarElm &&
      this.sidebarElm.removeEventListener(
        'transitionend',
        this.sidebarResizeHandler
      );

    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    sidebarResizeHandler(e) {
      if (e.propertyName === 'width') {
        this.__resizeHandler();
      }
    },
    setOptions(data) {
      let series = [];
      let areaColor = [
        {
          start: 'rgba(128, 255, 165)',
          end: 'rgba(1, 191, 236)'
        },
        {
          start: 'rgba(0, 221, 255)',
          end: 'rgba(77, 119, 255)'
        },
        {
          start: 'rgba(55, 162, 255)',
          end: 'rgba(116, 21, 219)'
        },
        {
          start: 'rgba(255, 0, 135)',
          end: 'rgba(135, 0, 157)'
        },
        {
          start: 'rgba(255, 191, 0)',
          end: 'rgba(224, 62, 76)'
        },
        {
          start: 'rgba(60, 170, 69)',
          end: 'rgba(60, 200, 69)'
        },
        {
          start: 'rgba(126,192,238)',
          end: 'rgba(126,192,255)'
        },
        {
          start: 'rgba(19,98,173)',
          end: 'rgba(60, 200, 210)'
        }
      ];
      if (data.seriesData && data.seriesData.length) {
        data.seriesData.map((ele, index) => {
          let endColor = LightenDarkenColor(colorlist[index], 80);
          series.push({
            name: ele.name,
            type: 'line',
            stack: '总量',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: colorlist[index]// areaColor[index % areaColor.length].start
                },
                {
                  offset: 1,
                  color: endColor // areaColor[index % areaColor.length].end
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: ele.data
          });
        });
      }
      this.chart.setOption({
        color: colorlist, // ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
        title: {
          text: ''
        },
        // tooltip: {
        //   show: data.showTooltip ? data.showTooltip : false,
        //   trigger: 'axis',
        //   textStyle: {
        //     align: 'left'
        //   },
        //   backgroundColor: 'rgba(255,255,255,0.8)',
        //   axisPointer: {
        //     type: 'cross',
        //     label: {
        //       backgroundColor: '#6a7985' // #2961a7'
        //     }
        //   }
        // },
        legend: {
          data: data.legendData,
          icon: 'roundRect',
          bottom: data.legend ? data.legend.bottom : '',
          itemWidth: 20,
          itemHeight: 12,
          itemGap: 5,
          textStyle: {
            fontSize: 10,
            wordWrap: 'breakWord'
          },
          formatter: function(name) {
            if (name.length > 40) {
              return name.slice(0, 40) + '...';
            }
            return name;
          }
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
          // left: '1%',
          // right: '1%',
          // bottom: data.legend ? data.legend.gridbottom : '',
          left: '18',
          right: '25',
          top: data.legend && data.legend.bottom === 0 ? '15%' : '80',
          bottom: data.legend && data.legend.bottom === 0 ? '80' : '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: data.xAxis,
            axisLabel: {
              textStyle: { // 文字样式
                color: '#666',
                fontSize: 12
              }
            },
            axisLine: {
              lineStyle: {
                color: '#666',
                width: 1,
                type: 'solid'
              }
            },
            axisTick: {
              lineStyle: {
                color: '#666',
                width: 1,
                type: 'solid'
              }
            }
          }
        ],
        yAxis: [
          {
            name: data.yAxisName ? data.yAxisName : '',
            nameTextStyle: {
              color: '#aaa',
              nameLocation: 'start',
              padding: [0, 0, 0, -17]
            },
            type: 'value',
            minInterval: 1,
            max: function(value) {
              return Math.max(5, value.max);
            },
            show: (data.yAxis != null && data.yAxis.show != null) ? data.yAxis.show : true,
            axisLabel: {
              interval: 0, // 强制文字产生间隔
              rotate: 0, // 文字逆时针旋转45°
              textStyle: { // 文字样式
                color: '#666',
                fontSize: 12
              }
            },
            splitLine: {
              show: false
            },
            splitArea: {
              show: false,
              areaStyle: {
                color: '#ffffff'
              }
            }
          }
        ],
        series: series
      });
    },
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      this.setOptions(this.chartData);
    }
  }
};
</script>
