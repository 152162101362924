<template>
  <div class="box">
    <ul class="box position-list body-background">
      <template v-for="(item,index) in officeList">
        <!-- 任职状态 - 协会 -->
        <li v-if="item.type === 'associations'" :key="'assoc'+index">
          <span class="icon icon-association"><font-awesome-icon class="icon" :icon="['fa', 'briefcase-medical']" /></span>
          {{ item.association_name }}{{ item.branch === "NULL" ? "" : item.branch }}
          <div class="info-details">
            <span>{{ item.association_title }}</span>
          </div>
          <!-- <span class="time">{{ item.month }}</span> -->
        </li>
        <li v-if="item.type === 'journals'" :key="'jour'+index">
          <span class="icon icon-association"><font-awesome-icon class="icon" :icon="['fa', 'briefcase-medical']" /></span>
          {{ item.journal_name }}
          <div class="info-details">
            <span>{{ item.journal_title }}</span>
          </div>
          <span class="time">{{ item.month }}</span>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>

export default {
  props: {
    officeList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    back() {
      this.$emit('officeStatusFn', false);
    }
  }
};
</script>
<style lang="scss" scoped>
.swipe-wrapper {
  $height: 44px;
  $scroll-height: calc(100% - 44px);
  overflow-y: auto;
  .header {
    position: relative;
    height: $height;
    line-height: $height;
    text-align: center;
    background-color: #edf0f4;
    box-shadow: 0 1px 6px #ccc;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 5;
    .h-title {
      font-size: 14px;
    }
    .cubeic-back {
      font-size: 16px;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 15px;
      color: #2961a7;
    }
  }
  .scroll-container {
    height: $scroll-height;
  }
}
.position-list {
  li {
    padding: 10px;
    margin-top: 5px;
    background: #fff;
    font-size: 12px;
    color: #6e6e6d;
    border-radius: 0;
    text-align: left;
    // line-height: 20px;
    position: relative;
    span {
      display: inline-block;
      color: #b2b2b2;
    }
    .info-details {
      float: right;
      // display: inline-block;
    }
    .icon {
      display: inline-block;
      color: #fff !important;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      text-align: center;

      &.icon-wechat {
        background: #3caa45;
      }
      &.icon-interaction {
        background: #e34457;
      }
      &.icon-meeting {
        background: #b41bb3;
      }
      &.icon-association {
        background: #6c6c6c;
      }

      .icon {
        color: #fff;
        margin-top: 4px;
        width: 10px;
        height: 10px;
        display: inline-block;
      }
    }
  }
}
</style>
