export default {
  // tekeda
  // albumBucketName: 'tkio-test-cnbej-92867-dabhcpmicroseg-001',
  // region: 'cn-north-1',
  // accessKeyId: 'AKIA4ML3FIBKMJL4UZB2',
  // secretAccessKey: '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'

  // lundbeck
  albumBucketName: 'lundbeck-superrep-hcp360-sandbox',
  region: 'cn-northwest-1',
  accessKeyId: 'AKIA3SIRKMIYSJNHRNUC',
  secretAccessKey: '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'
};
