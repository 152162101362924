<template>
  <div>
    <ul class="activity-list body-background">
      <Nodata
        v-if="list.length === 0"
        :loading="loading"
        :empty-txt="$t('no_data')"
      />
      <template v-if="list.length">
        <li
          v-for="(item, index) in list"
          :key="index"
          @click="lookActivityDetail(item)"
        >
          <span class="type" :class="item.type">{{ item.one }}</span>
          {{ item.study ? item.study : item.one }}<br />
          <div>
            <div v-if="item.one === '同事'">
              <span>{{ item.institution_name_cooperation }}</span>
              <span>{{ item.department_name_cooperation }}</span>
            </div>
            <span>{{ item.two }}</span>
          </div>

          <span v-if="item.month" class="time">{{ item.month }}</span>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
import Nodata from '@/components/Nodata';
export default {
  components: {
    Nodata,
  },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    lookActivityDetail(item) {
      this.$emit('lookActivityDetail', item);
    },
  },
};
</script>
<style lang="scss" scoped></style>
