<template>
  <div class="box table-list">
    <table>
      <tr>
        <td class="filed-name">备案号:</td>
        <td class="filed-value" colspan="2">
          {{ nmpaDetail[0].record_no }}
        </td>
      </tr>
      <tr>
        <td class="filed-name">机构名称:</td>
        <td class="filed-value" colspan="2">
          {{ nmpaDetail[0].hco_name }}
        </td>
      </tr>
      <tr>
        <td class="filed-name">机构级别:</td>
        <td class="filed-value" colspan="2">
          {{ nmpaDetail[0].hco_tier }}
        </td>
      </tr>
      <tr>
        <td class="filed-name">地址:</td>
        <td class="filed-value" colspan="2">
          {{ nmpaDetail[0].hco_address }}
        </td>
      </tr>
      <tr>
        <td class="filed-name">联系人</td>
        <td class="filed-value" colspan="2">
          {{ nmpaDetail[0].contact_name }}
        </td>
      </tr>
      <tr>
        <td class="filed-name">联系方式</td>
        <td class="filed-value" colspan="2">
          {{ nmpaDetail[0].contact_phone }}
        </td>
      </tr>
      <tr>
        <td class="filed-name">备案时间</td>
        <td class="filed-value" colspan="2">
          {{ nmpaDetail[0].record_time }}
        </td>
      </tr>
      <tr>
        <td class="filed-name">科研开放日时间</td>
        <td class="filed-value" colspan="2">
          {{ getDate(nmpaDetail[0].scientific_time) }}
        </td>
      </tr>
      <tr>
        <td class="filed-name">备案状态</td>
        <td class="filed-value" colspan="2">
          {{ nmpaDetail[0].record_status }}
        </td>
      </tr>
      <tr>
        <td class="filed-name">数据源链接</td>
        <td class="filed-value" colspan="2">
          {{ nmpaDetail[0].record_url }}
        </td>
      </tr>
      <tr>
        <td style="font-size: 14px" colspan="3">专业备案信息:</td>
      </tr>
      <tr>
        <td class="filed-name">专业名称</td>
        <td class="filed-name">主要研究者</td>
        <td class="filed-name">职称</td>
      </tr>
      <tr v-for="(item, index) in nmpaDetail" :key="index">
        <td class="filed-name">{{ item.department }}</td>
        <td class="filed-name">{{ item.department_pi }}</td>
        <td class="filed-name">{{ item.title }}</td>
      </tr>
    </table>

    <Nodata
      v-if="!nmpaDetail.length"
      :loading="loading"
      :empty-txt="$t('no_data')"
    />
  </div>
</template>
<script>
import * as py from "@/api/Physicians";
import Nodata from "@/components/Nodata";
import * as moment from "moment";

export default {
  components: {
    Nodata,
  },
  props: {
    physicianId: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      loading: false,
      nmpaDetail: [
        // {
        //   view_key: "000138be8e652383a379b6c27be3fc54",
        //   physician_id: "MDMD100085517",
        //   institution_id: "H15000008",
        //   contact_type: "器械",
        //   record_no: "械临机构备201800674",
        //   hco_name: "赤峰市医院",
        //   hco_tier: "三级",
        //   hco_address: "内蒙古自治区赤峰市红山区昭乌达路中段1号",
        //   department: "内科-心血管内科专业",
        //   department_pi: "王东方",
        //   title: "主任医师",
        //   contact_name: "王旭梅",
        //   contact_phone: "15049919669",
        //   status: null,
        //   record_time: "2021-04-29T00:00:00+08:00",
        //   record_status: "已备案",
        //   nmpa_id: "000138be8e652383a379b6c27be3fc54",
        //   record_url:
        //     "https://beian.cfdi.org.cn/CTMDS/pub/PUB010100.do?method=handle04&compId=1C8A77B6C0A802331E4A1FED3423D99C",
        //   consumption_time: "2022-08-04T00:59:11.497+08:00",
        //   scientific_time: null,
        // },
      ],
    };
  },
  mounted() {
    this.getNmpaDetail();
  },
  methods: {
    getNmpaDetail() {
      this.loading = true;
      py.getPhysicianNMPA(this.physicianId).then((res) => {
        if (res && res.value.length) {
          this.nmpaDetail = res.value;
        }
        this.loading = false;
      });
    },
    getDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>
<style lang="scss" scoped>
.filed-name {
  width: 160px;
  padding: 5px;
  text-align: center;
}
.filed-value {
  padding: 5px;
}
</style>
