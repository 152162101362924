export default {
  methods: {
    addFavoriteFn(item, status) {
      console.info('------');
      this.$store.dispatch('setDialogStatus', true);
      if (item.is_favorite) { // 已关注
        if (this.isFirst === 'yes') {
          return;
        }
        this.$createDialog({
          type: 'confirm',
          showClose: true,
          title: this.$t('tips_title'),
          content: this.$t('make_sure_follow'),
          confirmBtn: {
            text: this.$t('confirm_txt'),
            active: true,
            disabled: false,
            href: 'javascript:;'
          },
          cancelBtn: {
            text: this.$t('cancel_txt'),
            active: false,
            disabled: false,
            href: 'javascript:;'
          },
          onConfirm: () => {
            if (status) {
              this.$emit('addFollowFn', item);
            } else {
              this.addFn(item);
            }
          },
          onCancel: () => {
            this.$store.dispatch('setDialogStatus', false);
          }
        }).show();
      } else { // 未关注
        if (!item.perception_type) {
          this.checkObj.selecteda = '';
          this.checkObj.selectedb = '';
          this.stylePopupStatus = true;
        } else {
          if (status) {
            this.$emit('addFollowFn', item);
          } else {
            this.addFn(item);
          }
        }
      }
    },
    gotoHcp360(item, status) {
      // this.$store.dispatch('userSystemLog', {
      //   page_param: item.physician_name,
      //   page_uri: this.$route.path,
      //   page: this.$t('btn_go_hcp')
      // });
      this.$store.dispatch('setDialogStatus', true);
      this.$createDialog({
        type: 'confirm',
        showClose: true,
        title: this.$t('tips_title'),
        content: this.$t('go_hcp'),
        confirmBtn: {
          text: this.$t('yes'),
          active: true,
          disabled: false,
          href: 'javascript:;'
        },
        cancelBtn: {
          text: this.$t('no'),
          active: false,
          disabled: false,
          href: 'javascript:;'
        },
        onConfirm: () => {
          if (this.isFirst === 'yes') {
            this.settingStep(this.step);
          }
          this.$store.dispatch('setDialogStatus', false);
          // this.$router.push({
          //   path: '/hcp/detail',
          //   query: {
          //     physician_id: item.physician_id
          //   }
          // });
          window.location.href = '/hcp/detail?physician_id=' + item.physician_id;
        },
        onCancel: () => {
          if (status) {
            this.$store.dispatch('setDialogStatus', false);
          }
        }
      }).show();
    }
  }
};
