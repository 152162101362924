<template>
  <div class="swipe-wrapper">
    <div class="back">
      <i class="iconfont icon-jiantou"></i>
      <p style="font-weight: 600">请点击屏幕右上角 [ ··· ]</p>
      <p>
        在
        <i class="iconfont icon-browser"></i>
        浏览器打开
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.swipe-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f9f9fb;
  z-index: 99;
  .back {
    position: relative;
    padding-top: 50px;
    color: #333;
    font-size: 22px;
    text-align: center;
    .icon-browser {
      font-size: 60px;
      color: #1487f0;
    }
  }
}
</style>
