<template>
  <div class="parting">
    <div>
      <CommonHeader
        title="医生分型"
        icon-url="/lundbeck/NBA/physician.png"
        :no-feedback="true"
      >
        <div slot="before">
          <img
            v-if="!isPhysicianFirst && canEditStatus"
            src="@/assets/lundbeck/NBA/edit.png"
            alt=""
            width="16"
            style="margin-top: 6px"
            @click="openPhysicianDialog()"
          />
        </div>
      </CommonHeader>
      <div v-if="isPhysicianFirst">
        <p class="tip-text">请先回答问题来确认医生分型</p>
        <van-button
          v-if="canEditStatus"
          class="tip-button"
          round
          type="info"
          @click="openPhysicianDialog()"
        >
          立即进行问答
        </van-button>
      </div>
      <div v-else>
        <div>
          <div class="parting-type">
            <div
              :class="
                physicianSelectedParting === 'D'
                  ? 'parting-type-selected'
                  : 'parting-type-unselected'
              "
            >
              <div @click="openPartingState('D')">
                <div v-if="physicianSelectedParting === 'D'">
                  <img :src="star" alt="" width="14" />
                </div>
                <p>症状控制 - 初识</p>
              </div>
            </div>
            <div
              :class="
                physicianSelectedParting === 'C'
                  ? 'parting-type-selected'
                  : 'parting-type-unselected'
              "
            >
              <div @click="openPartingState('C')">
                <div v-if="physicianSelectedParting === 'C'">
                  <img :src="star" alt="" width="14" />
                </div>
                <p>长期获益 - 尝试</p>
              </div>
            </div>
            <div
              :class="
                physicianSelectedParting === 'B'
                  ? 'parting-type-selected'
                  : 'parting-type-unselected'
              "
            >
              <div @click="openPartingState('B')">
                <div v-if="physicianSelectedParting === 'B'">
                  <img :src="star" alt="" width="14" />
                </div>
                <p>长期获益 - 认可</p>
              </div>
            </div>
            <div
              :class="
                physicianSelectedParting === 'A'
                  ? 'parting-type-selected'
                  : 'parting-type-unselected'
              "
            >
              <div @click="openPartingState('A')">
                <div v-if="physicianSelectedParting === 'A'">
                  <img :src="star" alt="" width="14" />
                </div>
                <p>长期获益 - 倡导</p>
              </div>
            </div>
          </div>
          <img src="@/assets/svg/xAxis.svg" alt="" style="width: 100%;" />
          <div class="parting-type-x">
            <p>低</p>
            <p>医生对于长期获益的认可度</p>
            <p>高</p>
          </div>
        </div>
        <div class="parting-type-history">
          <div class="history-title">
            <div class="title-flag"></div>
            <span class="title-text">分型历史</span>
          </div>
          <div class="history-content">
            <div
              v-for="item in showPhysicianPartingHistorys"
              :key="item.physician_typing_physician_id"
            >
              <div class="history-date">
                <div class="date-flag"></div>
                <span class="date-text">{{ getDate(item.create_time) }}</span>
              </div>
              <div class="history-text">
                <p>{{ item.result }}</p>
                <p class="modified-name">
                  {{ item.modify_user_name ?item.modify_user_name:item.modify_user_id }}
                </p>
              </div>
            </div>
            <div v-if="showExpand">
              <div
                v-if="!physicianHistoryExpandStatus"
                class="expend-btn"
                @click="expendPhysicianHistory"
              >
                <span class="arrow-down-flag"></span>
                展开
              </div>
              <div v-else class="expend-btn" @click="expendPhysicianHistory">
                <span class="arrow-up-flag"></span>
                收起
              </div>
            </div>
          </div>
        </div>
        <div class="parting-type-action">
          <div class="action-title">
            <div class="title-flag"></div>
            <span class="title-text">行动建议</span>
          </div>
          <div>
            <div class="action-plan">
              <div v-if="physicianSelectedParting === 'A'">
                <div class="action-progress">
                  <p>建议目标行为</p>
                </div>
                <p class="action-text">
                  <span class="action-flag"></span>
                  早发患者首选安齐来
                </p>

                <div class="action-progress">
                  <p>关键信息</p>
                </div>
                <p class="action-text">
                  <span class="action-flag"></span>
                  差异化竞争：建立初始治疗新选择
                </p>
                <p class="action-text">
                  <span class="action-flag"></span>
                  观念强化：长期获益的重要性（神经保护）
                </p>
                <p class="action-text">
                  <span class="action-flag"></span>
                  观念强化：安齐来可提供长期获益
                </p>
                <p class="action-text">
                  <span class="action-flag"></span>
                  观念强化：疗效与安全性的平衡
                </p>
                <div class="proof-link">
                  <h4>相关资料</h4>
                  <p class="link">
                    <span class="action-flag"></span>
                    <a
                      @click="
                        lookDetail(
                          'LFile/nba_content/2020中国帕金森病治疗指南（第四版）更新解读-美化版2-0608-Final_1663939546000.pdf'
                        )
                      "
                    >
                      2020中国帕金森病治疗指南（第四版）更新解读-美化版2-0608-Final
                      (v1.0)
                    </a>
                  </p>
                  <p class="link">
                    <span class="action-flag"></span>
                    <a
                      @click="
                        lookDetail(
                          'LFile/nba_content/质控项目-帕金森病全程管理新进展_1663939841000.pdf'
                        )
                      "
                    >
                      质控项目-帕金森病全程管理新进展 (v1.0)
                    </a>
                  </p>
                  <p class="link">
                    <span class="action-flag"></span>
                    <a
                      @click="
                        lookDetail(
                          'LFile/nba_content/4-从指南更新看帕金森病的全程管理--MAO-BI应用的长期获益-0623 2022-8-26 103812 1_1663939134000.pdf'
                        )
                      "
                    >
                      4-从指南更新看帕金森病的全程管理—MAO-BI应用的长期获益-CNCT-001833_有效期至2023年7月
                      (v1.0)
                    </a>
                  </p>
                  <p class="link">
                    <span class="action-flag"></span>
                    <a
                      @click="
                        lookDetail(
                          'LFile/nba_content/CMA-5. PD经典研究：从结果回溯研究设计-V1.0-20220526_1663939753000.pdf'
                        )
                      "
                    >
                      CMA-5. PD经典研究：从结果回溯研究设计-V1.0-20220526 (v1.0)
                    </a>
                  </p>
                </div>
              </div>
              <div v-if="physicianSelectedParting === 'B'">
                <div class="action-progress">
                  <p>建议目标行为</p>
                </div>
                <p class="action-text">
                  <span class="action-flag"></span>
                  LD首选添加安齐来
                </p>

                <div class="action-progress">
                  <p>关键信息</p>
                </div>
                <p class="action-text">
                  <span class="action-flag"></span>
                  差异化竞争：区隔咪多吡
                </p>
                <p class="action-text">
                  <span class="action-flag"></span>
                  观念强化：长期获益的重要性（神经保护）
                </p>
                <p class="action-text">
                  <span class="action-flag"></span>
                  观念强化：疗效与安全性全面占优
                </p>
                <div class="proof-link">
                  <h4>相关资料</h4>
                  <p class="link">
                    <span class="action-flag"></span>
                    <a
                      @click="
                        lookDetail(
                          'LFile/nba_content/2020中国帕金森病治疗指南（第四版）更新解读-美化版2-0608-Final_1663939546000.pdf'
                        )
                      "
                    >
                      2020中国帕金森病治疗指南（第四版）更新解读-美化版2-0608-Final
                      (v1.0)
                    </a>
                  </p>
                  <p class="link">
                    <span class="action-flag"></span>
                    <a
                      @click="
                        lookDetail(
                          'LFile/nba_content/MAO-B抑制剂在PD治疗中的优化应用-V3-20220725（加页码版）(1)_1663939782000.pdf'
                        )
                      "
                    >
                      MAO-B抑制剂在PD治疗中的优化应用-V3-20220725（加页码版）(v1.0)
                    </a>
                  </p>
                </div>
              </div>
              <div v-if="physicianSelectedParting === 'C'">
                <div class="action-progress">
                  <p>建议目标行为</p>
                </div>
                <p class="action-text">
                  <span class="action-flag"></span>
                  LD首选添加安齐来
                </p>

                <div class="action-progress">
                  <p>关键信息</p>
                </div>
                <p class="action-text">
                  <span class="action-flag"></span>
                  差异化竞争：区隔DA
                </p>
                <p class="action-text">
                  <span class="action-flag"></span>
                  观念强化：长期获益的重要性（神经保护）
                </p>
                <p class="action-text">
                  <span class="action-flag"></span>
                  观念强化：长期获益MAO-BI优于DA
                </p>
                <p class="action-text">
                  <span class="action-flag"></span>
                  观念强化：运动波动、安全性
                </p>
                <div class="proof-link">
                  <h4>相关资料</h4>
                  <p class="link">
                    <span class="action-flag"></span>
                    <a
                      @click="
                        lookDetail(
                          'LFile/nba_content/2020中国帕金森病治疗指南（第四版）更新解读-美化版2-0608-Final_1663939546000.pdf'
                        )
                      "
                    >
                      2020中国帕金森病治疗指南（第四版）更新解读-美化版2-0608-Final
                      (v1.0)
                    </a>
                  </p>
                  <p class="link">
                    <span class="action-flag"></span>
                    <a
                      @click="
                        lookDetail(
                          'LFile/nba_content/质控项目-帕金森病全程管理新进展_1663939841000.pdf'
                        )
                      "
                    >
                      质控项目-帕金森病全程管理新进展 (v1.0)
                    </a>
                  </p>
                  <p class="link">
                    <span class="action-flag"></span>
                    <a
                      @click="
                        lookDetail(
                          'LFile/nba_content/CMA-4. 基于CDS的运动波动管理-V1.0-20220526_1663939724000.pdf'
                        )
                      "
                    >
                      CMA-4. 基于CDS的运动波动管理-V1.0-20220526 (v1.0)
                    </a>
                  </p>
                  <p class="link">
                    <span class="action-flag"></span>
                    <a
                      @click="
                        lookDetail(
                          'LFile/nba_content/质控项目-帕金森病共病药物管理-关注多重用药风险_1663939826000.pdf'
                        )
                      "
                    >
                      质控项目-帕金森病共病药物管理-关注多重用药风险 (v1.0)
                    </a>
                  </p>
                </div>
              </div>
              <div v-if="physicianSelectedParting === 'D'">
                <div class="action-progress">
                  <p>建议目标行为</p>
                </div>
                <p class="action-text">
                  <span class="action-flag"></span>
                  LD首选添加安齐来
                </p>
                <p class="action-text">
                  <span class="action-flag"></span>
                  LD + DA + 安齐来
                </p>

                <div class="action-progress">
                  <p>关键信息</p>
                </div>
                <p class="action-text">
                  <span class="action-flag"></span>
                  差异化竞争：区隔DA
                </p>
                <p class="action-text">
                  <span class="action-flag"></span>
                  观念强化：步态障碍控制优选MAO-BI
                </p>
                <p class="action-text">
                  <span class="action-flag"></span>
                  观念强化：运动波动、安全性
                </p>
                <p class="action-text">
                  <span class="action-flag"></span>
                  观念强化：长期获益的重要性（神经保护）
                </p>
                <div class="proof-link">
                  <h4>相关资料</h4>
                  <p class="link">
                    <span class="action-flag"></span>
                    <a
                      @click="
                        lookDetail(
                          'LFile/nba_content/灵北-雷沙吉兰-帕金森步态障碍管理初稿-9.14  -  Repaired_1663939805000.pdf'
                        )
                      "
                    >
                      灵北-雷沙吉兰-帕金森步态障碍管理初稿-9.14 - Repaired
                      (v1.0)
                    </a>
                  </p>
                  <p class="link">
                    <span class="action-flag"></span>
                    <a
                      @click="
                        lookDetail(
                          'LFile/nba_content/CMA-3.帕金森病运动波动的临床识别与管理-V1.0-20220526_1663939688000.pdf'
                        )
                      "
                    >
                      CMA-3.帕金森病运动波动的临床识别与管理-V1.0-20220526
                      (v1.0)
                    </a>
                  </p>
                  <p class="link">
                    <span class="action-flag"></span>
                    <a
                      @click="
                        lookDetail(
                          'LFile/nba_content/CMA-4. 基于CDS的运动波动管理-V1.0-20220526_1663939724000.pdf'
                        )
                      "
                    >
                      CMA-4. 基于CDS的运动波动管理-V1.0-20220526 (v1.0)
                    </a>
                  </p>
                  <p class="link">
                    <span class="action-flag"></span>
                    <a
                      @click="
                        lookDetail(
                          'LFile/nba_content/2020中国帕金森病治疗指南（第四版）更新解读-美化版2-0608-Final_1663939546000.pdf'
                        )
                      "
                    >
                      2020中国帕金森病治疗指南（第四版）更新解读-美化版2-0608-Final
                      (v1.0)
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <van-dialog
        v-model="physicianDialogShow"
        title="医生分型问答"
        theme="round-button"
        confirm-button-color="#0062b9"
        :before-close="beforePhysicianClose"
        @confirm="confirmPhysicianAnswer()"
      >
        <div class="diglog-close" @click="closeDiglog()">×</div>
        <div class="diglog-questions">
          <div class="question">
            <p class="question-text">
              1.目标医生是否认为对于帕金森患者的治疗，长期获益是否是处方决策的重要因素?
              <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group
              v-model="questionAnswers.firstAnswer"
              direction="horizontal"
              class="answer"
            >
              <van-radio name="true" style="margin-right: 30%">是</van-radio>
              <van-radio
                name="false"
                @click="
                  () => {
                    questionAnswers.secondAnswer = null;
                    questionAnswers.thirdAnswer = null;
                  }
                "
              >
                否
              </van-radio>
            </van-radio-group>
          </div>
          <div v-if="questionAnswers.firstAnswer == 'true'" class="question">
            <p class="question-text">
              2.目前医生二线加用MAO-BI和DA的情况大致如何？
              <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group v-model="questionAnswers.secondAnswer">
              <van-radio name="true">
                MAO-BI ≥DA（较多使用MAO-BI，或二者相近）
              </van-radio>
              <van-radio
                name="false"
                @click="
                  () => {
                    questionAnswers.thirdAnswer = null;
                  }
                "
              >
                DA>MAO-BI (较多使用DA)
              </van-radio>
            </van-radio-group>
          </div>
          <div v-if="questionAnswers.secondAnswer == 'true'" class="question">
            <p class="question-text">
              3.医生是否会为超过10%的新患者，一线初始处方MAO-BI？
              <span style="color: #cc4b47">*</span>
            </p>
            <van-radio-group
              v-model="questionAnswers.thirdAnswer"
              direction="horizontal"
              class="answer"
            >
              <van-radio name="true" style="margin-right: 30%">是</van-radio>
              <van-radio name="false">否</van-radio>
            </van-radio-group>
          </div>
          <p v-if="physicianAnswerTips" style="color: #cc4b47">
            这是必答题，请选择答案！
          </p>
        </div>
      </van-dialog>
      <van-dialog
        v-model="partingShow"
        title="分型说明"
        theme="round-button"
        confirm-button-color="#0062b9"
        @confirm="
          () => {
            partingShow = false;
          }
        "
      >
        <div style="padding: 20px">
          <div class="diglog-close" @click="closeDiglog()">×</div>
          <div v-if="showType === 'A'" class="parting-type-action">
            <div class="action-progress">
              <p>长期获益 - 倡导</p>
            </div>
            <p class="action-text">
              <span class="action-flag"></span>
              长期获益是其处方决策的重要因素
            </p>
            <p class="action-text">
              <span class="action-flag"></span>
              为合适的患者一线初始处方安齐来
            </p>
            <p class="action-text">
              <span class="action-flag"></span>
              二线加用MAO-BI高于DA
            </p>
          </div>
          <div v-if="showType === 'B'" class="parting-type-action">
            <div class="action-progress">
              <p>长期获益 - 认可</p>
            </div>
            <p class="action-text">
              <span class="action-flag"></span>
              长期获益是其处方决策的重要因素
            </p>
            <p class="action-text">
              <span class="action-flag"></span>
              认为MAO-BI优于DA
            </p>
            <p class="action-text">
              <span class="action-flag"></span>
              二线加用MAO-BI高于或近似DA
            </p>
          </div>
          <div v-if="showType === 'C'" class="parting-type-action">
            <div class="action-progress">
              <p>长期获益 - 尝试</p>
            </div>
            <p class="action-text">
              <span class="action-flag"></span>
              长期获益是其处方决策的重要因素
            </p>
            <p class="action-text">
              <span class="action-flag"></span>
              对MAO-BI长期获益认识不足
            </p>
            <p class="action-text">
              <span class="action-flag"></span>
              二线加用MAO-BI远少于DA
            </p>
          </div>
          <div v-if="showType === 'D'" class="parting-type-action">
            <div class="action-progress">
              <p>症状控制 - 初识</p>
            </div>
            <p class="action-text">
              <span class="action-flag"></span>
              认为尽快控制症状更重要
            </p>
            <p class="action-text">
              <span class="action-flag"></span>
              二线加用MAO-BI低于DA
            </p>
          </div>
        </div>
      </van-dialog>
    </div>
    <swipeDialog v-if="detailStatus" title="详情" @closeFn="closeFn">
      <van-loading v-if="fileLoading" class="loading">加载中...</van-loading>
      <pdfComponent v-if="pdfLink" :url="pdfLink" :pdf-key="pdfKey" />
      <!-- <van-loading v-if="fileLoading" class="loading">加载中...</van-loading>
      <div v-if="!fileLoading">
        <a :href="pdfLink" download>
          <img class="download" src="@/assets/nba/down.png" width="20" alt />
        </a>
        <pdf v-for="i in numPages" :key="i" :src="pdfLink" :page="i" />
      </div> -->
    </swipeDialog>
  </div>
</template>

<script>
import CommonHeader from "@/components/CommonHeader";
import { Button, RadioGroup, Radio, Loading } from "vant";
import * as py from "@/api/Physicians";
import { getLocalStorage } from "@/utils";
import * as moment from "moment";
import pdf from "vue-pdf";
import swipeDialog from "@/views/layout/swipeDialog";
import { s3Service } from "@/utils/aws/index";
import pdfComponent from "../pdfComponent.vue";
import { getMimeTypeBySuffix } from "@/utils/index";
export default {
  name: "Azilect",
  components: {
    CommonHeader,
    vanButton: Button,
    vanRadioGroup: RadioGroup,
    vanRadio: Radio,
    swipeDialog,
    pdfComponent,
    vanLoading: Loading,
    // pdf,
  },
  props: {
    productId: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      loading: false,
      isPhysicianFirst: true,
      physicianDialogShow: false,
      questionAnswers: {
        firstAnswer: null,
        secondAnswer: null,
        thirdAnswer: null,
      },
      physicianPartings: [],
      showPhysicianPartingHistorys: [],
      showExpand: false,
      physicianHistoryExpandStatus: false,
      physicianAnswerTips: false,
      physicianSelectedParting: "",
      partingShow: false,
      star: require("@/assets/lundbeck/star.png"),
      showType: "",
      numPages: 0,
      pdfLink: "",
      pdfKey: "",
      detailStatus: false,
      fileLoading: false,
      canEditStatus: false
    };
  },
  mounted() {
    if (getLocalStorage('territory_type') === 'MR') {
      this.canEditStatus = true;
    }
    this.getPhysicianTyping();
  },
  methods: {
    getPhysicianTyping() {
      let params = {
        physician_id: this.$route.query.physician_id,
        product_id: this.productId,
        territory_id: getLocalStorage("territory_id"),
      };
      py.getPhysicianTyping(params).then((res) => {
        if (res && res.value.length) {
          this.physicianPartings = res.value;

          if (this.physicianPartings.length > 0) {
            this.isPhysicianFirst = false;
            this.questionAnswers = JSON.parse(
              this.physicianPartings[0].content
            );
            if (
              !this.physicianHistoryExpandStatus &&
              this.physicianPartings.length > 2
            ) {
              this.showPhysicianPartingHistorys = this.physicianPartings.slice(
                0,
                2
              );
              this.showExpand = true;
            } else {
              this.showPhysicianPartingHistorys = this.physicianPartings;
              this.showExpand = false;
            }
            this.getPhysicianPartingResult();
          } else {
            this.isPhysicianFirst = true;
          }
        }
      });
    },
    getPhysicianPartingResult() {
      let result = "";
      if (this.questionAnswers.firstAnswer === "true") {
        if (this.questionAnswers.secondAnswer === "true") {
          if (this.questionAnswers.thirdAnswer === "true") {
            this.physicianSelectedParting = "A";
            result = "长期获益 - 一线";
          } else if (this.questionAnswers.thirdAnswer === "false") {
            this.physicianSelectedParting = "B";
            result = "长期获益 - 认可";
          }
        } else if (this.questionAnswers.secondAnswer === "false") {
          this.physicianSelectedParting = "C";
          result = "长期获益 - 尝试";
        }
      } else if (this.questionAnswers.firstAnswer === "false") {
        this.physicianSelectedParting = "D";
        result = "症状控制 - 初识";
      }
      return result;
    },
    openPhysicianDialog() {
      this.physicianAnswerTips = false;
      if (this.isPhysicianFirst) {
        this.questionAnswers = {
          firstAnswer: null,
          secondAnswer: null,
          thirdAnswer: null,
        };
      }
      if (this.physicianPartings.length > 0) {
        this.questionAnswers = JSON.parse(
          this.physicianPartings[0].content
        );
      }
      this.physicianDialogShow = true;
    },
    beforePhysicianClose(action, done) {
      if (action === "confirm" && this.physicianDialogShow) {
        done(false);
      } else {
        done();
      }
    },
    closeDiglog() {
      this.partingShow = false;
      this.physicianDialogShow = false;
    },
    confirmPhysicianAnswer() {
      if (
        this.questionAnswers.firstAnswer == null ||
        (this.questionAnswers.firstAnswer === "true" &&
          this.questionAnswers.secondAnswer == null) ||
        (this.questionAnswers.firstAnswer === "true" &&
          this.questionAnswers.secondAnswer === "true" &&
          this.questionAnswers.thirdAnswer == null)
      ) {
        this.physicianAnswerTips = true;
        return;
      }
      let uName = getLocalStorage("user_name");
      let params = {
        physician_id: this.$route.query.physician_id,
        content: JSON.stringify(this.questionAnswers),
        user_id: getLocalStorage("user_id"),
        territory_id: getLocalStorage("territory_id"),
        create_time: Date.parse(new Date()),
        product_id: this.productId,
        result: this.getPhysicianPartingResult(),
        modify_user_id: getLocalStorage("user_id"),
        modify_user_name: uName !== 'null' ? uName : getLocalStorage("user_id"),
      };
      py.setPhysicianTyping(params).then((res) => {
        if (res.is_success) {
          this.$toast.success("提交成功");
          this.hospitalDialogShow = false;
          setTimeout(() => {
            this.getPhysicianTyping();
          }, 2);
        }
      });
      this.physicianDialogShow = false;
    },
    getDate(date) {
      return moment(new Date(+date)).format("YYYY-MM-DD");
    },
    expendPhysicianHistory() {
      if (
        this.physicianHistoryExpandStatus &&
        this.physicianPartings.length > 2
      ) {
        this.showPhysicianPartingHistorys = this.physicianPartings.slice(0, 2);
      } else {
        this.showPhysicianPartingHistorys = this.physicianPartings;
      }
      this.physicianHistoryExpandStatus = !this.physicianHistoryExpandStatus;
    },
    openPartingState(parting) {
      this.showType = parting;
      this.partingShow = true;
    },
    lookDetail(key) {
      this.$store.dispatch("setDialogStatus", true);
      this.detailStatus = true;
      this.fileLoading = true;

      if (this.detailStatus) {
        this.getPictureUrl(key);
      }
    },
    closeFn() {
      this.$store.dispatch("setDialogStatus", false);
      this.detailStatus = false;
      this.pdfLink = null;
      this.pdfKey = null;
    },
    getPictureUrl(key) {
      py.getS3ByKey(key).then((res) => {
        let currentMimeType = getMimeTypeBySuffix(
          key.split(".").pop().toLowerCase()
        );
        let blob = new Blob([res.data], {
          type: currentMimeType,
        });
        this.pdfKey = key;
        this.pdfLink = window.URL.createObjectURL(blob);
        this.fileLoading = false;
        // if (this.pdfLink) {
        //   this.getNumPages(this.pdfLink);
        // }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.parting {
  .tip-text {
    font-size: 14px;
    font-family: Arial;
    font-weight: 400;
    color: #2c3034;
    opacity: 1;
    margin: 40px auto 20px;
  }
  .tip-button {
    width: 160px;
    height: 40px;
    background: #1b75bc;
    opacity: 1;
    border-radius: 500px;
    margin-bottom: 20px;
  }
  .diglog-close {
    position: absolute;
    font-size: 30px;
    top: 25px;
    right: 20px;
  }
  .diglog-questions {
    padding: 20px;
    .question {
      margin-bottom: 20px;
      .question-text {
        font-size: 14px;
        font-family: Arial;
        font-weight: 700;
        line-height: 1;
        color: #2c3034;
        opacity: 1;
        text-align: left;
        margin-bottom: 10px;
      }
      .answer {
        display: flex;
        justify-content: center;
      }
    }
  }
  .parting-type {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    .parting-type-unselected {
      width: 74px;
      height: 80px;
      background: linear-gradient(46deg, #e8f4ff 0%, #c4e3ff 100%);
      opacity: 1;
      border-radius: 4px;
      margin: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        width: 50px;
        line-height: 1.5;
        color: #0062b9;
      }
    }
    .parting-type-selected {
      display: flex;
      align-items: center;
      width: 74px;
      height: 100px;
      background: linear-gradient(46deg, #52acff 0%, #0d7cfd 100%);
      opacity: 1;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        width: 50px;
        line-height: 1.5;
        color: #ffffff;
      }
    }
  }
  .parting-type-x {
    display: flex;
    justify-content: space-around;
    color: #838a95;
    p {
      display: inline-block;
    }
  }

  .title-flag {
    width: 6px;
    height: 14px;
    background: #1b75bc;
    opacity: 1;
    border-radius: 4px;
  }
  .title-text {
    height: 22px;
    font-size: 16px;
    font-family: Arial;
    font-weight: 700;
    color: #2c3034;
    opacity: 1;
    margin-left: 5px;
  }
  .parting-type-action {
    margin-top: 10px;
    .action-title {
      display: flex;
      align-items: baseline;
      height: 20px;
    }
    .action-plan {
      padding: 10px;
    }

    .action-progress {
      display: flex;
      align-items: center;
      height: 30px;
      background: rgba(0, 98, 185, 0.08);
      border-radius: 4px;
      margin-bottom: 20px;
      p {
        color: #0062b9;
        font-size: 14px;
        font-family: Arial;
        font-weight: 700;
        margin-left: 10px;
      }
    }
  }
  .loading {
    margin-top: 55%;
  }
  .download {
    z-index: 1;
    position: absolute;
    top: 450px;
    right: 20px;
    border: 1px solid rgb(218, 217, 217);
    border-radius: 100%;
    padding: 5px;
  }
  .proof-link {
    h4 {
      font-size: 14px;
      font-family: Arial;
      font-weight: bold;
      color: #2c3034;
      margin: 10px 0 20px;
      padding: 0;
    }
  }
}
</style>
