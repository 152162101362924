<template>
  <div class="onlineHelp cg-box">
    <van-loading v-if="!pageTotalNum" class="loading">加载中...</van-loading>

    <div v-show="pageTotalNum" :key="url" class="pdf-box">
      <pdf
        id="pdfPreview"
        ref="pdf"
        :src="pdfLink"
        :page="pageNum"
        :style="{
          transform:
            'translate(' +
            posX +
            'px,' +
            posY +
            'px) translateZ(0px) scale(' +
            dis +
            ')'
        }"
        @progress="loadedRatioFunction($event)"
        @page-loaded="pageLoaded($event)"
        @num-pages="pageTotalNum = $event"
        @error="pdfError($event)"
        @link-clicked="page = $event"
      />
      <a class="file-down" :href="pdfAdress" :download="pdfAdress" target="_blank">
        <img class="download" src="@/assets/nba/down.png" width="20" alt />
      </a>
      <!-- <a v-if="isMicroMessenger" download @click="microHandle">
        <img class="download" src="@/assets/nba/down.png" width="20" alt />
      </a>
      <a v-if="!isMicroMessenger" :href="url" download @click="microHandle">
        <img class="download" src="@/assets/nba/down.png" width="20" alt />
      </a> -->
    </div>
    <div v-if="pageTotalNum && !isTip" class="tools">
      <!-- <van-field
        v-model.number="goPageNum"
        :border="false"
        style="
          width: 70px;
          border: 1px solid #dce0e6;
          border-radius: 4px;
          padding: 2px 5px;
        "
      /> -->
      <!-- <van-button type="success" @click.stop="goPage"> 前往</van-button> -->
      <p>
        <van-icon name="arrow-left" @click.stop="prePage" />
      </p>
      <div class="page">第 {{ pageNum }} /{{ pageTotalNum }}页</div>
      <p>
        <van-icon name="arrow" @click.stop="nextPage" />
      </p>
    </div>
    <micro-tip v-if="isTip" />
  </div>
</template>
<script>
import pdf from "vue-pdf";
import { Button, Field, Loading, Icon } from "vant";
import AlloyFinger from 'alloyfinger';
import microTip from "./microTip.vue";
export default {
  name: "PdfComponent",
  components: {
    pdf,
    // vanField: Field,
    vanLoading: Loading,
    // vanButton: Button,
    vanIcon: Icon,
    microTip,
  },
  props: {
    url: {
      type: String,
      default() {
        return "";
      },
    },
    pdfKey: {
      type: String,
      default() {
        return "";
      },
    },
  },

  data() {
    return {
      posX: 0,
      posY: 0,
      dis: 1,
      angle: 0,
      pdfAdress: null,
      pdfLink: null,
      pageNum: 1,
      pageTotalNum: 0,
      // 加载进度
      loadedRatio: 0,
      curPageNum: 0,
      goPageNum: 1,
      downloadUrl: "",
      isMicroMessenger: null,
      isTip: false,
    };
  },
  mounted() {
    // 判断是否是在微信打开
    if (navigator.userAgent.indexOf("MicroMessenger") >= 0) {
      this.isMicroMessenger = true;
    } else {
      this.isMicroMessenger = false;
    }
    let BASE_URL = location.origin.indexOf('localhost') > -1 ? 'https://lundbeck-kol360-mobile-dev.lek-digtal.com' : (location.origin);
    this.pdfAdress = `${BASE_URL}/api/AWS/AWSService/GetObject?keyName=${this.pdfKey}`;
    // console.log('url', this.url);
    this.setAlloyFn();
  },
  created() {
    this.pdfLink = pdf.createLoadingTask(this.url);
  },
  beforeDestroy() {
    this.pdfLink._worker.destroy();
  },
  methods: {
    // 开启手势
    setAlloyFn() {
      let that = this;
      let element = document.getElementById('pdfPreview'); // 指定元素
      this.af = new AlloyFinger(element, {
        rotate: function(evt) {
          that.angle += evt.angle;
        },
        pinch: function(evt) {
          if (evt.zoom < 0.7) return; // 设置最小缩放
          that.dis = evt.zoom;
        },
        pressMove: function(evt) {
          that.posX += evt.deltaX;
          that.posY += evt.deltaY;
        },
        tap: function(evt) {
          // console.log('单击');
          // 点按触发
        },
        doubleTap: function(e) {
          // console.log('双击');
          // 双击屏幕触发
        },
        longTap: function(e) {
          // console.log('长按');
          // 长按屏幕750ms触发
        },
        swipe: function(e) {
          // e.direction代表滑动的方向
          // console.log('swipe' + e.direction);
        }
      });
    },
    // 上一页下一页重置手势数据
    resetData() {
      this.posX = 0;
      this.posY = 0;
      this.dis = 1;
      this.angle = 0;
    },
    // 上一页函数，
    prePage() {
      this.resetData();
      var page = this.pageNum;
      page = page > 1 ? page - 1 : this.pageTotalNum;
      this.pageNum = page;
    },
    // 下一页函数
    nextPage() {
      this.resetData();
      var page = this.pageNum;
      page = page < this.pageTotalNum ? page + 1 : 1;
      this.pageNum = page;
    },
    // 前往页数
    goPage() {
      if (
        !this.goPageNum ||
        /\D/.test(this.goPageNum) ||
        this.goPageNum < 1 ||
        this.goPageNum > this.pageTotalNum
      ) {
        this.$message.warning("输入页码有误");
        return;
      }
      this.resetData();
      this.pageNum = this.goPageNum;
    },
    // 页面加载回调函数，其中e为当前页数
    pageLoaded(e) {
      this.curPageNum = e;
    },
    // 其他的一些回调函数。
    pdfError(error) {
      console.error(error);
    },
    loadedRatioFunction(e) {
      this.loadedRatio = e;
      console.log(e);
    },
    microHandle() {
      if (this.isMicroMessenger) {
        this.isTip = true;
      } else {
        this.isTip = false;
      }
    },
  },
};
</script>

<style lang="scss">
.onlineHelp {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .tools {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 999;
    font-size: 16px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .page {
      display: inline-block;
      margin-right: 10px;
    }
  }
  .pdf-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: auto;
    width: 90%;
  }
  .download {
    z-index: 1;
    position: absolute;
    margin-top: 25%;
    right: 45px;
    border: 1px solid rgb(218, 217, 217);
    border-radius: 100%;
    padding: 5px;
  }
}
</style>
