<template>
  <div class="text-left" style="padding: 10px 20px">
    <div>
      <span class="s-title">建议洞见问题</span>
    </div>
    <div style="font-size: 12px">
      <div
        v-for="(item, index) in suggestions"
        :key="index"
        class="flex items-center justify-between"
        style="margin-top: 14px"
      >
        <span> {{ item?.text }}</span>
        <RoundButton @click="$emit('addClick', item)"> + 添加 </RoundButton>
      </div>
    </div>
  </div>
</template>

<script>
import {fetchHighlightedInsightQuestions} from '@/api/kol';
import RoundButton from './RoundButton.vue';

export default {
  components: {
    RoundButton,
  },
  data() {
    return {
      suggestions: [],
    };
  },
  mounted() {
    this.fetchInsightQuestions();
  },
  methods: {
    async fetchInsightQuestions() {
      const res = await fetchHighlightedInsightQuestions();
      this.suggestions = res.map((item) => ({
        ...item,
        text: item.question,
        id: item.insight_question_id,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.s-title {
  font-size: 12px;
  color: #999;
}
</style>
