<template>
  <div v-if="tableList.length" class="table-list">
    <p class="school-desc">毕业院校信息：</p>
    <table>
      <tr class="header">
        <td>就学时间</td>
        <td>毕业学院</td>
        <td>学位/科研经历</td>
        <td>主修</td>
      </tr>
      <tr v-for="(td, index) in tableList" :key="index">
        <td>{{ td.graduate_date }}</td>
        <td>{{ td.school_name }}</td>
        <td>{{ td.degree }}</td>
        <td>{{ td.major }}</td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    tableList: {
      type: Array,
      default() {
        return [];
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.table-list {
  padding: 0 10px;
  .school-desc {
    text-align: left;
    margin-bottom: 5px;
  }
  .header {
    td {
      // font-weight: bold;
      color: #000000;
    }
  }
}
</style>
