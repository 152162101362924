<template>
  <div class="template-wrap">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164 84">
      <g id="fc-transfer-1" transform="translate(0 0)">
        <!-- 多边形 -->
        <polygon points="0,42 82,0 164,42 82,84" fill="#D7F3F7" />
      </g>
    </svg>
    <div id="ep-flowchart" class="ep-flowchart">
      <div v-if="type === 'no_try'" class="ep-svg-wrap">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 1400">
          <g id="Page-1" fill="none" fill-rule="evenodd">
            <g id="Course-Template">
              <g
                id="fc-start"
                class="ep-svg-block"
                transform="translate(79 56)"
              >
                <rect
                  id="Rectangle"
                  width="241"
                  height="40"
                  fill="#696969"
                  rx="20"
                />
                <text
                  id="Content"
                  fill="#FFFFFF"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="20" y="25">未尝试使用的AB级医生</tspan>
                </text>
              </g>
              <g id="arrows-1.1" transform="translate(63 98)">
                <g id="arrows/arrow-1.1" transform="rotate(90 69.5 70)">
                  <path
                    id="Line"
                    stroke="#89969F"
                    stroke-linecap="square"
                    d="M.336 2.5h38.46"
                  />
                  <polygon
                    id="Triangle"
                    fill="#89969F"
                    points="37 5 37 0 40 2.5"
                  />
                </g>
              </g>
              <g
                id="fc-transfer-1"
                class="ep-svg-block"
                transform="translate(20 140)"
                @click="selectedNode('fc-transfer-1')"
              >
                <!-- 多边形 -->
                <polygon points="50,0 340,0 300,60 10,60" fill="#0062b9" />
                <text
                  id="Content"
                  fill="#FFFFFF"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="45" y="25">传递：全面+安全+额外认知获益</tspan>
                  <tspan x="75" y="45">→更好恢复学习状态</tspan>
                </text>
              </g>
              <g id="arrows-1.2" transform="translate(63 200)">
                <g id="arrows/arrow-1.2" transform="rotate(90 69.5 70)">
                  <path
                    id="Line"
                    stroke="#89969F"
                    stroke-linecap="square"
                    d="M.336 2.5h38.46"
                  />
                  <polygon
                    id="Triangle"
                    fill="#89969F"
                    points="37 5 37 0 40 2.5"
                  />
                </g>
              </g>
              <g
                id="fc-conclude-1"
                class="ep-svg-block"
                transform="translate(0 241)"
                @click="selectedNode('fc-conclude-1')"
              >
                <polygon points="200,0 300,40 200,80 100,40" fill="#0062b9" />
                <text
                  id="Content"
                  fill="#FFFFFF"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="128" y="45">缔结：初诊梅栋丽</tspan>
                </text>
              </g>
              <g id="arrows-1.3" transform="translate(300 278.5)">
                <path
                  id="Line"
                  stroke="#89969F"
                  stroke-linecap="square"
                  d="M.336 2.5h97"
                />
                <polygon
                  id="Triangle"
                  fill="#89969F"
                  points="37 5 37 0 40 2.5"
                  transform="translate(60 0)"
                />
                <text
                  id="Content"
                  fill="#000000"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="10" y="-5">不能描述</tspan>
                  <tspan x="0" y="20">认知的改善</tspan>
                </text>
              </g>
              <g
                id="fc-judge-1"
                class="ep-svg-block"
                transform="translate(200 241)"
                @click="selectedNode('fc-judge-1')"
              >
                <polygon points="300,0 400,40 300,80 200,40" fill="#0062b9" />
                <text
                  id="Content"
                  fill="#FFFFFF"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="228" y="45">是否认可基础疗效</tspan>
                </text>
              </g>
              <g id="arrow-2.1" transform="translate(500 321)">
                <path id="Path-4" stroke="#89969F" d="M0 0V40h-18v49" />
                <polygon
                  id="Triangle-2-Copy-2"
                  fill="#89969F"
                  transform="translate(-108 81)"
                  points="87 6 93 6 90 9"
                />
                <text
                  id="Content"
                  fill="#000000"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                  @click="selectedNode('fc-true-1')"
                >
                  <tspan x="-20" y="35">是</tspan>
                </text>
              </g>
              <g id="arrows-1.4" transform="translate(498 241)">
                <g id="arrows/arrow-1.4" transform="rotate(270 0 0)">
                  <path
                    id="Line"
                    stroke="#89969F"
                    stroke-linecap="square"
                    d="M.336 2.5h97"
                  />
                  <polygon
                    id="Triangle"
                    fill="#89969F"
                    transform="translate(60 0)"
                    points="37 5 37 0 40 2.5"
                  />
                  <text
                    id="Content"
                    transform="rotate(90 0 0)"
                    fill="#FFFFFF"
                    font-family="Roboto-Regular, Roboto"
                    font-size="18"
                    letter-spacing="1"
                    @click="selectedNode('fc-false-1')"
                  >
                    <tspan x="5" y="-40">否</tspan>
                  </text>
                </g>
              </g>
              <g id="arrows-1.5" transform="translate(361 3)">
                <g id="arrows/arrow-1.5" transform="rotate(180 69.5 70)">
                  <path
                    id="Line"
                    stroke="#89969F"
                    stroke-linecap="square"
                    d="M.336 2.5h140"
                  />
                  <polygon
                    id="Triangle"
                    fill="#89969F"
                    transform="translate(102 0)"
                    points="37 5 37 0 40 2.5"
                  />
                </g>
              </g>
              <g id="arrows-1.6" transform="translate(100 283.5)">
                <g id="arrows/arrow-1.6" transform="rotate(180)">
                  <path
                    id="Line"
                    stroke="#89969F"
                    stroke-linecap="square"
                    d="M.336 2.5h97"
                  />
                  <polygon
                    id="Triangle"
                    fill="#89969F"
                    points="37 5 37 0 40 2.5"
                    transform="translate(60 0)"
                  />
                  <text
                    id="Content"
                    fill="#000000"
                    transform="rotate(180)"
                    font-family="Roboto-Regular, Roboto"
                    font-size="18"
                    letter-spacing="1"
                  >
                    <tspan x="-85" y="-8">能描述</tspan>
                    <tspan x="-95" y="15">认知的改善</tspan>
                  </text>
                </g>
              </g>

              <!-- <g id="arrow-1.7" transform="translate(100 383.5)">
                <path id="Path-4" stroke="#89969F" d="M87 3H38.009C32.48 3 28 7.48 28 13v87" />
                <polygon id="Triangle-2-Copy-2" fill="#89969F" points="87 6 87 0 90 3" />
              </g>-->
              <g id="arrow-1.8" transform="translate(-37 128)">
                <g id="arrows/arrow-1.8">
                  <path
                    id="Path-4"
                    stroke="#89969F"
                    d="M38 155V247H38.009c0 5.523 4.483 10 10.009 10H87"
                  />
                  <polygon
                    id="Triangle-2-Copy-4"
                    fill="#89969F"
                    transform="translate(0 -13)"
                    points="87 273 87 267 90 270"
                  />
                </g>
              </g>
              <g
                id="fc-transfer-2"
                class="ep-svg-block"
                transform="translate(30 350)"
                @click="selectedNode('fc-transfer-2')"
              >
                <!-- 多边形 -->
                <polygon points="50,0 280,0 240,60 10,60" fill="#0062b9" />
                <text
                  id="Content"
                  fill="#FFFFFF"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="65" y="35">传递：认知→功能</tspan>
                </text>
              </g>
              <g id="arrows-1.9" transform="translate(43 410)">
                <g id="arrows/arrow-1.9" transform="rotate(90 69.5 70)">
                  <path
                    id="Line"
                    stroke="#89969F"
                    stroke-linecap="square"
                    d="M.336 2.5h38.46"
                  />
                  <polygon
                    id="Triangle"
                    fill="#89969F"
                    points="37 5 37 0 40 2.5"
                  />
                </g>
              </g>
              <g
                id="fc-conclude-2"
                class="ep-svg-block"
                transform="translate(-20 451)"
                @click="selectedNode('fc-conclude-2')"
              >
                <polygon points="200,0 300,40 200,80 100,40" fill="#0062b9" />
                <text
                  id="Content"
                  fill="#FFFFFF"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="128" y="45">缔结：换药梅栋丽</tspan>
                </text>
              </g>
              <g
                id="arrows-2.0"
                transform="translate(43 530)"
                @click="selectedNode('fc-true-2')"
              >
                <g id="arrows/arrow-2.0" transform="rotate(90 69.5 70)">
                  <path
                    id="Line"
                    stroke="#89969F"
                    stroke-linecap="square"
                    d="M.336 2.5h90"
                  />
                  <polygon
                    id="Triangle"
                    transform="translate(52 0)"
                    fill="#89969F"
                    points="37 5 37 0 40 2.5"
                  />
                  <text
                    id="Content"
                    fill="#000000"
                    transform="rotate(-90)"
                    font-family="Roboto-Regular, Roboto"
                    font-size="18"
                    letter-spacing="1"
                  >
                    <tspan x="-45" y="35">对功能恢复</tspan>
                    <tspan x="-35" y="60">有正反馈</tspan>
                  </text>
                </g>
              </g>
              <g
                id="fc-category-1"
                class="ep-svg-block"
                transform="translate(79 634)"
              >
                <circle id="Rectangle" cx="100" cy="50" r="60" fill="#696969" />
                <text
                  id="Content"
                  fill="#FFFFFF"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="52" y="55">功能恢复型</tspan>
                </text>
              </g>
              <g
                id="arrow-2.1"
                transform="translate(32 488)"
                @click="selectedNode('fc-strengthen-1')"
              >
                <path
                  id="Path-4"
                  stroke="#89969F"
                  d="M48 3H38.009C32.48 3 28 7.48 28 13v177c0 5.523 4.483 10 10.009 10H87"
                />
                <polygon
                  id="Triangle-2-Copy-2"
                  fill="#89969F"
                  transform="translate(-41 0)"
                  points="87 6 87 0 90 3"
                />
                <text
                  id="Content"
                  fill="#000000"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="-30" y="55">强化：认知→功能</tspan>
                </text>
              </g>
              <g id="arrows-2.0" transform="translate(280 488.5)">
                <g id="arrows/arrow-2.0" transform="rotate(0 69.5 70)">
                  <path
                    id="Line"
                    stroke="#89969F"
                    stroke-linecap="square"
                    d="M.336 2.5h90"
                  />
                  <polygon
                    id="Triangle"
                    transform="translate(52 0)"
                    fill="#89969F"
                    points="37 5 37 0 40 2.5"
                  />
                  <text
                    id="Content"
                    fill="#000000"
                    font-family="Roboto-Regular, Roboto"
                    font-size="18"
                    letter-spacing="1"
                  >
                    <tspan x="10" y="-5">无正反馈</tspan>
                  </text>
                </g>
              </g>
              <g id="arrow-2.1" transform="translate(102 605)">
                <path id="Path-4" stroke="#89969F" d="M0 0H320v-75" />
                <polygon
                  id="Triangle-2-Copy-2"
                  fill="#89969F"
                  transform="translate(230 -80)"
                  points="87 6 93 6 90 3"
                />
                <text
                  id="Content"
                  fill="#000000"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                  @click="selectedNode('fc-loop')"
                >
                  <tspan x="-30" y="-5">循环到</tspan>
                  <tspan x="-30" y="18">足够多</tspan>
                </text>
              </g>
              <g
                id="fc-judge-2"
                class="ep-svg-block"
                transform="translate(272 431)"
                @click="selectedNode('fc-judge-2')"
              >
                <polygon
                  points="210,-20 320,60 210,140 100,60"
                  fill="#0062b9"
                />
                <text
                  id="Content"
                  fill="#FFFFFF"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="154" y="40">探寻：是否对</tspan>
                  <tspan x="128" y="60">情感迟钝/快感缺失</tspan>
                  <tspan x="158" y="80">/疲乏低动力</tspan>
                  <tspan x="178" y="100">感兴趣？</tspan>
                </text>
              </g>
              <g id="arrow-2.1" transform="translate(482 570)">
                <path id="Path-4" stroke="#89969F" d="M0 1V80h-150v80" />
                <polygon
                  id="Triangle-2-Copy-2"
                  fill="#89969F"
                  transform="translate(-240 154)"
                  points="87 6 93 6 90 9"
                />
                <text
                  id="Content"
                  fill="#000000"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="-80" y="100">是</tspan>
                </text>
              </g>
              <g
                id="fc-category-2"
                class="ep-svg-block"
                transform="translate(234 744)"
              >
                <circle id="Rectangle" cx="100" cy="50" r="60" fill="#696969" />
                <text
                  id="Content"
                  fill="#FFFFFF"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="58" y="45">功能恢复/</tspan>
                  <tspan x="52" y="65">信心满满型</tspan>
                </text>
              </g>
              <g id="arrow-2.2" transform="translate(592 491)">
                <path id="Path-4" stroke="#89969F" d="M0 0V160h-80v80" />
                <polygon
                  id="Triangle-2-Copy-2"
                  fill="#89969F"
                  transform="translate(-170 234)"
                  points="87 6 93 6 90 9"
                />
                <text
                  id="Content"
                  fill="#000000"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="-50" y="155">否</tspan>
                </text>
              </g>
              <g
                id="fc-category-3"
                class="ep-svg-block"
                transform="translate(413 744)"
              >
                <circle id="Rectangle" cx="100" cy="50" r="60" fill="#696969" />
                <text
                  id="Content"
                  fill="#FFFFFF"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="58" y="45">全面症状/</tspan>
                  <tspan x="52" y="65">情感驱动型</tspan>
                </text>
              </g>
              <g id="arrows-2.3" transform="translate(375 854)">
                <g id="arrows/arrow-1.1" transform="rotate(90 69.5 70)">
                  <path
                    id="Line"
                    stroke="#89969F"
                    stroke-linecap="square"
                    d="M.336 2.5h38.46"
                  />
                  <polygon
                    id="Triangle"
                    fill="#89969F"
                    points="37 5 37 0 40 2.5"
                  />
                </g>
              </g>
              <g
                id="fc-result-1"
                class="ep-svg-block"
                transform="translate(395 895)"
                @click="selectedNode('fc-result-1')"
              >
                <!-- 多边形 -->
                <polygon points="40,0 200,0 170,60 10,60" fill="#0062b9" />
                <text
                  id="Content"
                  fill="#FFFFFF"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="45" y="25">强化疗效和安全，</tspan>
                  <tspan x="65" y="45">推荐20mg</tspan>
                </text>
              </g>
              <g id="arrow-2.1" transform="translate(332 854)">
                <path id="Path-4" stroke="#89969F" d="M0 0V40h-58v69" />
                <polygon
                  id="Triangle-2-Copy-2"
                  fill="#89969F"
                  transform="translate(-148 101)"
                  points="87 6 93 6 90 9"
                />
              </g>
              <g
                id="fc-content"
                class="ep-svg-block"
                transform="translate(202 964)"
                @click="selectedNode('fc-content')"
              >
                <rect
                  id="Rectangle"
                  width="140"
                  height="40"
                  fill="#0062b9"
                  rx="20"
                />
                <text
                  id="Content"
                  fill="#FFFFFF"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="20" y="25">深挖兴趣点</tspan>
                </text>
              </g>

              <g id="arrow-4" transform="translate(200 837)">
                <g id="arrows/arrow-4" transform="rotate(90 53 220.5)">
                  <path
                    id="Path-4"
                    stroke="#89969F"
                    d="M87 3H38.009C32.48 3 28 7.48 28 13v367c0 5.523 4.483 10 10.009 10H87"
                  />
                  <path id="Path-5" stroke="#89969F" d="M0 200H88" />
                  <polygon
                    id="Triangle-2-Copy-2"
                    fill="#89969F"
                    points="87 6 87 0 90 3"
                  />
                  <polygon
                    id="Triangle-2-Copy-3"
                    fill="#89969F"
                    points="87 203 87 197 90 200"
                  />
                  <polygon
                    id="Triangle-2-Copy-4"
                    fill="#89969F"
                    points="87 393 87 387 90 390"
                  />
                </g>
              </g>
              <g
                id="fc-conclusion-1"
                class="ep-svg-block"
                transform="translate(0 1095)"
                @click="selectedNode('fc-conclusion-1')"
              >
                <!-- 多边形 -->
                <polygon points="20,0 180,0 160,80 0,80" fill="#0062b9" />
                <text
                  id="Content"
                  fill="#FFFFFF"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="45" y="25">心达悦可以</tspan>
                  <tspan x="15" y="45">更好改善情感迟钝</tspan>
                  <tspan x="30" y="65">促进功能恢复</tspan>
                </text>
              </g>
              <g
                id="fc-conclusion-2"
                class="ep-svg-block"
                transform="translate(180 1095)"
                @click="selectedNode('fc-conclusion-2')"
              >
                <!-- 多边形 -->
                <polygon points="20,0 180,0 160,80 0,80" fill="#0062b9" />
                <text
                  id="Content"
                  fill="#FFFFFF"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="45" y="25">心达悦可以</tspan>
                  <tspan x="15" y="45">更好改善快感缺失</tspan>
                  <tspan x="30" y="65">促进功能恢复</tspan>
                </text>
              </g>
              <g
                id="fc-conclusion-3"
                class="ep-svg-block"
                transform="translate(360 1095)"
                @click="selectedNode('fc-conclusion-3')"
              >
                <!-- 多边形 -->
                <polygon points="20,0 200,0 180,80 0,80" fill="#0062b9" />
                <text
                  id="Content"
                  fill="#FFFFFF"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="50" y="25">心达悦可以</tspan>
                  <tspan x="15" y="45">更好改善疲乏低动力</tspan>
                  <tspan x="45" y="65">促进功能恢复</tspan>
                </text>
              </g>
              <g id="arrows-1.1" transform="translate(-50 1175)">
                <g id="arrows/arrow-1.1" transform="rotate(90 69.5 70)">
                  <path
                    id="Line"
                    stroke="#89969F"
                    stroke-linecap="square"
                    d="M.336 2.5h38.46"
                  />
                  <polygon
                    id="Triangle"
                    fill="#89969F"
                    points="37 5 37 0 40 2.5"
                  />
                </g>
              </g>
              <g
                id="fc-example-1"
                class="ep-svg-block"
                transform="translate(0 1216)"
                @click="selectedNode('fc-example-1')"
              >
                <rect
                  id="Rectangle"
                  width="160"
                  height="80"
                  fill="#0062b9"
                  rx="20"
                />
                <text
                  id="Content"
                  fill="#FFFFFF"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="25" y="25">由于情感迟钝</tspan>
                  <tspan x="40" y="45">而换药的</tspan>
                  <tspan x="20" y="65">秦思光/梅栋丽</tspan>
                </text>
              </g>
              <g id="arrows-1.1" transform="translate(137 1175)">
                <g id="arrows/arrow-1.1" transform="rotate(90 69.5 70)">
                  <path
                    id="Line"
                    stroke="#89969F"
                    stroke-linecap="square"
                    d="M.336 2.5h38.46"
                  />
                  <polygon
                    id="Triangle"
                    fill="#89969F"
                    points="37 5 37 0 40 2.5"
                  />
                </g>
              </g>
              <g
                id="fc-example-2"
                class="ep-svg-block"
                transform="translate(190 1216)"
                @click="selectedNode('fc-example-2')"
              >
                <rect
                  id="Rectangle"
                  width="160"
                  height="80"
                  fill="#0062b9"
                  rx="20"
                />
                <text
                  id="Content"
                  fill="#FFFFFF"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="25" y="25">由于快感缺失</tspan>
                  <tspan x="40" y="45">而换药的</tspan>
                  <tspan x="20" y="65">秦思光/梅栋丽</tspan>
                </text>
              </g>
              <g id="arrows-1.1" transform="translate(334 1175)">
                <g id="arrows/arrow-1.1" transform="rotate(90 69.5 70)">
                  <path
                    id="Line"
                    stroke="#89969F"
                    stroke-linecap="square"
                    d="M.336 2.5h38.46"
                  />
                  <polygon
                    id="Triangle"
                    fill="#89969F"
                    points="37 5 37 0 40 2.5"
                  />
                </g>
              </g>
              <g
                id="fc-example-3"
                class="ep-svg-block"
                transform="translate(400 1216)"
                @click="selectedNode('fc-example-3')"
              >
                <rect
                  id="Rectangle"
                  width="160"
                  height="80"
                  fill="#0062b9"
                  rx="20"
                />
                <text
                  id="Content"
                  fill="#FFFFFF"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="20" y="25">由于疲乏低动力</tspan>
                  <tspan x="40" y="45">而换药的</tspan>
                  <tspan x="20" y="65">秦思光/梅栋丽</tspan>
                </text>
              </g>
              <g id="arrows-1.1" transform="translate(-50 1296)">
                <g id="arrows/arrow-1.1" transform="rotate(90 69.5 70)">
                  <path
                    id="Line"
                    stroke="#89969F"
                    stroke-linecap="square"
                    d="M.336 2.5h38.46"
                  />
                  <polygon
                    id="Triangle"
                    fill="#89969F"
                    points="37 5 37 0 40 2.5"
                  />
                </g>
              </g>
              <g id="arrows-1.1" transform="translate(137 1296)">
                <g id="arrows/arrow-1.1" transform="rotate(90 69.5 70)">
                  <path
                    id="Line"
                    stroke="#89969F"
                    stroke-linecap="square"
                    d="M.336 2.5h38.46"
                  />
                  <polygon
                    id="Triangle"
                    fill="#89969F"
                    points="37 5 37 0 40 2.5"
                  />
                </g>
              </g>
              <g id="arrows-1.1" transform="translate(334 1296)">
                <g id="arrows/arrow-1.1" transform="rotate(90 69.5 70)">
                  <path
                    id="Line"
                    stroke="#89969F"
                    stroke-linecap="square"
                    d="M.336 2.5h38.46"
                  />
                  <polygon
                    id="Triangle"
                    fill="#89969F"
                    points="37 5 37 0 40 2.5"
                  />
                </g>
              </g>
              <g
                id="fc-end"
                class="ep-svg-block"
                transform="translate(9 1336)"
                @click="selectedNode('fc-end')"
              >
                <rect
                  id="Rectangle"
                  width="541"
                  height="40"
                  fill="#0062b9"
                  rx="20"
                />
                <text
                  id="Content"
                  fill="#FFFFFF"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="80" y="25">
                    跟进成就感：症状改善→功能恢复→人生价值
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div v-if="type === 'G-AB1'" class="ep-svg-wrap">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600">
          <g id="Page-1" fill="none" fill-rule="evenodd">
            <g id="Course-Template">
              <g
                id="fc-start"
                class="ep-svg-block"
                transform="translate(179 56)"
              >
                <rect
                  id="Rectangle"
                  width="241"
                  height="40"
                  fill="#696969"
                  rx="20"
                />
                <text
                  id="Content"
                  fill="#FFFFFF"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="60" y="25">功能恢复型医生</tspan>
                </text>
              </g>
              <g id="arrows-1.1" transform="translate(163 98)">
                <g id="arrows/arrow-1.1" transform="rotate(90 69.5 70)">
                  <path
                    id="Line"
                    stroke="#89969F"
                    stroke-linecap="square"
                    d="M.336 2.5h38.46"
                  />
                  <polygon
                    id="Triangle"
                    fill="#89969F"
                    points="37 5 37 0 40 2.5"
                  />
                </g>
              </g>
              <g
                id="fc-transfer-1"
                class="ep-svg-block"
                transform="translate(120 140)"
                @click="selectedNode('fc-strengthen-1')"
              >
                <!-- 多边形 -->
                <polygon points="50,0 340,0 300,60 10,60" fill="#0062b9" />
                <text
                  id="Content"
                  fill="#FFFFFF"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="85" y="35">强化：认知→功能</tspan>
                </text>
              </g>
              <g id="arrows-1.2" transform="translate(163 200)">
                <g id="arrows/arrow-1.2" transform="rotate(90 69.5 70)">
                  <path
                    id="Line"
                    stroke="#89969F"
                    stroke-linecap="square"
                    d="M.336 2.5h38.46"
                  />
                  <polygon
                    id="Triangle"
                    fill="#89969F"
                    points="37 5 37 0 40 2.5"
                  />
                </g>
              </g>
              <g
                id="fc-conclude-1"
                class="ep-svg-block"
                transform="translate(100 241)"
                @click="selectedNode('fc-conclude-2')"
              >
                <polygon points="200,0 300,40 200,80 100,40" fill="#0062b9" />
                <text
                  id="Content"
                  fill="#FFFFFF"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="128" y="45">缔结：换药梅栋丽</tspan>
                </text>
              </g>
              <g id="arrows-1.2" transform="translate(163 322)">
                <g id="arrows/arrow-1.2" transform="rotate(90 69.5 70)">
                  <path
                    id="Line"
                    stroke="#89969F"
                    stroke-linecap="square"
                    d="M.336 2.5h38.46"
                  />
                  <polygon
                    id="Triangle"
                    fill="#89969F"
                    points="37 5 37 0 40 2.5"
                  />
                </g>
              </g>
              <g
                id="fc-end"
                class="ep-svg-block"
                transform="translate(179 366)"
              >
                <rect
                  id="Rectangle"
                  width="241"
                  height="40"
                  fill="#696969"
                  rx="20"
                />
                <text
                  id="Content"
                  fill="#FFFFFF"
                  font-family="Roboto-Regular, Roboto"
                  font-size="18"
                  letter-spacing="1"
                >
                  <tspan x="40" y="25">足够多的换药梅栋丽</tspan>
                </text>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <transition name="pop-details">
        <div v-show="selected != ''" class="ep-detail-wrap">
          <!-- <div v-show="selected == 'fc-start'" class="ep-detail">
            <h3>start</h3>
            <p>未尝试使用的AB级医生</p>
          </div>-->
          <div v-show="selected == 'fc-transfer-1'" class="ep-detail">
            <div class="modal-header">
              <img
                class="not-followed"
                src="../../../assets/nba/close.png"
                width="20"
                alt
                @click="selectedNode('')"
              />
            </div>
            <div class="modal-body">
              <p>强调要点：</p>
              <p>
                1.柳叶刀杂志的21种抗抑郁药物荟萃分析显示心达悦的整体疗效和可接受度优势显著
              </p>
              <p>
                2.相较于安慰剂，伏硫西汀是唯一有统计学意义，可显著改善抑郁症患者DSST相关认知症状的抗抑郁药物
              </p>
              <p>
                3.注意力不集中，记忆力下降会严重影响学生（≥18岁）人群的学习状态
              </p>
              <p class="data-spacing">可用资料：</p>

              <p @click="selectedNode('pdf-show')">
                《心达悦®治疗抑郁症的整体疗效 CNCT-001929》
              </p>
              <p @click="selectedNode('pdf-show')">
                《心达悦®可显著改善抑郁症患者的认知症状CNCT-001456》
              </p>
              <p @click="selectedNode('pdf-show')">
                《心达悦®一种安全性卓越的新型抗抑郁药 CNCT-001930》
              </p>
            </div>
          </div>
          <div v-show="selected == 'fc-conclude-1'" class="ep-detail">
            <div class="modal-header">
              <img
                class="not-followed"
                src="../../../assets/nba/close.png"
                width="20"
                alt
                @click="selectedNode('')"
              />
            </div>
            <div class="modal-body">
              <p>
                缔结话术：您看您门诊上可否为1-2例初诊学生（≥18岁）使用心达悦？我们一起看看效果？
              </p>
              <p>
                可用资料：
                <span @click="selectedNode('pdf-show')">
                  《专家幻灯-伏硫西汀---助力梅栋丽学习回归之优选20-20200922-CNCT-001563》
                </span>
                +自己初诊梅栋丽患者分享
              </p>
            </div>
          </div>
          <div v-show="selected == 'fc-judge-1'" class="ep-detail">
            <div class="modal-header">
              <img
                class="not-followed"
                src="../../../assets/nba/close.png"
                width="20"
                alt
                @click="selectedNode('')"
              />
            </div>
            <div class="modal-body">
              <p>
                跟进：上次您处方的那个初诊学生患者回来复诊了吗？他的注意力记忆力改善怎么样啊，能具体描述一下吗
              </p>
            </div>
          </div>
          <div v-show="selected == 'fc-true-1'" class="ep-detail">
            <div class="modal-header">
              <img
                class="not-followed"
                src="../../../assets/nba/close.png"
                width="20"
                alt
                @click="selectedNode('')"
              />
            </div>
            <div class="modal-body">
              <p>
                关注认知的医生：可以具体描述患者的注意力记忆力改善，比如：“上课能集中注意力了，记单词明显比以前变快了”
              </p>
            </div>
          </div>
          <div v-show="selected == 'fc-false-1'" class="ep-detail">
            <div class="modal-header">
              <img
                class="not-followed"
                src="../../../assets/nba/close.png"
                width="20"
                alt
                @click="selectedNode('')"
              />
            </div>
            <div class="modal-body">
              <p>
                不关注认知的医生：很笼统回答“好了，挺好的”
                ，追问也没有细节的描述
              </p>
            </div>
          </div>
          <div v-show="selected == 'fc-transfer-2'" class="ep-detail">
            <div class="modal-header">
              <img
                class="not-followed"
                src="../../../assets/nba/close.png"
                width="20"
                alt
                @click="selectedNode('')"
              />
            </div>
            <div class="modal-body">
              <p>强调要点：</p>
              <p>
                重复之前观点：您对于心达悦的整体疗效和安全性以及在认知改善上的优势，已经认可了。
              </p>
              <p>注意力记忆力的改善对于学生的学习效率有很好的提升</p>
              <p class="data-spacing">可用资料：</p>
              <p @click="selectedNode('pdf-show')">
                《心达悦®促进抑郁症患者功能恢复CNCT-001521》
              </p>
            </div>
          </div>

          <div v-show="selected == 'fc-conclude-2'" class="ep-detail">
            <div class="modal-header">
              <img
                class="not-followed"
                src="../../../assets/nba/close.png"
                width="20"
                alt
                @click="selectedNode('')"
              />
            </div>
            <div class="modal-body">
              <p>
                缔结话术：您是否有经过一段时间治疗，仍然抱怨学习效率/状态不好的学生患者，可否尝试几例换用心达悦？我们一起看看效果
              </p>
              <p>如果老师对于换药有顾虑，请出示此证据打消对疗效的顾虑</p>
              <p class="data-spacing">可用资料：</p>
              <p @click="selectedNode('pdf-show')">
                《全面恢复+重获新生-CNCT-001732》换药秦思光
              </p>
            </div>
          </div>
          <div v-show="selected == 'fc-true-2'" class="ep-detail">
            <div class="modal-header">
              <img
                class="not-followed"
                src="../../../assets/nba/close.png"
                width="20"
                alt
                @click="selectedNode('')"
              />
            </div>
            <div class="modal-body">
              <p>
                关注功能的医生：可以具体描述患者的学习状态改善情况，比如：“他学习效率明显提升，成绩又回到了班上前几名，考上了理想的大学，父母家人都非常高兴”
              </p>
              <p>
                不关注功能的医生：很笼统回答
                挺好的挺好的，追问也没有如上细节的描述
              </p>
            </div>
          </div>

          <div v-show="selected == 'fc-loop'" class="ep-detail">
            <div class="modal-header">
              <img
                class="not-followed"
                src="../../../assets/nba/close.png"
                width="20"
                alt
                @click="selectedNode('')"
              />
            </div>
            <div class="modal-body">
              <p>探寻：是否对情感迟钝、快感缺失、疲乏低动力感兴趣？</p>
            </div>
          </div>
          <div v-show="selected == 'fc-strengthen-1'" class="ep-detail">
            <div class="modal-header">
              <img
                class="not-followed"
                src="../../../assets/nba/close.png"
                width="20"
                alt
                @click="selectedNode('')"
              />
            </div>
            <div class="modal-body">
              <p>传递观念：</p>
              <p>注意力记忆力的改善对于学生的学习效率有很好的提升</p>
              <p class="data-spacing">可用资料：</p>
              <p @click="selectedNode('pdf-show')">
                《抑郁症的认知症状与功能恢复CNCT-001944》
              </p>
            </div>
          </div>
          <!-- <div v-show="selected == 'fc-category-1'" class="ep-detail">
            <p>功能恢复型</p>
          </div>-->
          <div v-show="selected == 'fc-judge-2'" class="ep-detail">
            <div class="modal-header">
              <img
                class="not-followed"
                src="../../../assets/nba/close.png"
                width="20"
                alt
                @click="selectedNode('')"
              />
            </div>
            <div class="modal-body">
              <p>探寻情感迟钝兴趣</p>
              <p>
                探寻：
                老师您这边有没有患者感觉自己很麻木（高兴不起来，也感受不到悲伤，心如止水），觉得自己像个植物或者机器人？
                如果有的话，您对于这类患者是如何治疗的
              </p>
              <p>探寻快感缺失兴趣</p>
              <p>
                探寻：
                老师您这边有没有患者经过一段治疗后，仍反馈正性情绪没有恢复，或者对于正性情绪没有期待（例如：对什么都不感兴趣，体会不到快乐，对幸福感的期待下降）
              </p>
              <p>探寻疲乏低动力兴趣</p>
              <p>
                探寻：
                老师您这边有没有患者经过一段时间治疗后，仍反馈疲乏，精力不足，缺乏动力？
                （包括：躺着不想动，容易觉得累，白天昏昏沉沉，提不起精神，浑身没劲儿，感觉身体被掏空）
              </p>
            </div>
          </div>
          <!-- <div v-show="selected == 'fc-category-2'" class="ep-detail">
            <p>功能恢复/信心满满型</p>
          </div>
          <div v-show="selected == 'fc-category-3'" class="ep-detail">
            <p>全面症状/情感驱动型</p>
          </div>-->
          <div v-show="selected == 'fc-result-1'" class="ep-detail">
            <div class="modal-header">
              <img
                class="not-followed"
                src="../../../assets/nba/close.png"
                width="20"
                alt
                @click="selectedNode('')"
              />
            </div>
            <div class="modal-body">
              <p>强化疗效和安全，推荐20mg 剂量</p>
              <p>
                辅助资料：
                <span @click="selectedNode('pdf-show')">
                  《伏硫西汀20mg一样安全，更强疗效 CNCT-001570》
                </span>
              </p>
            </div>
          </div>
          <div v-show="selected == 'fc-content'" class="ep-detail">
            <div class="modal-header">
              <img
                class="not-followed"
                src="../../../assets/nba/close.png"
                width="20"
                alt
                @click="selectedNode('')"
              />
            </div>
            <div class="modal-body">
              <p>深挖兴趣点</p>
            </div>
          </div>
          <div v-show="selected == 'fc-conclusion-1'" class="ep-detail">
            <div class="modal-header">
              <img
                class="not-followed"
                src="../../../assets/nba/close.png"
                width="20"
                alt
                @click="selectedNode('')"
              />
            </div>
            <div class="modal-body">
              <p>强调要点：</p>
              <p>
                1.伏硫西汀协同调节5-HT、NE、DA浓度或成为改善“情感迟钝”的新选择。
              </p>
              <p>
                2.伏硫西汀可显著改善
                SSRI或SNRI部分应答患者的情感迟钝症状，兼顾整体症状的改善和功能恢复。
              </p>
              <p>3.经过SSRI治疗的患者 容易出现 情感迟钝症状</p>
              <p class="data-spacing">可用资料：</p>
              <p @click="selectedNode('pdf-show')">
                《抑郁症情感迟钝的现状与挑战-备注版20210810-CNCT-001772》
              </p>
              <p @click="selectedNode('pdf-show')">
                《抑郁情感迟钝的临床干预-备注版20210810-CNCT-001767》
              </p>
            </div>
          </div>
          <div v-show="selected == 'fc-conclusion-2'" class="ep-detail">
            <div class="modal-header">
              <img
                class="not-followed"
                src="../../../assets/nba/close.png"
                width="20"
                alt
                @click="selectedNode('')"
              />
            </div>
            <div class="modal-body">
              <p>强调要点：</p>
              <p>1.伏硫西汀协同调节5-HT、NE、DA，有效改善快感缺失。</p>
              <p>2.伏硫西汀显著改善快感缺失，兼顾整体症状的改善和功能恢复，</p>
              <p>3.且改善快感缺失优于阿戈美拉汀</p>
              <p>4.经SSRI治疗，仍然存在快感缺失</p>
              <p class="data-spacing">可用资料：</p>
              <p @click="selectedNode('pdf-show')">
                《抑郁症核心症状快感缺失新说-Part1-CNCT-001879》
              </p>
            </div>
          </div>
          <div v-show="selected == 'fc-conclusion-3'" class="ep-detail">
            <div class="modal-header">
              <img
                class="not-followed"
                src="../../../assets/nba/close.png"
                width="20"
                alt
                @click="selectedNode('')"
              />
            </div>
            <div class="modal-body">
              <p>强调要点：</p>
              <p>1.伏硫西汀协同调节5-HT、NE、DA浓度</p>
              <p>2.伏硫西汀显著改善抑郁症患者动机和精力</p>
              <p>3.经SSRI治疗，仍然存在疲乏低动力</p>
              <p class="data-spacing">可用资料：</p>
            </div>
          </div>
          <div v-show="selected == 'fc-example-1'" class="ep-detail">
            <div class="modal-header">
              <img
                class="not-followed"
                src="../../../assets/nba/close.png"
                width="20"
                alt
                @click="selectedNode('')"
              />
            </div>
            <div class="modal-body">
              <p>缔结话术：</p>
              <p>
                如果发现经过一种SSRI治疗后出现情感迟钝的患者，选择几例
                换用伏硫西汀看看效果?
              </p>
              <p>如果对换用后整体疗效反复有顾虑</p>
              <p class="data-spacing">可用资料：</p>
              <p @click="selectedNode('pdf-show')">
                《抑郁症中的“情感迟钝”药物优化方案 CNCT-001816》
              </p>
            </div>
          </div>
          <div v-show="selected == 'fc-example-2'" class="ep-detail">
            <div class="modal-header">
              <img
                class="not-followed"
                src="../../../assets/nba/close.png"
                width="20"
                alt
                @click="selectedNode('')"
              />
            </div>
            <div class="modal-body">
              <p>缔结话术：</p>
              <p>可否选几位快感缺失改善不佳的患者，换用伏硫西汀看看效果?</p>
              <p>如果对换用后整体疗效反复有顾虑</p>
              <p class="data-spacing">可用资料：</p>
              <p @click="selectedNode('pdf-show')">
                《快感缺失 优化治疗 CNCT-001995》
              </p>
            </div>
            <div v-show="selected == 'fc-example-3'" class="ep-detail">
              <p>缔结话术：</p>
              <p>可否选几位疲乏低动力改善不佳的患者，换用伏硫西汀看看效果?</p>
              <p>如果对换用后整体疗效反复有顾虑</p>
              <p class="data-spacing">可用资料：</p>
            </div>
          </div>
          <div v-show="selected == 'fc-end'" class="ep-detail">
            <div class="modal-header">
              <img
                class="not-followed"
                src="../../../assets/nba/close.png"
                width="20"
                alt
                @click="selectedNode('')"
              />
            </div>
            <div class="modal-body">
              <p>跟进成就感：症状改善→功能恢复→人生价值</p>
            </div>
          </div>
          <div v-show="selected == 'pdf-show'" class="ep-detail">
            <div class="modal-header">
              <img
                class="not-followed"
                src="../../../assets/nba/close.png"
                width="20"
                alt
                @click="selectedNode('')"
              />
            </div>
            <div class="modal-body">
              <a :href="pdfLink" download>
                <img
                  class="not-followed"
                  src="../../../assets/nba/download.png"
                  width="20"
                  alt
                />
              </a>
              <pdf v-for="i in numPages" :key="i" :src="pdfLink" :page="i" />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import pdf from "vue-pdf";
export default {
  name: "FlowChart",

  components: {
    pdf,
  },
  props: {
    type: {
      type: String,
      default() {
        return "no_try";
      },
    },
  },
  data() {
    return {
      selected: "",
      url: "",
      currentPage: 0,
      numPages: 0,
      // pdfLink: require("../../../assets/lundbeck/map_merged.pdf"),
    };
  },
  mounted() {
    // this.getNumPages(this.pdfLink);
  },
  methods: {
    selectedNode(node) {
      this.selected = node;
    },
    getNumPages(url) {
      let loadingTask = pdf.createLoadingTask(url);
      loadingTask.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
div {
  box-sizing: border-box;
}

.template-wrap {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0.5em;
  background: #ffffff;
}
.template-wrap figure {
  margin: 1em auto;
}

.ep-flowchart {
  width: 100%;
  position: relative;
}

.ep-svg-wrap .ep-svg-block {
  cursor: pointer;
}
// .ep-svg-wrap .ep-svg-block rect,
// .ep-svg-wrap .ep-svg-block text {
//   transition: all 0.3s ease;
// }
// .ep-svg-wrap .ep-svg-block:hover rect,
// .ep-svg-wrap .ep-svg-block:hover text {
//   transform: translateY(-5px);
//   text-shadow: 0 1px 0 #fff, 0 -1px 0 #000;
// }

.ep-detail-wrap {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.ep-detail-wrap .ep-detail {
  position: fixed;
  top: 40%;
  left: 10%;
  // position: relative;
  background-color: #ffffff;
  color: #000000;
  transform: translateY(-50%);
  margin: 50% auto;
  width: 80%;
  height: auto;
  max-height: 60%;
  padding: 5px 2px;
  border-radius: 15px;
  border: 5px solid #0062b9;
  z-index: 100;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.2);
  .data-spacing {
    margin-top: 20px;
  }
  p {
    text-align: left;
    line-height: 1.2;
    margin: 3px 0;
  }
}
// .ep-detail-wrap .ep-detail::after {
//   content: '✖';
//   color: #0062b9;
//   position: absolute;
//   top: -20px;
//   right: -20px;
//   display: inline-block;
//   font-size: 15px;
//   line-height: 20px;
//   height: 20px;
//   width: 20px;
//   padding: 5px;
//   text-align: center;
//   background-color: #f5f5f0;
//   border-radius: 50%;
//   border: 5px solid;
//   cursor: pointer;
//   box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
// }

.pop-details-enter-active,
.pop-details-leave-active {
  transition: all 0.3s ease-in-out;
}

.pop-details-enter {
  opacity: 0;
}

.pop-details-leave-to {
  opacity: 0;
}
.modal-header {
  height: 20px;
  img {
    float: right;
  }
}
.modal-body {
  overflow-x: hidden;
  overflow-y: auto;
  background: #ffffff;
  min-height: 100px;
  max-height: 400px;
  margin-top: 10px;
  position: static;
  a {
    position: absolute;
    right: 10px;
    z-index: 101;
    bottom: 20px;
  }
}
</style>
