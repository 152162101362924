<template>
  <div class="list-group">
    <div class="list-group-item box bac-white">
      <CommonHeader
        title="动态关键词"
        :source="$t('feedback_data_hao_online')"
        :no-feedback="true"
      />
      <div class="">
        <WordCloudChart
          v-if="wordChartData.seriesData.length"
          :chart-data="wordChartData"
        />
        <Nodata
          v-if="!wordChartData.seriesData.length"
          :loading="wordLoading"
          :empty-txt="$t('no_data')"
        />
      </div>
    </div>
    <div class="list-group-item box bac-white">
      <CommonHeader title="动态详情" :source="$t('feedback_data_hao_online')" :no-feedback="true" />
      <ul v-if="pageShowArr.length" class="activity-list">
        <li v-for="(item, index) in pageShowArr" :key="'public' + index">
          <!-- 论文发表 -->
          <template>
            <!-- <span class="type type1">{{ $t('publication') }}</span> -->
            <label style="font-weight: initial" v-html="item.title"></label>
            <br />
            <div
              v-if="item.activity_type || item.platform"
              class="info-details flex-detail"
            >
              <span v-if="item.activity_type" class="journal e-ellipsis-l1">
                <label
                  style="font-weight: initial"
                  v-html="item.activity_type"
                ></label>
                <span v-if="item.platform" class="journal_if">:<label
                    style="font-weight: initial"
                    v-html="item.platform"
                  ></label>
                </span>
              </span>

              <span class="time">{{ item.date | dealNullData }}</span>
            </div>
            <Tags :tag-item="item.keywordsArr" />
          </template>
        </li>
      </ul>
      <Nodata
        v-if="!pageShowArr.length"
        :loading="detailLoading"
        :empty-txt="$t('no_data')"
      />
    </div>
  </div>
</template>
<script>
import Tags from "@/components/Tags";
import WordCloudChart from "@/components/WordCloudChart";
import CommonHeader from "@/components/CommonHeader";
import Nodata from "@/components/Nodata";
import * as py from "@/api/Physicians";
import * as moment from "moment";

export default {
  components: {
    Tags,
    CommonHeader,
    WordCloudChart,
    Nodata,
  },
  data() {
    return {
      physician_id: "",
      wordChartData: {
        seriesData: [
          // ,
        ],
      },
      wordLoading: false,
      detailLoading: false,
      pageShowArr: [
        // {
        //   publication_title: '瞰见·特别策划丨丝绸之路学术周：功能学与影像学高峰论坛圆满收官——从“心“出发，至所未至',
        //   journal_if: '严道医声网',
        //   date: '2022-05-20',
        //   keywordsArr: ['test', 'test1', 'test2']
        // }
      ],
    };
  },
  mounted() {
    this.physician_id = this.$route.query.physician_id;
    this.getWords();
    this.getList();
  },
  methods: {
    getWords() {
      this.wordChartData.seriesData = [];
      this.wordLoading = true;
      py.getVPhysicianActivityFactLabelStats(this.physician_id)
        .then((res) => {
          if (res && res.value.length) {
            res.value.map((item) => {
              this.wordChartData.seriesData.push({
                value: item.count || 0,
                name: item.level_3,
              });
            });
          }
          this.wordLoading = false;
        })
        .catch((e) => {
          this.wordLoading = false;
        });
      // py.getDsFactLabels(this.physician_id)
      //   .then((res) => {
      //     if (res && res.value.length) {
      //       res.value.forEach((ele) => {
      //         this.wordChartData.seriesData.push({
      //           value: 10,
      //           name: ele.level_5,
      //         });
      //       });
      //     }
      //     this.wordLoading = false;
      //   })
      //   .catch((e) => {
      //     this.wordLoading = false;
      //   });
    },
    getList() {
      this.detailLoading = true;
      this.pageShowArr = [];
      py.getVPhysicianActivities(this.physician_id)
        .then((res) => {
          if (res && res.value.length) {
            res.value.forEach((ele) => {
              let labelsArr = [];
              if (ele.labels) {
                labelsArr = ele.labels.split(",");
              }
              this.pageShowArr.push({
                platform: ele.platform,
                title: ele.title,
                url: ele.url,
                date: ele.activity_date
                  ? moment(ele.activity_date).format("YYYY-MM-DD")
                  : "",
                data_sort: ele.activity_date_sort
                  ? moment(ele.activity_date_sort).format("YYYY-MM-DD")
                  : "",
                activity_type: ele.activity_type,
                keywordsArr: labelsArr,
              });
            });
          }
          this.detailLoading = false;
        })
        .catch((e) => {
          this.detailLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.flex-detail {
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
  justify-content: space-between;
  span {
    padding-right: 10px;
  }
}
</style>
