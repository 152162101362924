import * as py from '@/api/Physicians';

export default {
  data() {
    return {
      employmentLoading: false,
      associationsList: [],
      journalsList: [],
      officeList: [],
      physician_id: ''
    };
  },
  mounted() {
    this.physician_id = this.$route.query.physician_id;
    this.getEmploymentListData();
  },
  methods: {
    getEmploymentListData() {
      this.associationsList = [];
      this.journalsList = [];
      this.officeList = [];
      this.employmentLoading = true;
      Promise.all([py.getPhysicianAssociationsByPhysicianId(this.physician_id), py.getPhysicianJournalsByPhysicianId(this.physician_id)]).then(res => {
        if (res.length) {
          res.forEach((li, index) => {
            li.value.forEach(ele => {
              ele.type = index === 0 ? 'associations' : 'journals';
              this.officeList.push(ele);
            });
          });
          // 任职状态 - 协会
          this.associationsList = res[0].value || [];
          // 任职状态 - 期刊
          this.journalsList = res[1].value || [];
        }
        this.employmentLoading = false;
      }).catch(e => {
        this.employmentLoading = false;
      });
    }
  }
};
