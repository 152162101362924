<template>
  <div class="table-list">
    <table>
      <tr v-for="(td, index) in tableList" :key="index">
        <td width="25%">{{ td.week_day }}</td>
        <td width="25%">{{ td.am_pm }}</td>
        <td v-if="showHospital" width="25%">{{ td.hospital }}</td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    tableList: {
      type: Array,
      default() {
        return [];
      }
    },
    showHospital: {
      type: Boolean,
      default() {
        return false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.table-list {
  margin: 15px 5px 5px 5px;
  font-size: 0.28rem;
  // color: #333333;
  background-color: #fff;
  table {
    width: 100%;
    box-sizing: border-box;
    table-layout: fixed;
    border-collapse: separate;
    border: 1px solid #f4f4f4;
    border-width: 1px 0 0 1px;
    border-radius: 4px;
  }
  table td, table th {
    padding: 5px 5px;
    border: 1px solid #f4f4f4;
    border-width: 0 1px 1px 0;
    vertical-align: middle;
    text-align: left;
    word-wrap: break-word;
    word-break: break-word;
  }
}
</style>
