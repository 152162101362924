<template>
  <div class="body-content" style="position: relative">
    <div class="status">
      <div class="list-group">
        <div class="list-group-item">
          <div class="box flex-content bac-white">
            <CommonHeader
              :title="$t('activity_summary')"
              :source="$t('feedback_data_hao_gov')"
              :no-feedback="true"
            />
            <div>
              <SummaryChart
                v-if="barChartData.seriesData.length"
                :chart-data="barChartData"
                @searchFn="searchFn"
              />
              <DataRequest v-if="externalDataLastUpdate" :external-data-last-update="externalDataLastUpdate" />
              <!-- <p v-if="barChartData.seriesData.length" class="module-update-time">{{ updateTime }}</p> -->
              <Nodata
                v-else
                :loading="academicLoading"
                :empty-txt="$t('no_data')"
              />
            </div>
          </div>
        </div>
        <div class="list-group-item">
          <div class="box flex-content bac-white">
            <CommonHeader
              :title="$t('top_activity_key')"
              :source="$t('feedback_data_gov')"
              :no-feedback="true"
            />
            <div>
              <AreaStackChart
                v-if="chartData.seriesData.length"
                :chart-data="chartData"
                height="250px"
              />
              <Nodata
                v-else
                :loading="keywordLoading"
                :empty-txt="$t('no_data')"
              />
            </div>
          </div>
        </div>
        <div class="list-group-item">
          <cardActivity
            :title="$t('activity_article_detail')"
            :init-list="initList"
            :academic-loading="academicLoading"
            :page-show-arr="pageShowArr"
            @errorCorrection="errorCorrection"
          />
        </div>
      </div>
    </div>
    <cube-popup ref="errorCorrectionPopup" type="my-popup" :mask="true">
      <div class="cube-extend-popup-content">
        <slot>
          <div class="error-correction-popup">
            <p class="header">请选择数据错误的原因</p>
            <div class="content">
              <cube-checkbox-group
                v-model="checkList"
                :options="suggestionData"
              />
            </div>
            <div class="footer">
              <cube-button
                class="cube-btn-cancel"
                @click="confirmErrorPopup('close')"
              >
                取消
              </cube-button>
              <cube-button @click="confirmErrorPopup('confirm')">
                确认
              </cube-button>
            </div>
          </div>
        </slot>
      </div>
    </cube-popup>
  </div>
</template>

<script>
import _ from 'underscore';
import * as moment from 'moment';
import * as py from '@/api/Physicians';
import AreaStackChart from '@/components/AreaStackChart';
import {formatterDateYMD, formatterDate} from '@/filters/index';
import SummaryChart from '@/components/SummaryChart';
import Nodata from '@/components/Nodata';
import cardActivity from '@/components/cardActivity';
import CommonHeader from '@/components/CommonHeader';
import {reverseByKey} from '@/utils/index';
import sortDynamicMixins from '@/mixins/sortDynamicMixins';
import DataRequest from './dataRequest.vue';
// import draggable from "vuedraggable";

export default {
  name: 'ProfileDetailsTrends',
  components: {
    // draggable,
    AreaStackChart,
    CommonHeader,
    SummaryChart,
    Nodata,
    cardActivity,
    DataRequest,
  },
  mixins: [sortDynamicMixins],
  data() {
    return {
      updateTime: '',
      finished: false,
      loading: false,
      productLoading: false,
      productBarChartData: {
        // 产品声量分析
        xData: [],
        seriesData: [],
      },
      productTimeChartData: {
        seriesData: [],
        xAxis: [],
        legendData: [],
      },
      chartData: {
        seriesData: [],
        xAxis: [], // ['2016', '2017', '2018', '2019', '2020'],
        legendData: [],
      },
      barChartData: {
        xData: [
          '论文发表',
          '临床试验',
          '国自然基金',
          '获奖',
          '指南共识',
          '专利发明',
        ],
        seriesData: [],
        legend: false,
      },
      suggestionData: [
        {
          label: '论文/实验/数据不是该医生的',
          value: 1,
          disabled: false,
        },
        {
          label: '该信息已过期',
          value: 2,
          disabled: false,
        },
      ],
      checkList: [],
      activitySelect: 'academic',
      activityTabs: [
        {
          label: 'academic',
          text: '学术成果',
        },
        // {
        //   label: 'trends',
        //   text: '本周动态'
        // },
        {
          label: 'employment',
          text: '任职状态',
        },
      ],
      associationsList: [],
      journalsList: [],
      meetingList: [],
      interactionList: [],
      pageTotalArr: [], // 成果列表数据
      pageShowArr: [], // 学术成果分页显示数据
      pageNumber: 0, // 分页数
      academicBarList: [], // 柱状图数据
      academicLoading: false,
      keywordLoading: false,
      employmentLoading: false,
      physician_id: '',
      initList: [],
      externalDataLastUpdate: '',
    };
  },
  created() {
    this.physician_id = this.$route.query.physician_id;
    // this.getProductVolumeList();
    // this.getProductTimeList();
    this.getKeyWords();
  },
  mounted() {
    this.getPhysicianDataStats();
    this.getList();
    // this.errorCorrection();
  },
  methods: {
    dealKeyWordsData(res) {
      let max = new Date().getFullYear();
      let min = max - 10;
      for (let x = min; x <= max; x++) {
        this.chartData.xAxis.push(x + '');
      }
      let keywordMap = {};
      res.value.forEach((ele) => {
        // 根据关键字归类
        if (!keywordMap[ele.keyword]) {
          keywordMap[ele.keyword] = [ele];
          keywordMap[ele.keyword + '_keyword_no_total'] = ele.keyword_no;
        } else {
          keywordMap[ele.keyword].push(ele);
          keywordMap[ele.keyword + '_keyword_no_total'] =
            keywordMap[ele.keyword + '_keyword_no_total'] + ele.keyword_no;
        }
      });
      let keywordArr = [];
      Object.keys(keywordMap).forEach((key) => {
        if (keywordMap[key + '_keyword_no_total'] && key) {
          keywordArr.push({
            keyword_no_total: keywordMap[key + '_keyword_no_total'],
            keyword: key,
            data: keywordMap[key],
          });
        }
      });
      // top前5的关键词
      const sortArr = keywordArr
        .sort(reverseByKey('keyword_no_total'))
        .slice(0, 5);
      sortArr.forEach((ele) => {
        this.chartData.legendData.push(ele.keyword);
        let dArr = [];
        this.chartData.xAxis.forEach((year) => {
          const fData = ele.data.find((kw) => kw.year === year);
          dArr.push(fData && fData.keyword_no ? fData.keyword_no : 0);
        });
        this.chartData.seriesData.push({
          data: dArr,
          name: ele.keyword,
        });
      });
    },
    // 论文关键字趋势数据
    getKeyWords() {
      py.getPhysicianPublicationKeywordStatsByPhysicianId(
        this.$route.query.physician_id,
      )
        .then((res) => {
          if (res && res.value.length) {
            this.dealKeyWordsData(res);
          }
          this.keywordLoading = false;
        })
        .catch((e) => {
          this.keywordLoading = false;
        });
    },
    splitString(keywords) {
      let spArr = [];
      let result = [];
      if (keywords) {
        spArr = keywords.split('；');
        if (spArr.length === 1 && keywords.indexOf('，') > -1) {
          spArr = keywords.split('，');
        }
        if (spArr.length === 1 && keywords.indexOf(',') > -1) {
          spArr = keywords.split(',');
        }
        spArr.forEach((ele) => {
          if (ele && ele !== '' && ele !== ' ') {
            result.push(ele);
          }
        });
      }
      return result;
    },
    dealData(res, keywordsArr) {
      let pageTotalArr = [];
      let keyArr = [
        'physician__physician_publications',
        'physician__physician_clinical_trials',
        'physician__physician_projects',
        'physician__physician_awards',
        'physician__physician_guides',
        'physician__physician_patents',
      ];
      // const keywordsMap = _.groupBy(keywordsArr, "publication_id");
      for (let key in res) {
        if (Array.isArray(res[key]) && keyArr.includes(key)) {
          res[key].forEach((ele) => {
            if (key === 'physician__physician_publications') {
              // ele.keywordsArr = []; // this.splitString(ele.keywords);
              // if (ele.publication_id && keywordsMap[ele.publication_id]) {
              //   keywordsMap[ele.publication_id].forEach((key) => {
              //     let arr = this.splitString(key.keyword);
              //     if (arr.length > 1) {
              //       arr.forEach((kw) => {
              //         ele.keywordsArr.push(kw);
              //       });
              //     } else {
              //       ele.keywordsArr.push(key.keyword);
              //     }
              //   });
              // }
              // ele.publish_date ? ele.publish_date.substr(0, 4) : ''; //
              ele.date = formatterDateYMD(ele.publish_date);
              ele.type = 'type1';
            }
            if (key === 'physician__physician_clinical_trials') {
              ele.status = ele.status ? ele.status.split('（')[0] : '';
              ele.date = formatterDateYMD(ele.registration_date);
              ele.type = 'type2';
            }
            if (key === 'physician__physician_projects') {
              ele.date = formatterDateYMD(ele.exec_date_start);
              ele.type = 'type3';
            }
            if (key === 'physician__physician_awards') {
              ele.date = ele.award_time;
              ele.type = 'type4';
            }
            if (key === 'physician__physician_guides') {
              ele.date = ele.release_time;
              ele.type = 'type5';
            }
            if (key === 'physician__physician_patents') {
              ele.date = moment(ele.publish_date).format('YYYY-MM-DD');
              ele.type = 'type6';
            }
            pageTotalArr.push(ele);
          });
        }
      }
      return pageTotalArr;
    },
    searchFn(type) {
      console.info(type);
      this.$parent.$refs.profileDeatils.scrollTo(0, 570, 0, '');
    },
    getPhysicianDataStats() {
      py.getPhysicianDataStats(this.physician_id).then((res) => {
        if (res && res.value.length) {
          let dValue = [
            res.value[0].data_no_1,
            res.value[0].data_no_2,
            res.value[0].data_no_3,
            res.value[0].data_no_4,
            res.value[0].data_no_5,
            res.value[0].data_no_6,
          ];
          // const hasVal = dValue.some((v) => {
          //   return v > 0;
          // });
          // if (hasVal) {
          // 学术成果汇总柱状图数据
          this.barChartData.seriesData.push({data: dValue});
          this.externalDataLastUpdate = formatterDate(res.value[0].external_data_last_update);
          // this.updateTime = "更新日期：" + formatterDate(res[0][0].update_date);
          // }
        }
      });
    },
    // 获取学术成果列表数据
    getList() {
      this.pageTotalArr = [];
      this.pageShowArr = [];
      this.academicLoading = true;
      this.keywordLoading = true;

      Promise.all([
        py.getPublicationsByPhysicianId(this.physician_id),
        py.getPhysicianClinicalTrialsByPhysicianId(this.physician_id),
        py.getPhysicianProjectsByPhysicianId(this.physician_id),
        py.getPhysicianAwardsByPhysicianId(this.physician_id),
        py.getGuidesByPhysicianId(this.physician_id),
        py.getPatentsByPhysicianId(this.physician_id),
      ])
        .then((res) => {
          if (res.length) {
            let data = {
              physician__physician_publications: res[0].value,
              physician__physician_clinical_trials: res[1].value,
              physician__physician_projects: res[2].value,
              physician__physician_awards: res[3].value,
              physician__physician_guides: res[4].value,
              physician__physician_patents: res[5].value,
            };
            const pageTotalArr = this.dealData(data, []);
            const sortTotalArr = _.sortBy(pageTotalArr, (item) => {
              return -item.date;
            }); // 年份倒序
            this.initList = sortTotalArr;
          }
          this.academicLoading = false;
        })
        .catch((e) => {
          this.academicLoading = false;
        });
    },
    getEmploymentListData() {
      this.associationsList = [];
      this.journalsList = [];
      this.employmentLoading = true;
      Promise.all([
        py.getPhysicianAssociationsByPhysicianId(this.physician_id),
        py.getPhysicianJournalsByPhysicianId(this.physician_id),
      ])
        .then((res) => {
          if (res.length) {
            // 任职状态 - 协会
            this.associationsList = res[0].value || [];
            // 任职状态 - 期刊
            this.journalsList = res[1].value || [];
          }
          this.employmentLoading = false;
        })
        .catch((e) => {
          this.employmentLoading = false;
        });
    },
    errorCorrection(item) {
      this.checkList = [];
      const component = this.$refs['errorCorrectionPopup'];
      this.$store.dispatch('setDialogStatus', true);
      component.show();
    },
    confirmErrorPopup(isClose) {
      if (isClose === 'close') {
        this.$refs['errorCorrectionPopup'].hide();
      }
      if (isClose === 'confirm') {
        if (!this.checkList.length) {
          this.toast = this.$createToast({
            txt: '请选择数据错误的原因',
            type: 'warn',
          });
          this.toast.show();
          return;
        } else {
          // this.$refs['errorCorrectionPopup'].hide();
        }
      }
      this.$store.dispatch('setDialogStatus', false);
      this.$refs['errorCorrectionPopup'].hide();
    },
  },
};
</script>

<style scoped lang="scss">
.cube-tab-bar {
  line-height: 15px !important;
}
.activity-list {
  padding-bottom: 10px;
}
.new-status {
  padding-right: 10px;
  .time-status {
    margin-top: 20px;
    padding-left: 15px;
  }
}
.status {
  position: relative;
}
.info-details {
  position: absolute;
  bottom: 10px;
  width: calc(100% - 60px);
}
time-line {
  margin-top: 10px;
}
.phy-title {
  font-size: 16px;
  color: #000;
  text-align: left;
  padding: 10px;
}
.error-correction-popup {
  width: 300px;
  background-color: #ffffff;
  padding: 15px 10px;
  border-radius: 3px;
  .header {
    color: #333333;
    font-size: 14px;
    text-align: center;
    padding: 10px 0;
  }
  .content {
    font-size: 13px;
    ::v-deep .cube-checkbox-group {
      .cube-checkbox {
        padding: 0 !important;
      }
    }
  }
  .footer {
    display: flex;
    align-content: center;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;
    .cube-btn-cancel {
      color: #333333;
      background-color: transparent !important;
      border: 1px solid #cccccc;
    }
    ::v-deep .cube-btn {
      width: 120px;
      padding: 6px 0;
      font-size: 12px;
    }
  }
}
</style>
