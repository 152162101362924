<template>
  <div>
    <SchemaField
      v-for="(item, index) in insightField"
      :key="index"
      v-model="formState[item.field]"
      :schema="item"
      @change="handleChangeField(item.field, $event)"
    />
    <div>
      <SchemaField
        v-if="KIQLabel.length"
        :schema="{label: 'KIQ标签'}"
        :value-border="false"
      >
        <div>
          <van-button
            v-for="(item, index) in KIQLabel"
            :key="index"
            type="primary"
            size="mini"
            round
            style="padding: 0 14px"
          >{{ item }}</van-button>
        </div>
      </SchemaField>
    </div>
    <SchemaField :schema="{label: '反馈内容'}">
      <div
        ref="feedbackContentRef"
        style="width: 100%; min-height: 100px"
        contenteditable
        @input="updateContent"
        @compositionstart="inComposition = true"
        @compositionend="
          inComposition = false;
          updateContent($event);
        "
        v-html="feedbackContentHighlight"
      ></div>
    </SchemaField>
    <SpeechToText @translateResult="feedbackContent += $event" />
  </div>
</template>

<script>
import Vue from 'vue';
import {Tab, Tabs, Picker, Popup, Icon, Button} from 'vant';
import SchemaField from './SchemaField.vue';
import {
  fetchInsightQuestionLabels,
} from '@/api/kol';
import SpeechToText from '@/components/SpeechToText.vue';

Vue.use(Popup);
Vue.use(Picker);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Icon);
Vue.use(Button);

export default {
  components: {SchemaField, SpeechToText},
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    insightTopicList: Array,
  },
  data() {
    return {
      formState: {
        insight_question_id: '',
        insight_topic_id: '',
      },
      feedbackContent: '',
      KIQLabel: [],
    };
  },
  // 处理中文输入,
  inComposition: false,
  computed: {
    optionsKIT() {
      return this.insightTopicList?.map((item) => ({
        text: item.topic_name,
        id: item.insight_topic_id,
      }));
    },
    optionsKIQ() {
      return this.insightTopicList
        ?.find(
          (item) => item.insight_topic_id === this.formState.insight_topic_id,
        )
        ?.insight_topic__insight_questions?.map((item) => ({
          text: item.question,
          id: item.insight_question_id,
        }));
    },
    resultFormState() {
      return {
        insight_question_id: this.formState.insight_question_id,
        insight_content: this.feedbackContent,
      };
    },
    insightField() {
      return [
        {
          field: 'insight_topic_id',
          label: 'KIT',
          type: 'select',
          options: this.optionsKIT,
        },
        {
          field: 'insight_question_id',
          label: 'KIQ',
          type: 'select',
          options: this.optionsKIQ,
        },
      ];
    },
    feedbackContentHighlight() {
      let result = this.feedbackContent;
      this.KIQLabel.forEach((item) => {
        if (!result) return;
        result = result.replaceAll(
          item,
          `<span class="highlight">${item}</span>`,
        );
      });
      return result;
    },
  },
  watch: {
    'formState.insight_question_id'(val) {
      console.log(val, 'change formstate');
      if (!val) return;
      this.fetchInsightQuestionLabels(val);
    },
    resultFormState(newValue, oldValue) {
      this.$emit('input', newValue);
    },
    value: {
      handler(val) {
        this.feedbackContent = val.insight_content;
        try {
          this.formState.insight_question_id =
            val.insight_question.insight_question_id || val.insight_question_id;
          this.formState.insight_topic_id =
            val.insight_question.insight_topic_id;
        } catch (err) {
          return;
        }
      },
      immediate: true,
    },
  },

  methods: {
    async fetchInsightQuestionLabels(id) {
      const res = await fetchInsightQuestionLabels(id);
      this.KIQLabel = res.map((item) => item.label_name);
    },

    updateContent(e) {
      if (this.inComposition) {
        return;
      }
      const input = this.$refs.feedbackContentRef;
      var position = getCaretCharacterOffsetWithin(input);
      console.log(position, 'position');
      this.feedbackContent = e.target.innerText;
      this.$nextTick(() => {
        setCaretPosition(input, position);
      });
    },
    handleChangeField(field, value) {
      if (field === 'insight_topic_id') {
        this.$nextTick(() => {
          this.formState.insight_question_id = this.optionsKIQ[0].id;
        });
      }
      console.log(field, value, 'field change');
    },
  },
};

function getCaretCharacterOffsetWithin(element) {
  var caretOffset = 0;
  var doc = element.ownerDocument || element.document;
  var win = doc.defaultView || doc.parentWindow;
  var sel;
  if (typeof win.getSelection !== 'undefined') {
    sel = win.getSelection();
    if (sel.rangeCount > 0) {
      var range = win.getSelection().getRangeAt(0);
      var preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
    }
  } else if ((sel = doc.selection) && sel.type !== 'Control') {
    var textRange = sel.createRange();
    var preCaretTextRange = doc.body.createTextRange();
    preCaretTextRange.moveToElementText(element);
    preCaretTextRange.setEndPoint('EndToEnd', textRange);
    caretOffset = preCaretTextRange.text.length;
  }
  return caretOffset;
}

function setCaretPosition(element, offset) {
  var range = document.createRange();
  var sel = window.getSelection();

  // select appropriate node
  var currentNode = null;
  var previousNode = null;

  for (var i = 0; i < element.childNodes.length; i++) {
    // save previous node
    previousNode = currentNode;

    // get current node
    currentNode = element.childNodes[i];
    // if we get span or something else then we should get child node
    while (currentNode.childNodes.length > 0) {
      currentNode = currentNode.childNodes[0];
    }

    // calc offset in current node
    if (previousNode != null) {
      offset -= previousNode.length;
    }
    // check whether current node has enough length
    if (offset <= currentNode.length) {
      break;
    }
  }
  // move caret to specified offset
  if (currentNode != null) {
    range.setStart(currentNode, offset);
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
  }
}
</script>

<style lang="scss" scoped>
::v-deep .highlight {
  background-color: #24e57e;
}
</style>
