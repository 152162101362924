<template>
  <div>
    <div
      v-for="(item, index) in insightFormList"
      :key="item"
      class="bac-white text-left"
      style="padding: 10px 16px; margin-bottom: 16px"
    >
      <div class="s-title">洞见问题</div>
      <InsightForm
        :insight-topic-list="item.insightTopicList ? item.insightTopicList : insightTopicList"
        :value="item"
        style="margin: 0 -16px"
        @input="insightFormList[index] = {...item, ...$event}"
      />
    </div>
    <div class="flex" style="margin: 10px 0">
      <RoundButton @click="addInsightForm">+ 添加洞见问题</RoundButton>
    </div>
  </div>
</template>
<script>
import RoundButton from './RoundButton.vue';
import InsightForm from '@/components/form/InsightForm.vue';
import {createEditInsightRecord, fetchPhysicianMslInsights} from '@/api/kol';
import {fetchInsightTopics, fetchInsightQuestions} from '@/api/kol';
import { getLocalStorage } from '@/utils';

export default {
  components: {RoundButton, InsightForm},
  props: ['physician_id', 'source_msl_call_id'],
  data() {
    return {
      test: '',
      optionsKIT: [],
      optionsKIQ: [],
      insightFormState: {},
      insightFormList: [],
      insightTopicList: [],
    };
  },
  watch: {
    source_msl_call_id: {
      handler(value) {
        if (value) {
          this.fetchInsightList({source_msl_call_id: value});
        }
      },
      immediate: true,
    },
    // source_msl_call_id(value) {
    //   if (value) {
    //     this.fetchInsightList({source_msl_call_id: value});
    //   }
    // },
  },
  mounted() {
    this.fetchInsightTopics();
    // this.fetchInsightQuestions();
  },
  methods: {
    async fetchInsightTopics() {
      const res = await fetchInsightTopics();
      this.insightTopicList = res;
      this.optionsKIT = res.map((item) => ({
        text: item.topic_name,
        id: item.insight_topic_id,
      }));
    },
    // async fetchInsightQuestions() {
    //   const res = await fetchInsightQuestions();
    //   this.optionsKIQ = res.map((item) => ({
    //     text: item.question,
    //     id: item.insight_question_id,
    //   }));
    // },
    async fetchInsightList({source_msl_call_id, physician_id}) {
      const res = await fetchPhysicianMslInsights({
        source_msl_call_id,
        physician_id,
      });
      this.insightFormList = res.value;
      this.insightFormList.forEach((item) => {
        const insightTopicList = this.insightTopicList;
        // Check if topic exists
        if (!insightTopicList.find((topic) => (topic.insight_topic_id === item.insight_question?.insight_topic_id))) {
          insightTopicList.push({
            insight_topic_id: item.insight_question?.insight_topic_id,
            topic_name: item.insight_question?.insight_topic?.topic_name,
            insight_topic__insight_questions: [{
              insight_question_id: item.insight_question_id,
              question: item.insight_question?.question,
            }],
          });
        }
        item.insightTopicList = insightTopicList;
      });
      console.log(res.value, 'res');
    },
    async save(source_msl_call_id, type) {
      await Promise.all(
        this.insightFormList.map(({physician, insight_question, ...state}) => {
          // 创建/修改对应客户的医学洞见
          if (state.insight_question_id || state.insight_content) {
            const insightFormData = {
              physician_id: this.physician_id,
              insight_source: type,
              source_msl_call_id,
              insight_user_id: getLocalStorage('user_id'),
              insight_user_name: getLocalStorage('user_name'),
              ...state,
            };

            if (!insightFormData.insight_time) {
              insightFormData.insight_time = new Date();
            }
            if (insightFormData.insightTopicList) {
              delete insightFormData.insightTopicList;
            }

            return createEditInsightRecord(insightFormData);
          } else {
            return Promise.resolve();
          }
        }),
      );
    },
    addInsightForm(data = {}) {
      this.insightFormList.push(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.s-title {
  font-size: 12px;
  color: #999;
}
</style>
