import { sortDynamic } from '@/utils/defaultConstant';
import * as py from '@/api/Physicians';
import { setLocalStorage, getLocalStorage } from '@/utils/index';

export default {
  data() {
    return {
      sortDynamic: null,
      draggableDisabled: false,
      delay: 600
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      };
    }
  },
  created() {
    this.getSortMap();
  },
  methods: {
    getSortMap() {
      let sortDynamicData = getLocalStorage('SortDynamics');
      if (sortDynamicData) {
        let sData = JSON.parse(sortDynamicData);
        this.sortDynamic = JSON.parse(sData.sort_json);
      } else {
        this.sortDynamic = sortDynamic;
      }
    },
    addSortMap(json) {
      let user_id = getLocalStorage('user_id');
      let sortDynamicData = getLocalStorage('SortDynamics');
      let sData = JSON.parse(sortDynamicData);
      let sort_dynamic_id = getLocalStorage('sort_dynamic_id');
      let method = sort_dynamic_id ? 'put' : 'post';
      let locData = {
        user_id: user_id,
        sort_json: JSON.stringify(json)
      };
      if (sort_dynamic_id) {
        locData.sort_dynamic_id = sort_dynamic_id;
      }
      setLocalStorage('SortDynamics', JSON.stringify(locData));
      let data = {
        user_id: user_id,
        sort_json: JSON.stringify(json)
      };
      if (sort_dynamic_id) {
        data.sort_dynamic_id = sort_dynamic_id;
      }
      // 添加 或修改
      py.addSortDynamics(data, method).then(res => {
        if (res.is_success) {
          setLocalStorage('sort_dynamic_id', res.entity.sort_dynamic_id);
        }
      });
    },
    checkMove(e) {
      this.addSortMap(this.sortDynamic);
    }
  }
};
