<template>
  <div style="height: 100%;overflow-y: auto;">
    <Button type="info" @click="testadata">adata api</Button>
    <div>
      <span @click="settingLan('ZH')">CN</span>/<span @click="settingLan('EN')">En</span>
    </div>
    <!-- <pre>
            {{data}}
        </pre> -->
    <div class="test" style="">
      test {{ $t('home') }}- {{ $t('confirm') }}
    </div>
    <div v-for="(cl, index) in colorList" :key="index" :style="'background-color:'+cl">{{ index+1 }}: {{ cl }}</div>
    <div v-if="false" class="col-6">
      <h3>Draggable {{ draggingInfo }}</h3>
      <div id="itxst">
        <div
          v-for="(element,i) in list"
          :key="i"
          data-id="1"
        >{{ element.components }}</div>
        <div
          v-for="(element,i) in list"
          :key="'i'+i"
          class="list-group-item"
        >
          {{ element.components }}
        </div>
        <div data-id="2">item 2</div>
        <div data-id="3">item 3</div>
      </div>
    </div>
  </div>
</template>

<script>
import { adata } from '@/api/help';
import Button from 'vant/lib/button';
// import 'vant/lib/button/style';
import Sortable from 'sortablejs';

export default {
  name: 'Blank',
  components: {
    Button
  },
  data() {
    return {
      colorList: ['#3caa45', '#7EC0EE', '#1362ad', '#FFD700', '#A290FF ', '#F19C9C', '#9CCC65', '#FAD773', '#9EA9BE', '#9CE8CA', '#9DBCFB', '#FF99FF', '#ea7ccc'],
      data: [],
      enabled: true,
      list: [
        { components: 'TabTags', id: 0 },
        { components: 'RadarChart', id: 1 },
        { components: 'Jean', id: 2 },
        { components: 'TabTags', id: 0 },
        { components: 'RadarChart', id: 1 },
        { components: 'TabTags', id: 0 },
        { components: 'RadarChart', id: 1 },
        { components: 'Jean', id: 2 },
        { components: 'Jean', id: 2 },
        { components: 'TabTags', id: 0 },
        { components: 'RadarChart', id: 1 },
        { components: 'Jean', id: 2 },
        { components: 'TabTags', id: 0 },
        { components: 'RadarChart', id: 1 },
        { components: 'Jean', id: 2 }
      ],
      dragging: false
    };
  },
  computed: {
    draggingInfo() {
      return this.dragging ? 'under drag' : '';
    }
  },
  mounted() {
    this.$nextTick(() => {
      // // 获取对象
      // var el = document.getElementById('itxst');
      // // 设置配置
      // var ops = {
      //   animation: 1000,
      //   scroll: true,
      //   delay: 1000,
      //   // 拖动结束
      //   onEnd: function(evt) {
      //     console.log(evt);
      //     // 获取拖动后的排序
      //     var arr = sortable.toArray();
      //     console.info(JSON.stringify(arr));
      //   }
      // };
      // 初始化
      // var sortable = Sortable.create(el, ops);
    });
  },
  methods: {
    testadata() {
      adata().then(res => {
        console.info(res);
        this.data = res;
      });
    },
    settingLan(LAN) {
      this.$i18n.locale = LAN;
    },
    checkMove: function(e) {
      window.console.log('Future index: ' + e.draggedContext.futureIndex);
      console.info(this.list);
    }
  }
};
</script>

<style lang="scss" scoped>
.test{
    width: 375px;
}
</style>
