<template>
  <div class="custom-tag clearfix">
    <div
      v-for="(item, $index) in tagItem"
      :key="$index"
      :label="item.label"
      class="custom-tag-item"
      :class="item.class + (item.label === activeItem ? ' active' : '')"
      @click="onClick(item)"
    >{{ item.label ? item.label : item }} <font-awesome-icon v-if="item.clickable" class="tag-icon" :icon="['fa', item.label === activeItem ? 'caret-up' : 'caret-down']" /></div>
  </div>
</template>
<script>
export default {
  props: {
    tagItem: {
      type: Array,
      default() {
        return [];
      }
    },
    activeItem: {
      type: String,
      default() {
        return '';
      }
    }
  },
  watch: {
    activeItem: {
      handler() {}
    }
  },
  methods: {
    onClick(item) {
      this.$emit('tagClick', item);
      // if (item.clickable) {
      // }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/styles/var.scss';
.custom-tag {
  display: flex;
  flex-wrap: wrap;
  .custom-tag-item{
    @include tagStyle();
    @include tag-status();

    &.active {
      border: 1px solid #0061bf;

      .tag-icon {
        color: #0061bf;
      }
    }
  }
}
.tag-icon {
  margin-left: 2px;
  color: #aaa;
}
</style>
