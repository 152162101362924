<template>
  <div class="box bac-white m-top-15">
    <CommonHeader
      :title="title"
      :source="$t('feedback_data_hao_gov')"
      :no-feedback="true"
    />
    <cardActivityBscSearch @selectedFn="searchSelectedFn" />
    <van-list
      v-if="pageShowArr.length"
      v-model="loading"
      :finished="finished"
      :immediate-check="false"
      :finished-text="$t('no_more_txt')"
      :loading-text="$t('loading_txt')"
      @load="onLoad"
    >
      <ul class="activity-list">
        <li
          v-for="(item, index) in pageShowArr"
          :key="'public' + index"
          @click="lookActivityDetail(item)"
        >
          <!-- 论文发表 -->
          <template v-if="item.type == 'type1'">
            <span class="type type1">{{ $t("publication") }}</span>
            <label
              style="font-weight: initial"
              v-html="item.publication_title"
            ></label>
            <br />
            <div
              v-if="item.journal || item.journal_if"
              class="info-details flex-detail"
            >
              <span v-if="item.journal" class="journal e-ellipsis-l1">
                <label style="font-weight: initial" v-html="item.journal">
                </label>
              </span>
              <span v-if="item.journal_if" class="journal_if">
                IF:
                <label style="font-weight: initial" v-html="item.journal_if">
                </label>
              </span>
              <span class="time">{{ item.date | dealNullData }}</span>
            </div>
            <Tags :tag-item="item.keywordsArr" />
          </template>
          <!-- 临床试验 -->
          <template v-if="item.type == 'type2'">
            <span class="type type2">{{ $t("professional") }}</span>
            <label
              style="font-weight: initial"
              v-html="item.professional_subject"
            >
            </label>
            <br />
            <div class="profess-status flex-detail">
              <span>{{ item.status }}</span>
              <span>{{ item.period }}</span>
            </div>
            <!-- <div class="profess-status">注册时间: {{ item.registration_date | formatterDate }}</div> -->
            <div class="info-details flex-detail">
              <span v-html="item.applier_name"></span>
              <!-- <span>{{ item.two }}</span> -->
              <span class="time">{{ item.date | dealNullData }}</span>
            </div>
          </template>
          <!-- 国自然基金 -->
          <template v-if="item.type == 'type3'">
            <span class="type type3">{{ $t("project") }}</span>
            <label
              style="font-weight: initial"
              v-html="item.project_title"
            ></label>
            <br />
            <div class="info-details flex-detail">
              <span v-html="item.project_fund_name"></span>
              <span class="time">{{ item.date | dealNullData }}</span>
            </div>
          </template>
          <!-- 获奖 -->
          <template v-if="item.type == 'type4'">
            <span class="type type4">{{ $t("award") }}</span>
            <label
              style="font-weight: initial"
              v-html="item.award_name"
            ></label>
            <br />
            <div>
              <span class="time">{{ item.date | dealNullData }}</span>
            </div>
          </template>
          <!-- 指南共识 -->
          <template v-if="item.type == 'type5'">
            <span class="type type5">指南共识</span>
            <label style="font-weight: initial" v-html="item.topic"></label>
            <br />
            <div>
              <span class="time">{{ item.date | dealNullData }}</span>
            </div>
          </template>
          <!-- 专利发明 -->
          <template v-if="item.type == 'type6'">
            <span class="type type6">专利发明</span>
            <label
              style="font-weight: initial"
              v-html="item.patent_name"
            ></label>
            <br />
            <div>
              <span class="time">{{ item.date | dealNullData }}</span>
            </div>
          </template>
        </li>
      </ul>
    </van-list>
    <Nodata v-else :loading="academicLoading" :empty-txt="$t('no_data')" />
    <transition name="page-move">
      <cardActivityDetail
        v-if="activityDetailStatus"
        :detail-obj="activityDetailObj"
        backtxt="返回"
        @closeDetailFn="closeDetailFn"
      />
    </transition>
  </div>
</template>
<script>
import Tags from "@/components/Tags";
import Nodata from "@/components/Nodata";
import cardActivityDetail from "@/components/cardActivityDetail";
import cardActivityBscSearch from "@/components/cardActivityBscSearch";
import CommonHeader from "@/components/CommonHeader";
import Publications from "@/mixins/Publications";
import { List } from "vant";
import { settPageData } from "@/utils/index";

export default {
  components: {
    Tags,
    cardActivityDetail,
    cardActivityBscSearch,
    CommonHeader,
    vanList: List,
    Nodata,
  },
  mixins: [Publications],
  props: {
    academicLoading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    title: {
      type: String,
      default() {
        return "";
      },
    },
    // pageShowArr: {
    //   type: Array,
    //   default() {
    //     return [];
    //   }
    // },
    initList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      selected: "",
      loading: false,
      finished: false,
      pageTotalArr: [],
      pageShowArr: [],
      pageNumber: 0,
    };
  },
  watch: {
    academicLoading(nVal, oVal) {
      if (!nVal) {
        this.pageFn(this.initList);
      }
    },
  },
  mounted() {},
  methods: {
    errorCorrection(item) {
      this.$emit("errorCorrection", item);
    },
    // 数据分页出来
    pageFn(sortTotalArr) {
      if (sortTotalArr.length) {
        this.pageTotalArr = settPageData(sortTotalArr, 10); // 分页数据
        this.pageShowArr = this.pageTotalArr[this.pageNumber]; // (_.sortBy(pageTotalArr, (item) => { return -item.date; })).splice(0, 10);
      } else {
        this.pageShowArr = [];
        this.pageTotalArr = [];
      }
    },
    circleFilter(arr, key, selected) {
      const newArr = arr.filter((item) => {
        const hasVal = selected.some((val) => {
          if (val.active) {
            let iVal = val.value;
            // 类型
            if (key === "type") {
              return item[key] ? item[key].includes(iVal) : false;
            }
            // 第一作者
            if (key === "author_seq_no") {
              return item[key] === iVal;
            }

            if (key === "journal_if") {
              if (iVal === null) {
                return item[key] === iVal;
              } else {
                let min = iVal[0];
                let max = iVal[1];
                return item[key] > min && item[key] <= max;
              }
            }
          } else {
            return false;
          }
        });
        if (hasVal) {
          return true;
        }
      });
      return newArr;
    },
    confirmFn(selected) {
      console.info(1, selected);
      this.pageTotalArr = [];
      const initList = JSON.parse(JSON.stringify(this.initList));
      this.finished = false;
      this.loading = false;
      // this.$set(this, 'finished', false);
      if (selected.length) {
        let filterArr = [];
        for (let i = 0; i < selected.length; i++) {
          if (i === 0) {
            filterArr = this.circleFilter(
              initList,
              selected[i].field,
              selected[i].arr
            );
          } else {
            filterArr = this.circleFilter(
              filterArr,
              selected[i].field,
              selected[i].arr
            );
          }
        }
        this.pageNumber = 0;
        this.pageFn(filterArr);
      } else {
        this.pageNumber = 0;
        this.pageFn(initList);
      }
    },
    bscCircleFilter(obj) {
      const initList = JSON.parse(JSON.stringify(this.initList));
      const newArr = initList
        .filter((item) => {
          return item[obj.field] ? item[obj.field].includes(obj.value) : false; // 过滤一级类型 论文、国自然、专利等
        })
        .filter((item) => {
          let valueArr = [];
          let hasCheck = false;
          obj.children.forEach((val) => {
            // 验证有没有二级过滤,并且把验证值传入数组
            if (val.active) {
              if (Array.isArray(val.value)) {
                valueArr.push(...val.value);
              } else {
                valueArr.push(val.value);
              }

              hasCheck = true;
            }
          });
          if (obj.children.length && hasCheck) {
            // 如果有
            const hasVal = obj.children.some((val) => {
              if (val.active) {
                let iVal = val.value;
                let key = val.field; // 字段
                // 第一作者 or 其他
                // if (key === 'first_author') {
                //   if ((item[key] && iVal > 0) || (!item[key] && iVal === 0)) {
                //     return true;
                //   } else {
                //     return false;
                //   }
                // }
                if (key === "author_seq_no") {
                  if (iVal) {
                    console.log(
                      item[key],
                      valueArr,
                      valueArr.includes(item[key])
                    );
                    return valueArr.includes(item[key]);
                  } else {
                    return item[key] !== 1;
                  }
                }
                if (key === "is_pi") {
                  if (iVal) {
                    return valueArr.includes(item[key]);
                  } else {
                    return item[key] !== 1;
                  }
                  // return item[key] === iVal;
                }
                if (key === "journal_if") {
                  if (iVal === null) {
                    return item[key] === iVal;
                  } else {
                    let min = iVal[0];
                    let max = iVal[1];
                    return item[key] > min && item[key] <= max;
                  }
                }
              } else {
                return false;
              }
            });
            if (hasVal) {
              return true;
            }
          } else {
            return true;
          }
        });
      return newArr;
    },
    searchSelectedFn(selected, time, selectTag) {
      this.pageNumber = 0;
      this.finished = false;
      this.loading = false;
      this.pageTotalArr = [];
      let filterArr = this.bscCircleFilter(selected);
      if (time) {
        let timeArr = time.split("-");
        let nfilterArr = filterArr.filter((item) => {
          let dateCheck = false;
          if (timeArr.length === 2) {
            if (timeArr[0] && timeArr[1]) {
              dateCheck =
                parseInt(item.date) > parseInt(timeArr[0]) &&
                parseInt(item.date) <= parseInt(timeArr[1]);
            } else if (timeArr[0]) {
              dateCheck = parseInt(item.date) > parseInt(timeArr[0]);
            } else if (timeArr[1]) {
              dateCheck = parseInt(item.date) <= parseInt(timeArr[1]);
            }
          } else {
            dateCheck = parseInt(item.date) <= parseInt(timeArr[1]);
          }
          return dateCheck;
        });
        if (nfilterArr.length) {
          this.pageFn(nfilterArr);
        } else {
          this.pageShowArr = [];
        }
      } else {
        if (filterArr.length) {
          this.pageFn(filterArr);
        } else {
          this.pageShowArr = [];
        }
      }
    },
    paging() {
      // if (this.activitySelect === 'academic') {
      ++this.pageNumber;
      if (this.pageNumber < this.pageTotalArr.length) {
        this.pageTotalArr[this.pageNumber].forEach((ele) => {
          this.pageShowArr.push(ele);
        });
        this.loading = false;
      } else {
        this.finished = true;
      }
      // }
    },
    onLoad() {
      setTimeout(() => {
        this.paging();
      }, 1500);
    },
  },
};
</script>
<style lang="scss" scoped>
.phy-title {
  font-size: 16px;
  color: #000;
  text-align: left;
  padding: 10px;
}
.flex-detail {
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
  justify-content: space-between;
  span {
    padding-right: 10px;
  }
  .journal {
    flex: 1;
  }
  .journal_if {
    width: 40px;
  }
}
</style>
