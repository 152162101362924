<template>
  <div
    style="
      padding-top: 12px;
      background-color: #f0f0f4;
      margin-left: -20px;
      margin-right: -20px;
    "
  >
    <Nodata v-if="!list.length" :loading="loading" :empty-txt="$t('no_data')" />
    <div
      v-for="(item, index) in list"
      :key="index"
      style="
        margin-bottom: 12px;
        line-height: 2;
        text-align: left;
        padding: 20px;
        background-color: white;
      "
    >
      <div style="font-size: 16px; font-weight: bold">
        {{ item.insight_question?.question }}
      </div>
      <div style="font-size: 12px; color: #b2b2b2">
        {{ formatterDatenEn(item.insight_time) }}
      </div>
      <div style="font-size: 14px">{{ item.insight_content }}</div>
    </div>
  </div>
</template>

<script>
import {fetchPhysicianMslInsights} from '@/api/kol';
import {formatterDatenEn} from '@/filters/index';
import Nodata from '@/components/Nodata';

export default {
  components: {
    Nodata,
  },
  data() {
    return {
      list: [],
      loading: false,
    };
  },
  computed: {
    physician_id() {
      return this.$route.query?.physician_id;
    },
  },
  mounted() {
    this.getPhysicianInsightList();
  },
  methods: {
    formatterDatenEn,
    async getPhysicianInsightList() {
      this.loading = true;
      try {
        const list = await fetchPhysicianMslInsights({
          physician_id: this.physician_id,
        });
        this.list = list.value.reverse();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
