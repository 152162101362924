<template>
  <!-- 外部医生参会情况 -->
  <div class="tag-container bac-white">
    <div class="tag-title box-right">
      <CommonHeader
        title="外部医生参会情况"
        :icon="'user-tag'"
        :no-feedback="true"
      >
        <div v-if="canEditStatus" slot="before">
          <img
            src="@/assets/lundbeck/add.png"
            alt=""
            width="24"
            style="margin: 6px 6px 0 0"
            @click="addPhysicanMeetingEx"
          />
        </div>
      </CommonHeader>
      <div v-if="exMeetingList.length > 0" style="padding: 20px 0">
        <div
          v-for="item in exMeetingList"
          :key="item.physician_meeting_ex_join_id"
          class="meeting-card"
        >
          <h4 class="meeting-card-title" @click="lookPhysicanMeetingEx(item)">
            {{ item.subject }}
          </h4>
          <div class="meeting-card-content">
            <p>
              <span>{{ foramtDate(item.start_date) }}</span>
              -
              <span>{{ foramtDate(item.end_date) }}</span>
            </p>

            <p
              class="meeting-card-delete"
              @click="deleteMeetingEx(item.physician_meeting_ex_join_id)"
            >
              <van-icon name="delete-o" />
              删除
            </p>
          </div>
        </div>
      </div>

      <Nodata
        v-if="exMeetingList.length == 0"
        :loading="loading"
        :empty-txt="$t('no_data')"
      />
    </div>
    <!-- 返回医生详情 -->
    <swipeDialog
      v-if="meetingStatus"
      title="外部医生参会情况"
      backtxt="返回"
      @closeFn="closeFn"
    >
      <div v-if="isEdit" class="meeting">
        <div class="meeting-filed">
          <p class="meeting-filed-text">
            会议主题<span style="color: #cc4b47">*</span>
          </p>
          <van-field
            v-model="meetingData.meetingTheme"
            :border="false"
            class="field-border"
            placeholder="请输入"
          />
          <p v-if="meetingThemeTips" class="required-text">请填写会议主题</p>
        </div>

        <div class="meeting-filed">
          <p class="meeting-filed-text">
            开始日期-结束日期<span style="color: #cc4b47">*</span>
          </p>
          <div class="meeting-date">
            <van-field
              v-model="meetingData.startDate"
              style="width: 45%"
              :border="false"
              class="field-border"
              placeholder="开始日期"
              right-icon="notes-o"
              @click="calendarShow = true"
            />
            <span> - </span>

            <van-field
              v-model="meetingData.endDate"
              style="width: 45%"
              :border="false"
              class="field-border"
              placeholder="结束日期"
              right-icon="notes-o"
              @click="calendarShow = true"
            />
            <van-calendar
              v-model="calendarShow"
              :min-date="minDate"
              color="#0062b9"
              type="range"
              allow-same-day
              @confirm="calendarConfirm"
            />
          </div>
          <p v-if="meetingDateTips" class="required-text">
            请输入开始日期和结束日期
          </p>
        </div>

        <div class="meeting-filed">
          <p class="meeting-filed-text">参加厂家赞助的会议-bonus信息</p>
          <van-field
            v-model="meetingData.bonus"
            :border="false"
            class="field-border"
            placeholder="请输入"
          />
        </div>
        <div class="meeting-filed">
          <p class="meeting-filed-text">
            参会医生 <span class="input-tip">(多个医生用逗号分隔)</span>
          </p>
          <van-field
            v-model="meetingData.participantPhysician"
            :border="false"
            rows="3"
            autosize
            type="textarea"
            class="field-border"
            placeholder="请输入"
          />
        </div>
        <div class="meeting-filed">
          <p class="meeting-filed-text">会议规模</p>
          <van-field
            v-model="meetingData.meetingSize"
            :border="false"
            class="field-border"
            placeholder="人/院/省市/全国"
          />
        </div>
        <div class="meeting-filed">
          <p class="meeting-filed-text">
            涉及厂家<span class="input-tip">(多个厂家用逗号分隔)</span>
          </p>
          <van-field
            v-model="meetingData.manufactor"
            :border="false"
            rows="3"
            autosize
            type="textarea"
            class="field-border"
            placeholder="请输入"
          />
        </div>
        <div class="meeting-filed">
          <p class="meeting-filed-text">
            学术机构<span class="input-tip">(多个机构用逗号分隔)</span>
          </p>
          <van-field
            v-model="meetingData.learning"
            :border="false"
            rows="3"
            autosize
            type="textarea"
            class="field-border"
            placeholder="请输入"
          />
        </div>
        <div class="meeting-filed">
          <p class="meeting-filed-text">
            照片<span class="input-tip">(单张图片大小不能超过2M)</span>
          </p>
          <div class="meeting-picture">
            <van-uploader
              v-model="pictureList"
              :max-size="2 * 1024 * 1024"
              :after-read="afterReadPicture"
              :max-count="9"
              upload-icon="plus"
              @oversize="pictureOverSize"
            />
          </div>
        </div>
        <div class="meeting-filed">
          <div class="meeting-file-header">
            <p class="meeting-filed-text">
              附件<span class="input-tip">(单个文件大小不能超过5M)</span>
            </p>

            <van-uploader
              v-model="fileList"
              :max-size="5 * 1024 * 1024"
              :after-read="afterReadFile"
              :max-count="9"
              :preview-image="false"
              accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
              @oversize="fileOverSize"
            >
              <p class="meeting-filed-right"><van-icon name="plus" /> 添加</p>
            </van-uploader>
          </div>
          <div
            v-for="(item, index) in fileList"
            :key="index"
            class="meeting-file-show"
          >
            <p class="meeting-file-name">
              <img src="@/assets/lundbeck/attachment.png" width="16" alt="" />
              {{ item.name ? item.name : item.file.name }}
            </p>
            <p class="meeting-file-remove" @click="removeFile(item.fileId)">
              ×
            </p>
          </div>
        </div>
        <van-button
          class="meeting-btn"
          color="#1B75BC"
          round
          @click="saveMeetingEx"
        >
          提交
        </van-button>
      </div>
      <div v-else class="meeting-detail">
        <div class="meeting-filed">
          <p class="meeting-filed-text">会议主题</p>
          <p class="meeting-filed-content">{{ meetingData.meetingTheme }}</p>
        </div>

        <div class="meeting-filed">
          <p class="meeting-filed-text">会议日期</p>
          <p class="meeting-filed-content">
            {{ foramtDate(meetingData.startDate) }}-{{
              foramtDate(meetingData.endDate)
            }}
          </p>
        </div>

        <div class="meeting-filed">
          <p class="meeting-filed-text">参加厂家赞助的会议-bonus信息</p>
          <p class="meeting-filed-content">{{ meetingData.bonus }}</p>
        </div>
        <div class="meeting-filed">
          <p class="meeting-filed-text">
            参会医生 <span class="input-tip">(多个医生用逗号分隔)</span>
          </p>
          <p class="meeting-filed-content">
            {{ meetingData.participantPhysician }}
          </p>
        </div>
        <div class="meeting-filed">
          <p class="meeting-filed-text">会议规模</p>
          <p class="meeting-filed-content">{{ meetingData.meetingSize }}</p>
        </div>
        <div class="meeting-filed">
          <p class="meeting-filed-text">
            涉及厂家<span class="input-tip">(多个厂家用逗号分隔)</span>
          </p>
          <p class="meeting-filed-content">{{ meetingData.manufactor }}</p>
        </div>
        <div class="meeting-filed">
          <p class="meeting-filed-text">
            学术机构<span class="input-tip">(多个机构用逗号分隔)</span>
          </p>
          <p class="meeting-filed-content">{{ meetingData.learning }}</p>
        </div>
        <div class="meeting-filed">
          <p class="meeting-filed-text">照片</p>
          <div class="meeting-picture">
            <van-uploader
              v-model="pictureList"
              :max-count="detailCount"
              :deletable="false"
            />
            <!-- <img
              v-for="item in pictureList"
              :key="item.key"
              :src="item.content"
              width="85"
              alt=""
            /> -->
          </div>
        </div>
        <div class="meeting-filed">
          <div class="meeting-file-header">
            <p class="meeting-filed-text">附件</p>
          </div>
          <div
            v-for="(item, index) in fileList"
            :key="index"
            class="meeting-file-show"
          >
            <a class="meeting-file-name" :href="item.url" :download="item.name">
              <img src="@/assets/lundbeck/attachment.png" width="16" alt="" />
              {{ item.name }}
            </a>
          </div>
        </div>
        <van-button
          class="meeting-btn"
          color="#1B75BC"
          round
          @click="changeEditStatus"
        >
          修改
        </van-button>
      </div>
    </swipeDialog>
  </div>
</template>
<script>
import * as py from "@/api/Physicians";
import { Button, Field, Calendar, Uploader, Icon, Dialog } from "vant";
import * as moment from "moment";
import CommonHeader from "@/components/CommonHeader";
import swipeDialog from "@/views/layout/swipeDialog";
import { s3Service } from "@/utils/aws/index";
import { getMimeTypeBySuffix, getLocalStorage } from "@/utils/index";
import Nodata from "@/components/Nodata";
export default {
  name: "PhysicianMeetingEx",
  components: {
    Nodata,
    CommonHeader,
    swipeDialog,
    vanField: Field,
    vanCalendar: Calendar,
    vanUploader: Uploader,
    vanIcon: Icon,
    vanButton: Button,
  },
  props: {},
  data() {
    return {
      isEdit: false,
      loading: false,
      exMeetingList: [],
      meetingStatus: false,
      meetingData: {
        meetingTheme: "",
        startDate: "",
        endDate: "",
        bonus: "",
        participantPhysician: "",
        meetingSize: "",
        manufactor: "",
        learning: "",
        pictureList: [],
        fileList: [],
      },
      meetingThemeTips: false,
      meetingDateTips: false,
      calendarShow: false,
      minDate: new Date("2020-01-01"),
      pictureList: [],
      fileList: [],
      detailCount: 0,
      showPopover: false,
      currentDeleteId: "",
      canEditStatus: false,
      ajaxStatus: false
    };
  },
  computed: {},
  mounted() {
    if (getLocalStorage('territory_type') === 'MR') {
      this.canEditStatus = true;
    }
    this.getMeetingEx();
    // py.getS3ByKey("LFile/meeting/易倍申分型_1663922248000.png").then((res) => {
    //   console.log(res);
    // });
  },

  methods: {
    getMeetingEx() {
      this.loading = true;
      py.getPhysicianMeetingExJoins(this.$route.query.physician_id).then(
        (res) => {
          if (res && res.value) {
            this.exMeetingList = res.value;
          }
          this.loading = false;
        }
      );
    },
    foramtDate(date) {
      return moment(date).format("YYYY.MM.DD");
    },
    addPhysicanMeetingEx() {
      this.$store.dispatch("setDialogStatus", true);
      this.isEdit = true;
      this.meetingStatus = true;
      this.meetingData = {
        meetingTheme: "",
        startDate: "",
        endDate: "",
        bonus: "",
        participantPhysician: "",
        meetingSize: "",
        manufactor: "",
        learning: "",
        pictureList: [],
        fileList: [],
      };
      this.pictureList = [];
      this.fileList = [];
    },
    lookPhysicanMeetingEx(item) {
      this.$store.dispatch("setDialogStatus", true);
      this.isEdit = false;
      this.meetingStatus = true;
      this.setMeetingData(item);
    },
    setMeetingData(item) {
      this.meetingData = {
        id: item.physician_meeting_ex_join_id,
        meetingTheme: item.subject,
        startDate: this.foramtDate(item.start_date),
        endDate: this.foramtDate(item.end_date),
        bonus: item.bonus,
        meetingSize: item.scale,
        participantPhysician: item.physician_join_text,
        manufactor: item.manufactor,
        learning: item.learning,
        pictureList: JSON.parse(item.attached_images_json),
        fileList: JSON.parse(item.attached_json),
      };
      let allPicture = this.meetingData.pictureList.map((e) => {
        return py.getS3ByKey(e.key);
      });
      Promise.all(allPicture).then((value) => {
        this.pictureList = [];
        let tempPic = [];
        let allBlod = [];
        this.detailCount = this.meetingData.pictureList.length;
        for (let i = 0; i < this.detailCount; i++) {
          let currentMimeType = getMimeTypeBySuffix(
            this.meetingData.pictureList[i].key.split(".").pop().toLowerCase()
          );
          let blob = new Blob([value[i].data], {
            type: currentMimeType,
          });
          allBlod.push(this.blobToBase64(blob));
          tempPic.push({
            fileId: this.meetingData.pictureList[i].fileId,
            name: this.meetingData.pictureList[i].name,
            key: this.meetingData.pictureList[i].key,
            // content: window.URL.createObjectURL(blob),
          });
        }
        if (tempPic.length) {
          this.getPicList(allBlod, tempPic);
        }
      });
      let allFile = this.meetingData.fileList.map((e) => {
        return py.getS3ByKey(e.key);
      });
      Promise.all(allFile).then((value) => {
        this.fileList = [];
        for (let i = 0; i < this.meetingData.fileList.length; i++) {
          let currentMimeType = getMimeTypeBySuffix(
            this.meetingData.fileList[i].key.split(".").pop().toLowerCase()
          );
          let blob = new Blob([value[i].data], {
            type: currentMimeType,
          });

          this.fileList.push({
            fileId: this.meetingData.fileList[i].fileId,
            name: this.meetingData.fileList[i].name,
            key: this.meetingData.fileList[i].key,
            url: window.URL.createObjectURL(blob),
          });
        }
      });
    },
    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          resolve(e.target.result);
        };
        // readAsDataURL
        fileReader.readAsDataURL(blob);
        fileReader.onerror = () => {
          reject(new Error("文件流异常"));
        };
      });
    },
    getPicList(blodRequst, tempPicArr) {
      Promise.all(blodRequst).then((res) => {
        if (res && res.length) {
          for (let i = 0; i < tempPicArr.length; i++) {
            tempPicArr[i].content = res[i];
          }
          this.pictureList = tempPicArr;
        }
      });
    },

    closeFn() {
      this.$store.dispatch("setDialogStatus", false);
      this.meetingStatus = false;
      this.meetingDateTips = false;
      this.meetingThemeTips = false;
    },
    calendarConfirm(date) {
      const [start, end] = date;
      this.calendarShow = false;
      this.meetingData.startDate = moment(start).format("YYYY-MM-DD");
      this.meetingData.endDate = moment(end).format("YYYY-MM-DD");
    },
    afterReadPicture(file) {
      // 此时可以自行将文件上传至服务器
      file.fileId = Date.parse(new Date());
      file.status = "uploading";
      s3Service
        .upload(file.file, {
          dirPath: "LFile/meeting",
          filename:
            file.file.name.substring(0, file.file.name.lastIndexOf(".")) +
            "_" +
            file.fileId,
        })
        .then((res) => {
          console.log("res", res);
          if (res.is_success) {
            file.status = "done";
            file.key = res.key;
          } else {
            file.status = "failed";
          }
          console.log("pictureList", this.pictureList);
        });
    },
    afterReadFile(file) {
      // 此时可以自行将文件上传至服务器
      file.fileId = Date.parse(new Date());
      file.status = "uploading";
      s3Service
        .upload(file.file, {
          dirPath: "LFile/meeting",
          filename:
            file.file.name.substring(0, file.file.name.lastIndexOf(".")) +
            "_" +
            file.fileId,
        })
        .then((res) => {
          console.log("res", res);
          if (res.is_success) {
            file.status = "done";
            file.key = res.key;
          } else {
            file.status = "failed";
          }
          console.log("fileList", this.fileList);
        });
    },
    pictureOverSize() {
      this.$toast.fail("单个图片大小不能超过 2M");
    },

    fileOverSize() {
      this.$toast.fail("单个文件大小不能超过 5M");
    },
    removeFile(fileId) {
      let index = this.fileList.findIndex((e) => e.fileId === fileId);
      if (index || index === 0) {
        this.fileList.splice(index, 1);
      }
    },
    saveMeetingEx() {
      if (this.ajaxStatus) {
        return;
      }
      if (!this.meetingData.meetingTheme) {
        this.meetingThemeTips = true;
        return;
      }
      if (!(this.meetingData.startDate || this.meetingData.endDate)) {
        this.meetingDateTips = true;
        return;
      }
      this.meetingData.pictureList = this.pictureList.map((item) => {
        return {
          fileId: item.fileId,
          name: item.name
            ? item.name
            : item.file.name.substring(0, item.file.name.lastIndexOf(".")),
          key: item.key,
        };
      });
      this.meetingData.fileList = this.fileList.map((item) => {
        return {
          fileId: item.fileId,
          name: item.name
            ? item.name
            : item.file.name.substring(0, item.file.name.lastIndexOf(".")),
          key: item.key,
        };
      });
      let params = {
        subject: this.meetingData.meetingTheme,
        start_date: this.meetingData.startDate,
        end_date: this.meetingData.endDate,
        bonus: this.meetingData.bonus,
        scale: this.meetingData.meetingSize,
        physician_join_text: this.meetingData.participantPhysician,
        manufactor: this.meetingData.manufactor,
        learning: this.meetingData.learning,
        attached_images_json: JSON.stringify(this.meetingData.pictureList),
        attached_json: JSON.stringify(this.meetingData.fileList),
        physician_id: this.$route.query.physician_id,
      };
      this.ajaxStatus = true;
      if (this.meetingData.id) {
        params.physician_meeting_ex_join_id = this.meetingData.id;
        py.updatePhysicianMeetingExJoin(params).then((res) => {
          if (res.is_success) {
            this.$toast.success("修改成功");
            this.getMeetingEx();
            this.closeFn();
          } else {
            this.$toast.fail("修改失败，请重试");
          }
          this.ajaxStatus = false;
        });
      } else {
        py.savePhysicianMeetingExJoin(params).then((res) => {
          if (res.is_success) {
            this.$toast.success("添加成功");
            this.getMeetingEx();
            this.closeFn();
          } else {
            this.$toast.fail("添加失败，请重试");
          }
          this.ajaxStatus = false;
        });
      }
    },
    changeEditStatus() {
      this.isEdit = true;
    },
    beforeClose(action, done) {
      if (action === "confirm") {
        if (this.currentDeleteId) {
          py.deletePhysicianMeetingExJoin(this.currentDeleteId).then((res) => {
            if (res === this.currentDeleteId) {
              this.$toast.success("删除成功");
              setTimeout(() => {
                this.getMeetingEx();
              }, 100);
            } else {
              this.$toast.fail("删除失败，请重试");
            }
            done();
          });
        } else {
          this.$toast.fail("删除失败，请重试");
          done();
        }
      } else {
        done();
      }
    },

    deleteMeetingEx(id) {
      this.currentDeleteId = id;
      Dialog.confirm({
        message: "是否删除这条记录",
        beforeClose: this.beforeClose,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tag-title {
  padding: 10px 10px 0 10px;
}
.meeting-card {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  opacity: 1;
  border-radius: 10px;
  padding: 20px 15px;
  text-align: left;
  margin-bottom: 12px;
  .meeting-card-title {
    font-size: 16px;
    font-family: Arial;
    font-weight: 400;
    color: #2c3034;
    margin-bottom: 16px;
  }
  .meeting-card-content {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-family: Arial;
    font-weight: 400;
    color: #838a95;
    .meeting-card-delete {
      font-family: Arial;
      font-weight: 400;
      color: #1b75bc;
    }
  }
}
.meeting {
  padding: 30px;
  .meeting-filed {
    margin-bottom: 20px;
    .meeting-filed-text {
      font-size: 13px;
      font-family: Arial;
      font-weight: 400;
      color: #2c3034;
      text-align: left;
      margin-bottom: 10px;
    }
  }
  .meeting-date {
    display: flex;
    justify-content: space-around;
    align-items: center;
    span {
      margin: 0 5px;
    }
  }
  .meeting-picture {
    display: flex;
  }
  .meeting-file-header {
    display: flex;
    justify-content: space-between;
  }
  .meeting-filed-right {
    font-size: 16px;
    font-family: Arial;
    font-weight: bold;
    color: #1b75bc;
  }
  .field-border {
    border: 1px solid #dce0e6;
    border-radius: 4px;
    padding: 5px 10px;
    :v-deep .van-field__control{
      -webkit-user-select: auto;
    }
  }

  .required-text {
    display: flex;
    font-size: 13px;
    color: #cc4b47;
  }
}
.meeting-detail {
  padding: 30px;
  .meeting-filed {
    margin-bottom: 20px;

    .meeting-filed-text {
      font-size: 13px;
      font-family: Arial;
      font-weight: 400;
      color: #838a95;
      text-align: left;
      margin-bottom: 10px;
    }
    .meeting-filed-content {
      display: flex;
      font-size: 16px;
      margin-top: 15px;
      font-family: Arial;
      font-weight: 400;
      color: #2c3034;
    }
    .meeting-picture {
      display: flex;
    }
  }
}
.meeting-btn {
  width: 100%;
  margin-top: 20px;
}
.meeting-file-show {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f7f8;
  border-radius: 10px;
  text-align: left;
  padding: 10px;
  margin-top: 10px;
  .meeting-file-name {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: Arial;
    font-weight: 400;
    color: #1b75bc;
    img {
      margin-right: 5px;
    }
  }
  .meeting-file-remove {
    font-size: 20px;
    color: #1b75bc;
  }
  .input-tip {
    font-size: 10px;
    color: #838a95;
    margin-left: 5px;
  }
}
</style>
