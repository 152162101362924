<template>
  <div>
    <div @click="show = true">
      <slot></slot>
    </div>
    <transition name="page-move">
      <div v-if="show" class="swipe-wrapper">
        <header class="header flex justify-center">
          <p
            class="h-title"
            style="
              max-width: 50%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            "
          >
            {{ url }}
          </p>
          <i class="cubeic-back" @click.stop="show = false"> 返回</i>
        </header>
        <iframe
          :src="url"
          frameborder="0"
          style="height: 100vh; width: 100vw"
        ></iframe>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: ['url'],
  data() {
    return {
      show: false,
    };
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import '@/styles/swipeDialog.scss';
</style>
