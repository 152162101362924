var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"onlineHelp cg-box"},[(!_vm.pageTotalNum)?_c('van-loading',{staticClass:"loading"},[_vm._v("加载中...")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.pageTotalNum),expression:"pageTotalNum"}],key:_vm.url,staticClass:"pdf-box"},[_c('pdf',{ref:"pdf",style:({
        transform:
          'translate(' +
          _vm.posX +
          'px,' +
          _vm.posY +
          'px) translateZ(0px) scale(' +
          _vm.dis +
          ')'
      }),attrs:{"id":"pdfPreview","src":_vm.pdfLink,"page":_vm.pageNum},on:{"progress":function($event){return _vm.loadedRatioFunction($event)},"page-loaded":function($event){return _vm.pageLoaded($event)},"num-pages":function($event){_vm.pageTotalNum = $event},"error":function($event){return _vm.pdfError($event)},"link-clicked":function($event){_vm.page = $event}}}),_c('a',{staticClass:"file-down",attrs:{"href":_vm.pdfAdress,"download":_vm.pdfAdress,"target":"_blank"}},[_c('img',{staticClass:"download",attrs:{"src":require("@/assets/nba/down.png"),"width":"20","alt":""}})])],1),(_vm.pageTotalNum && !_vm.isTip)?_c('div',{staticClass:"tools"},[_c('p',[_c('van-icon',{attrs:{"name":"arrow-left"},on:{"click":function($event){$event.stopPropagation();return _vm.prePage.apply(null, arguments)}}})],1),_c('div',{staticClass:"page"},[_vm._v("第 "+_vm._s(_vm.pageNum)+" /"+_vm._s(_vm.pageTotalNum)+"页")]),_c('p',[_c('van-icon',{attrs:{"name":"arrow"},on:{"click":function($event){$event.stopPropagation();return _vm.nextPage.apply(null, arguments)}}})],1)]):_vm._e(),(_vm.isTip)?_c('micro-tip'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }