<template>
  <div>
    <div v-for="(e, i) in tagTypeArr" :key="i">
      <div
        v-if="e.labelType && e.labelType.toString() !== 'undefined'"
        class="tag-line-label"
      >
        <div class="tag-lable-type">
          <span class="tag-label-icon"></span>
          {{ e.labelType }}:
        </div>

        <div
          v-if="e.labelType === '画像标签' && canEditStatus"
          class="tag-label-question"
          @click="openPortraitDialog"
        >
          <img src="@/assets/lundbeck/NBA/edit.png" width="12" alt="" />
          问卷
        </div>
        <div
          v-if="
            e.labelType === '业务标签' && product.indexOf('Brintellix') > -1 && canEditStatus
          "
          class="tag-label-question"
          @click="openBusinessDialog"
        >
          <img src="@/assets/lundbeck/NBA/edit.png" width="12" alt="" />
          问卷
        </div>
      </div>
      <!-- v-if="e.labelType === '业务标签' && product.indexOf('Brintellix') > -1" -->
      <div
        style="padding: 0 10px"
      >
        <Nodata
          v-if="e.tagArr.length === 0"
          :empty-txt="$t('no_data')"
          style="height: 26px"
        />
        <div v-for="(el, index) in e.tagArr" :key="index">
          <div
            v-if="el.tagArr.length"
            class="custom-tag clearfix"
            style="margin-bottom: 15px"
          >
            <h3 v-if="el.labelType != 'noneTepArr'" class="sub-label-type">
              {{ el.labelType }}
            </h3>
            <div
              v-for="(label, $index) in el.tagArr"
              :key="$index"
              :label="label.label"
              class="custom-tag-item"
              :class="el.class + (label.label === activeItem ? ' active' : '')"
            >
              {{ label.label ? label.label : '' }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="false" class="custom-tag clearfix">
        <Nodata
          v-if="e.tagArr.length === 0"
          :empty-txt="$t('no_data')"
          style="height: 26px"
        />
        <div
          v-for="(item, $index) in e.tagArr"
          :key="$index"
          :label="item.label"
          class="custom-tag-item"
          :class="item.class + (item.label === activeItem ? ' active' : '')"
          @click="onClick(item)"
        >
          {{ item.label ? item.label : '' }}
          <font-awesome-icon
            v-if="item.clickable"
            class="tag-icon"
            :icon="[
              'fa',
              item.label === activeItem ? 'caret-up' : 'caret-down',
            ]"
          />
        </div>
      </div>
    </div>
    <div>
      <van-dialog
        v-model="portraitLabelQuestion"
        theme="round-button"
        confirm-button-color="#0062b9"
        :before-close="beforePortraitClose"
      >
        <div slot="title" class="diglog-title">
          <h3 class="diglog-title-text">画像标签问卷</h3>
          <div class="diglog-title-close" @click="closeDiglog()">×</div>
        </div>
        <div class="diglog-questions">
          <div class="question">
            <p class="question-text">
              1. 目前医生注册了哪个线上诊疗平台？
              <span style="color: #cc4b47">*</span>
            </p>
            <van-checkbox-group v-model="portraitAnswers.firstAnswer">
              <van-checkbox name="京东" shape="square" class="answer-checkbox">
                京东
              </van-checkbox>
              <van-checkbox
                name="好大夫"
                shape="square"
                class="answer-checkbox"
              >
                好大夫
              </van-checkbox>
              <van-checkbox name="微医" shape="square" class="answer-checkbox">
                微医
              </van-checkbox>
              <van-checkbox
                name="不了解"
                shape="square"
                class="answer-checkbox"
              >
                不了解
              </van-checkbox>
              <van-checkbox name="其他" shape="square" class="answer-checkbox">
                其他
              </van-checkbox>
            </van-checkbox-group>
            <van-field
              v-if="getDisableStatus('其他')"
              v-model="portraitAnswers.otherAnswer"
              :border="true"
              placeholder="请输入"
            />
            <p v-if="portraitLabelAnswerTips" style="color: #cc4b47">
              这是必答题，请选择答案！
            </p>
          </div>
        </div>
      </van-dialog>
      <van-dialog
        v-model="businessLabelQuestion"
        title="业务标签问卷"
        theme="round-button"
        confirm-button-color="#0062b9"
        :before-close="beforeBusinessClose"
      >
        <div slot="title" class="diglog-title">
          <h3 class="diglog-title-text">业务标签问卷</h3>
          <div class="diglog-title-close" @click="closeDiglog()">×</div>
        </div>
        <div class="diglog-questions">
          <div class="question">
            <p class="question-text">
              1.请填写医生工作人群的占比数量（在工作的患者/总患者数）
            </p>
            <p class="percent-answer">{{ businessAnswer.firstAnswer }} %</p>
            <van-slider v-model="businessAnswer.firstAnswer" :step="1" />
          </div>
          <div class="question">
            <p class="question-text">
              2.请填写医生学生人群的占比数量（在上学的患者/总患者数）
            </p>
            <p class="percent-answer">{{ businessAnswer.secondAnswer }} %</p>
            <van-slider v-model="businessAnswer.secondAnswer" :step="1" />
          </div>
          <div class="question">
            <p class="question-text">3. 医生是否推荐非医保药物</p>
            <van-radio-group
              v-model="businessAnswer.thirdAnswer"
              direction="horizontal"
              class="answer"
            >
              <van-radio name="是" style="margin-right: 30%">是</van-radio>
              <van-radio name="否"> 否 </van-radio>
            </van-radio-group>
          </div>
          <div class="question">
            <p class="question-text">4. 医生对于原研仿制药的倾向</p>
            <van-radio-group
              v-model="businessAnswer.fourthAnswer"
              class="answer"
              direction="horizontal"
            >
              <van-radio name="倾向原研"> 倾向原研 </van-radio>
              <van-radio name="倾向仿制"> 倾向仿制 </van-radio>
            </van-radio-group>
          </div>
          <div class="question">
            <p class="question-text">
              5.当功能或某些症状恢复不佳时，是否倾向换药
            </p>
            <van-radio-group
              v-model="businessAnswer.fifthAnswer"
              direction="horizontal"
              class="answer"
            >
              <van-radio name="是" style="margin-right: 30%">是</van-radio>
              <van-radio name="否"> 否 </van-radio>
            </van-radio-group>
          </div>
        </div>
      </van-dialog>
    </div>
    <!-- <div
      v-for="(item, $index) in tagItem"
      :key="$index"
      :label="item.label"
      class="custom-tag-item"
      :class="item.class + (item.label === activeItem ? ' active' : '')"
      @click="onClick(item)"
    >
      {{ item.label ? item.label : item }}
      <font-awesome-icon
        v-if="item.clickable"
        class="tag-icon"
        :icon="['fa', item.label === activeItem ? 'caret-up' : 'caret-down']"
      />
    </div> -->
  </div>
</template>
<script>
import _ from "underscore";
import * as py from "@/api/Physicians";
import { getLocalStorage } from "@/utils";
import Nodata from "@/components/Nodata";
import {
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
  Field,
  Slider,
} from "vant";
export default {
  components: {
    vanRadioGroup: RadioGroup,
    vanRadio: Radio,
    vanCheckbox: Checkbox,
    vanCheckboxGroup: CheckboxGroup,
    vanField: Field,
    vanSlider: Slider,
    Nodata,
  },
  props: {
    tagItem: {
      type: Array,
      default() {
        return [];
      },
    },
    activeItem: {
      type: String,
      default() {
        return "";
      },
    },
    product: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      tagTypeArr: [
        {
          labelType: "画像标签",
          tagArr: [],
        },
        {
          labelType: "学术标签",
          tagArr: [],
        },
        {
          labelType: "业务标签",
          tagArr: [],
        },
      ],
      portraitLabelQuestion: false,
      portraitAnswers: {
        firstAnswer: [],
        otherAnswer: "",
      },
      businessAnswer: {
        firstAnswer: 0,
        secondAnswer: 0,
        thirdAnswer: null,
        fourthAnswer: null,
        fifthAnswer: null,
      },
      portraitLabelAnswerTips: false,
      businessLabelQuestion: false,
      canEditStatus: false
    };
  },
  watch: {
    activeItem: {
      handler() {},
    },
  },
  mounted() {
    if (getLocalStorage('territory_type') === 'MR') {
      this.canEditStatus = true;
    }
    this.tagTypeArr.forEach(ele => {
      ele.tagArr = [];
    });
    this.getLabelByType();
  },
  methods: {
    getLabelByType() {
      const tagsByType = _.groupBy(this.tagItem, "labelType");
      for (let labelType in tagsByType) {
        this.tagTypeArr.map((item) => {
          if (item.labelType === labelType) {
            if (tagsByType[labelType] && tagsByType[labelType].length) {
              let subLabelTypeMap = _.groupBy(tagsByType[labelType], "subLabelType");
              let noneSubArr = tagsByType[labelType].filter(sub => !sub.subLabelType || sub.subLabelType === 'null');
              let nuiSubArr = this.getMustTier(_.uniq(noneSubArr, true, (val) => val.label));
              item.tagArr = [];
              item.tagArr.push({
                labelType: 'noneTepArr',
                tagArr: nuiSubArr,
              });
              for (let subTp in subLabelTypeMap) {
                // eslint-disable-next-line no-unneeded-ternary
                if (subTp && subTp !== 'null' && subTp !== '') {
                  let nuiSubArrA = this.getMustTier(_.uniq(subLabelTypeMap[subTp], true, (val) => val.label));
                  item.tagArr.push({
                    labelType: subTp,
                    tagArr: nuiSubArrA,
                  });
                }
              }
            } else {
              item.tagArr = this.getMustTier(tagsByType[labelType]);
            }
          }
        });
      }
    },
    // 国家权威/省内菁英/院内栋梁只显示最高层级
    getMustTier(tags) {
      let returnArr = [];
      let tierLabels = [];
      // 将国家权威/省内菁英/院内栋梁提取出来
      tags.map((item) => {
        if (
          item.label === "国家权威" ||
          item.label === "省内菁英" ||
          item.label === "院内栋梁"
        ) {
          tierLabels.push(item);
        } else {
          returnArr.push(item);
        }
      });
      if (tierLabels && tierLabels.length > 0) {
        if (tierLabels.find((e) => e.label === "国家权威")) {
          returnArr.unshift(tierLabels.find((e) => e.label === "国家权威"));
        } else if (tierLabels.find((e) => e.label === "省内菁英")) {
          returnArr.unshift(tierLabels.find((e) => e.label === "省内菁英"));
        } else if (tierLabels.find((e) => e.label === "院内栋梁")) {
          returnArr.unshift(tierLabels.find((e) => e.label === "院内栋梁"));
        }
      }
      return returnArr;
    },
    onClick(item) {
      this.$emit("tagClick", item);
      // if (item.clickable) {
      // }
    },
    // 画像标签问卷
    openPortraitDialog() {
      let params = {
        physician_id: this.$route.query.physician_id,
        territory_id: getLocalStorage("territory_id"),
        // product_id: this.product,
      };
      py.getOnlineDiagnosis(params).then((res) => {
        if (res && res.value.length) {
          this.portraitAnswers = JSON.parse(res.value[0].content);
        } else {
          this.portraitAnswers = {
            firstAnswer: [],
            otherAnswer: "",
          };
        }
        this.portraitLabelAnswerTips = false;
        this.portraitLabelQuestion = true;
      });
    },
    openBusinessDialog() {
      let params = {
        physician_id: this.$route.query.physician_id,
        product_id: this.product,
        territory_id: getLocalStorage("territory_id"),
      };
      // 业务标签问卷
      py.getPhysicianTyping(params).then((res) => {
        if (res && res.value.length) {
          this.businessAnswer = JSON.parse(res.value[0].content);
        } else {
          this.businessAnswer = {
            firstAnswer: 0,
            secondAnswer: 0,
            thirdAnswer: null,
            fourthAnswer: null,
            fifthAnswer: null,
          };
        }
        this.businessLabelQuestion = true;
      });
    },
    closeDiglog() {
      this.portraitLabelQuestion = false;
      this.businessLabelQuestion = false;
    },
    beforePortraitClose(action, done) {
      if (action === "confirm") {
        if (this.portraitAnswers.firstAnswer.length > 0) {
          let result = this.getPortraitResult();
          let params = {
            physician_id: this.$route.query.physician_id,
            content: JSON.stringify(this.portraitAnswers),
            user_id: getLocalStorage("user_id"),
            territory_id: getLocalStorage("territory_id"),
            create_time: Date.parse(new Date()),
            // product_id: this.product,
            result: result.length > 0 ? result.join(",") : "",
          };
          py.setOnlineDiagnosis(params).then((res) => {
            if (res.is_success) {
              setTimeout(() => {
                done();
                this.$emit("refresh");
                this.$toast.success("提交成功");
              }, 1000);
            } else {
              this.$toast.fail("提交失败，请重试");
              done(false);
            }
          });
        } else {
          this.portraitLabelAnswerTips = true;
          done(false);
        }
      } else {
        done();
      }
    },
    getPortraitResult() {
      let result = [];
      this.portraitAnswers.firstAnswer.map((e) => {
        if (e && e !== "不了解" && e !== "其他") {
          result.push(e);
        }
      });
      if (
        this.portraitAnswers.firstAnswer.findIndex((e) => e === "其他") > -1
      ) {
        if (this.portraitAnswers.otherAnswer) {
          result.push(this.portraitAnswers.otherAnswer);
        }
      } else {
        this.portraitAnswers.otherAnswer = "";
      }
      return result;
    },
    beforeBusinessClose(action, done) {
      if (action === "confirm") {
        let params = {
          physician_id: this.$route.query.physician_id,
          content: JSON.stringify(this.businessAnswer),
          user_id: getLocalStorage("user_id"),
          territory_id: getLocalStorage("territory_id"),
          create_time: Date.parse(new Date()),
          product_id: this.product,
          result: "",
        };
        py.setPhysicianTyping(params).then((res) => {
          if (res.is_success) {
            setTimeout(() => {
              done();
              this.$emit("refresh");
              this.$toast.success("提交成功");
            }, 1000);
          } else {
            this.$toast.fail("提交失败，请重试");
            done(false);
          }
        });
      } else {
        done();
      }
    },
    getDisableStatus(answer) {
      return (
        this.portraitAnswers.firstAnswer.findIndex((e) => e === answer) > -1
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/var.scss";
.custom-tag {
  display: flex;
  flex-wrap: wrap;
  width: 84vw;
  .custom-tag-item {
    background-color: rgba(0, 98, 185, 0.08);
    padding: 5px 10px;
    font-size: 14px;
    font-family: Arial;
    font-weight: 400;
    color: #2c3034;
    margin: 5px 5px;
    -webkit-mask-image: radial-gradient(
      circle at 4px 50%,
      transparent 4px,
      red 4.5px
    );
    -webkit-mask-position: -4px;

    &.active {
      border: 1px solid #0061bf;

      .tag-icon {
        color: #0061bf;
      }
    }
  }
  .sub-label-type {
    width: 100%;
    display: flex;
    font-size: 14px;
    font-family: Arial;
    font-weight: bold;
    color: #2c3034;
    margin-bottom: 10px;
  }
}
.tag-icon {
  margin-left: 2px;
  color: #aaa;
}
.tag-line-label {
  display: flex;
  font-size: 13px;
  // width: 80px;
  text-align: left;
  margin: 20px 0;
  white-space: nowrap;
  justify-content: space-between;
  .tag-lable-type {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: Arial;
    font-weight: bold;
    color: #2c3034;
  }
  .tag-label-icon {
    display: inline-block;
    width: 3px;
    height: 10px;
    background: #1b75bc;
    border-radius: 10px;
    margin-right: 5px;
  }
  .tag-label-question {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: Arial;
    font-weight: bold;
    color: #1b75bc;
    img {
      margin-right: 5px;
    }
  }
  .diglog-close {
    position: absolute;
    font-size: 30px;
    top: 25px;
    right: 20px;
  }
}
.diglog-title {
  display: flex;
  padding: 0 10px 10px;
  .diglog-title-text {
    font-size: 16px;
    font-family: Arial;
    font-weight: bold;
    color: #2c3034;
    width: 90%;
  }
  .diglog-title-close {
    font-size: 30px;
  }
}
.diglog-questions {
  max-height: 400px;
  overflow-y: auto;
  padding: 20px 20px 0 20px;
  .question {
    margin-bottom: 20px;
    .question-text {
      font-size: 14px;
      font-family: Arial;
      font-weight: 700;
      line-height: 1.4;
      color: #2c3034;
      text-align: left;
      margin-bottom: 10px;
    }
    .answer {
      display: flex;
    }
    .answer-checkbox {
      margin: 5px;
    }
    .percent-answer {
      font-size: 24px;
      font-family: Arial;
      font-weight: 700;
      color: #1b75bc;
      margin: 20px 0 30px 0;
      text-align: left;
    }
  }
}
</style>
