<template>
  <div class="seg-type-desc box">
    <!-- <p class="tit">分型描述:</p> -->
    <div class="seg-type-list">
      <p v-for="(td, index) in descList" :key="index">
        <span class="td-title">{{ td.title }}：</span>
        <span class="td-value">{{ td.value }}</span>
      </p>
    </div>
    <!-- <div class="table-list">
      <table>
        <tr v-for="(td, index) in tableList" :key="index">
          <td v-if="td.title" style="width: 30%;">{{ td.title }}</td>
          <td style="width: 70%;">{{ td.value }}</td>
        </tr>
      </table>
    </div> -->
    <!-- <Table :table-list="tableData" /> -->
  </div>
</template>
<script>
export default {
  props: {
    descList: {
      type: Array,
      default() {
        return [];
      }
    }
  }
};
</script>
<style lang="scss" scoped>
</style>
