<template>
  <div class="c-serach-box">
    <div class="c-serach-item">
      <div class="c-s-label">类型筛选</div>
      <div
        v-for="(se, index) in searchList"
        :key="index"
        class="c-s-item"
        :class="{ active: se.active }"
        @click="selectFn(index)"
      >
        <div class="c-s-flex">{{ se.title }}</div>
      </div>
      <!-- <div class="c-s-item">
        <div class="c-s-flex">论文</div>
      </div> -->
    </div>
    <div v-if="activeChildren && activeChildren.length" class="c-serach-item">
      <div class="c-s-label">更多筛选</div>
      <div
        v-for="(se, index) in activeChildren"
        :key="se.field + index"
        class="c-s-item"
        :class="{ active: se.active }"
        @click="selectChildrenFn(index)"
      >
        <div class="c-s-flex">{{ se.title }}</div>
      </div>
      <!-- <div class="c-s-item">
        <div class="c-s-flex">论文</div>
      </div> -->
    </div>
    <div class="c-serach-item">
      <div class="c-s-label">时间筛选</div>
      <div
        v-for="(se, index) in timeArr"
        :key="index"
        class="c-s-item"
        :class="{ active: se.active }"
        @click="selectTimeFn(index)"
      >
        <div class="c-s-flex">{{ se.title }}</div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      searchTag: "",
      searchList: [
        {
          title: "论文发表",
          value: "type1",
          active: false,
          field: "type",
          children: [
            {
              title: "第一作者",
              value: [1, 3],
              active: false,
              field: "author_seq_no",
            },
            {
              title: "其他",
              value: 0,
              active: false,
              field: "author_seq_no",
            },
          ],
        },
        {
          title: "临床试验",
          value: "type2",
          active: false,
          field: "type",
          children: [
            {
              title: "PI",
              value: 1,
              active: false,
              field: "is_pi",
            },
            {
              title: "非PI",
              value: 0,
              active: false,
              field: "is_pi",
            },
          ],
        },
        {
          title: "国自然项目",
          value: "type3",
          active: false,
          field: "type",
          children: [],
        },
        {
          title: "获奖",
          value: "type4",
          active: false,
          field: "type",
          children: [],
        },
        {
          title: "指南共识",
          value: "type5",
          active: false,
          field: "type",
          children: [],
        },
        {
          title: "专利发明",
          value: "type6",
          active: false,
          field: "type",
          children: [],
        },
        // { title: '参会', value: 'type5', active: false, field: 'type', children: [] }
      ],
      timeArr: [
        {
          title: "全部",
          value: "",
          active: true,
          field: "date",
        },
        {
          title: "近5年",
          value: "",
          active: false,
          field: "date",
        },
        {
          title: "5-10年",
          value: "",
          active: false,
          field: "date",
        },
        {
          title: "10年以上",
          value: "",
          active: false,
          field: "date",
        },
      ],
      activeIndex: 0,
      selectTime: "",
      activeChildren: [],
    };
  },
  computed: {
    learningType() {
      return this.$store.state.user.learningType;
    },
  },
  watch: {
    learningType(nVal, oVal) {
      this.selectFn(nVal);
    },
    searchTag(nVal, oVal) {
      console.info(nVal, oVal, "论文标签搜索");
    },
  },
  mounted() {
    let nextFive = new Date().getFullYear() - 5;
    let nextTen = new Date().getFullYear() - 10;
    this.timeArr[1].value = nextFive + "-";
    this.timeArr[2].value = nextTen + "-" + nextFive;
    this.timeArr[3].value = "-" + nextTen;
    this.selectFn();
  },
  methods: {
    confirmFn(obj) {
      this.$emit(
        "selectedFn",
        this.searchList[this.activeIndex],
        this.selectTime,
        obj
      );
    },
    selectFn(index) {
      if (index || index === 0) {
        this.searchList.forEach((ele, $index) => {
          ele.active = false;
          if (index === $index) {
            this.activeIndex = $index;
            ele.active = true;
            this.activeChildren = ele.children;
          }
        });
        this.$emit(
          "selectedFn",
          this.searchList[this.activeIndex],
          this.selectTime
        );
      }
    },
    selectChildrenFn(index) {
      this.activeChildren.forEach((ele, $index) => {
        // ele.active = false;
        if (index === $index) {
          ele.active = !ele.active;
        }
      });
      this.$emit(
        "selectedFn",
        this.searchList[this.activeIndex],
        this.selectTime
      );
    },
    selectTimeFn(index) {
      this.selectTime = "";
      this.timeArr.forEach((ele, $index) => {
        ele.active = false;
        if (index === $index) {
          this.selectTime = ele.value;
          ele.active = !ele.active;
        }
      });
      this.$emit(
        "selectedFn",
        this.searchList[this.activeIndex],
        this.selectTime
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.c-serach-item {
  flex-wrap: wrap;
}
.c-s-item,
.c-s-label {
  margin-bottom: 10px;
}
</style>
