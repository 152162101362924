export default {
  data() {
    return {
      activityDetailObj: {},
      activityDetailStatus: false,
    };
  },
  methods: {
    lookActivityDetail(item) {
      // type1 论文发表 type2 临床试验
      console.log('item--->', item);
      if (
        item.network_type === '论文合作' ||
        item.network_type === '临床试验'
      ) {
        this.activityDetailObj = item;
        this.$store.dispatch('setDialogStatus', true);
        this.activityDetailStatus = true;
      }
    },
    closeDetailFn(status) {
      this.$store.dispatch('setDialogStatus', false);
      this.activityDetailStatus = status;
    },
  },
};
