<template>
  <div class="swipe-wrapper">
    <header class="header">
      <p class="h-title">
        {{
          detailObj.network_type
        }}详情
      </p>
      <i class="cubeic-back" @click.stop="back">{{ backtxt }}</i>
    </header>
    <div class="scroll-container bac-white">
      <div class="publication">
        <div class="table-list">
          <table v-if="detailObj.network_type === '论文合作'">
            <tr>
              <td>{{ $t('thesis_title') }}</td>
              <td v-html="item.publication_title"></td>
            </tr>
            <tr>
              <td>{{ $t('key_word') }}</td>
              <td>{{ item.keywordsTxt }}</td>
            </tr>
            <tr>
              <td>{{ $t('publication_time') }}</td>
              <td>{{ item.publish_date | formatterDate }}</td>
            </tr>
            <tr>
              <td>{{ $t('journal') }}</td>
              <td v-html="item.journal"></td>
            </tr>
            <tr>
              <td>{{ $t('journal_if') }}</td>
              <td v-html="item.journal_if"></td>
            </tr>
            <tr>
              <td>{{ $t('doi') }}</td>
              <td v-html="item.doi"></td>
            </tr>
            <tr>
              <td>{{ $t('authors') }}</td>
              <td v-html="item.authors"></td>
            </tr>
            <tr v-if="item.abstract">
              <td>{{ $t('abstract') }}</td>
              <td v-html="item.abstract"></td>
            </tr>
            <tr v-if="item.source">
              <td>{{ $t('source') }}</td>
              <td>
                <link-viewer :url="item.source">
                  {{ item.source }}
                </link-viewer>
              </td>
            </tr>
          </table>
          <table v-if="detailObj.network_type === '临床试验'">
            <tr>
              <td>{{ $t('professional_subject') }}</td>
              <td v-html="item.professional_subject"></td>
            </tr>
            <tr>
              <td>{{ $t('applier_contact') }}</td>
              <td v-html="item.applier_contact"></td>
            </tr>
            <tr>
              <td>{{ $t('applier_name') }}</td>
              <td v-html="item.applier_name"></td>
            </tr>
            <tr>
              <td>{{ $t('applier_status') }}</td>
              <td v-html="item.status"></td>
            </tr>
            <tr>
              <td>{{ $t('registration_date') }}</td>
              <td>{{ item.registration_date | formatterDate }}</td>
            </tr>
            <tr>
              <td>{{ $t('drug_name') }}</td>
              <td v-html="item.drug_name"></td>
            </tr>
            <tr>
              <td>{{ $t('drug_type') }}</td>
              <td v-html="item.drug_type"></td>
            </tr>
            <tr>
              <td>{{ $t('indication') }}</td>
              <td v-html="item.indication"></td>
            </tr>
            <tr>
              <td>{{ $t('study_objectives') }}</td>
              <td v-html="item.study_objectives"></td>
            </tr>
            <tr>
              <td>{{ $t('category') }}</td>
              <td v-html="item.category"></td>
            </tr>
            <tr>
              <td>{{ $t('period') }}</td>
              <td v-html="item.period"></td>
            </tr>
            <tr>
              <td>{{ $t('scope') }}</td>
              <td v-html="item.scope"></td>
            </tr>
            <tr>
              <td>{{ $t('organization') }}</td>
              <td v-html="item.organization">{{ item.organization }}</td>
            </tr>
          </table>
        </div>
        <div style="height: 40px"></div>
      </div>
      <!-- <cube-slide ref="slide" :data="activityDetailsArr" :loop="false" :show-dots="false" :auto-play="false">
          <cube-slide-item v-for="(item, index) in activityDetailsArr" :key="index" @click.native="clickDetailHandler(item, index)">
          </cube-slide-item>
        </cube-slide> -->
    </div>
  </div>
</template>
<script>
import * as py from '@/api/Physicians';
import LinkViewer from '@/components/link-viewer.vue';

export default {
  components: {
    LinkViewer,
  },
  props: {
    detailObj: {
      type: Object,
      default() {
        return {};
      },
    },
    backtxt: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      activityDetailsArr: [1, 2, 3, 4],
      // 、关键词、、发表期刊、期刊IF、DOI、作者、摘要
      tableList: [],
      item: {},
    };
  },
  created() {
    this.getDetail();
  },
  mounted() {},
  methods: {
    splitString(keywords) {
      let spArr = [];
      let result = [];
      if (keywords) {
        spArr = keywords.split('；');
        if (spArr.length === 1 && keywords.indexOf('，') > -1) {
          spArr = keywords.split('，');
        }
        if (spArr.length === 1 && keywords.indexOf(',') > -1) {
          spArr = keywords.split(',');
        }
        spArr.forEach((ele) => {
          if (ele && ele !== '' && ele !== ' ') {
            result.push(ele);
          }
        });
      }
      return result;
    },
    getDetail() {
      console.log('this.detailObj.network_type', this.detailObj.network_type);
      if (this.detailObj.network_type === '论文合作') {
        py.getKolPublicationsDetail(
          this.detailObj.network_content_id,
          this.$route.query.physician_id,
        ).then((res) => {
          let keywordsArr = this.splitString(res.value[0].keywords) || [];
          res.value[0].keywordsTxt = keywordsArr.join();
          this.item = res.value[0];
        });
      } else {
        py.getKolPhysicianClinicalTrialsDetail(
          this.detailObj.network_content_id,
          this.$route.query.physician_id,
        ).then((res) => {
          this.item = res.value[0];
        });
      }
    },
    clickDetailHandler(item, index) {
      console.info(item, index);
    },
    back() {
      this.$emit('closeDetailFn', false);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/swipeDialog.scss';
.publication {
  padding: 10px;
  .table-list {
    table tr td {
      &:nth-child(1) {
        width: 25%;
      }
    }
  }
}
</style>
