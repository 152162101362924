<template>
  <div class="view-wrapper">
    <template>
      <div class="search-bar-list pd-btm0">
        <div
          style="background-color: #f5f7f8"
          class=" w-full"
          :class="{'no-list': list.length == 0}"
        >
          <van-collapse v-model="active">
            <van-collapse-item
              v-for="(item, index) in list"
              :key="index"
              :name="index"
            >
              <template #title>
                <div style="text-align: left">{{ item.department_name }}</div>
              </template>
              <template #value>
                <div class="summary">医生<span class="number">{{ item.physician_no }}</span></div>
              </template>
              <template>
                <PhysicianListSection
                  :institution-id="item.institution_id"
                  :department-name="item.department_name"
                />
              </template>
            </van-collapse-item>
          </van-collapse>
        </div>
      </div>
    </template>
    <popup
      v-if="showDescStatus"
      :title="searchDesc"
      @closeFn="showDescStatus = false"
    >
      <div class="box" style="max-height: 400px; overflow-y: auto">
        <div class="seg-type-desc box">
          <div class="seg-type-list">
            <p>
              <span class="td-value">可输入科室名称进行搜索</span>
            </p>
          </div>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import * as py from '@/api/Physicians';
import popup from '@/views/layout/popup';
import {List, Search, Button} from 'vant';
import {
  getFirstUpperChar,
  getLocalStorage,
  setLocalStorage,
  debounce,
} from '@/utils/index';
import _ from 'underscore';
import followBscMIxin from '@/mixins/followBscMIxin';
import Nodata from '@/components/Nodata';
import CardItemDepartment from '@/views/hcp/components/CardItemDepartment';
import swipeBack from '@/views/layout/swipeBack';
import {Collapse, CollapseItem} from 'vant';
import Vue from 'vue';
import PhysicianListSection from '@/views/hcp/PhysicianListSection.vue';

Vue.use(Collapse).use(CollapseItem);
export default {
  components: {
    PhysicianListSection,
    // CardItemDepartment,
    popup,
    // Nodata,
    // vanList: List,
  },
  mixins: [followBscMIxin],
  props: {
    institutionId: String,
  },
  data() {
    return {
      active: [],
      sortOrder: 'physician_name',
      activeTxt: 'other',
      isHcpHeader: 0,
      keyword: '',
      loading: false,
      singerData: [], // 结构数据
      initList: [], // 初始数据
      list: [],
      listLoading: false,
      finished: false,
      pageNum: 1,
      searchKey: '',
      isSearch: false,
      loadingTxt: this.$t('loading_txt'),
      physician_count: 0,
      selectedProduct: '',
      selectedParting: '',
      searchDisable: false,
      resetDisable: false,
      summaryData: [],
      activeNames: '',
      isSales: null,
      loadUrl: null,
      showDescStatus: false,
      isMr: false,
      searchDesc: '搜索说明', // 排序说明
      title: '',
    };
  },
  created() {
    this.title = this.$route.query.institution_name;
    if (
      getLocalStorage('territory_type') &&
      getLocalStorage('territory_type') === 'MR'
    ) {
      this.isMr = true;
    }
    this.initPage();
  },
  mounted() {},
  destroyed() {},
  methods: {
    backFn() {
      this.$router.push({
        path: '/kol/home',
        query: {
          user_id: getLocalStorage('user_id_key'),
          tab_state: this.$route.query.tab_state,
        },
      });
    },
    showDescFn(status) {
      this.searchDesc = status === 1 ? '搜索说明' : '排序说明';
      this.showDescStatus = true;
    },
    closeWXWindow() {
      window.history.pushState(null, null, document.URL);
    },
    initPage(userId = 'admin') {
      let is_sales = getLocalStorage('is_sales');
      this.isSales = is_sales === '1';
      this.list = [];
      this.pageFn();
    },
    sortNameFn(arr) {
      const listDataMap = _.groupBy(arr, (ele) => {
        ele.name = getFirstUpperChar(ele.physician_name);
        return ele.name;
      });
      let mapArr = [];
      for (let key in listDataMap) {
        mapArr.push({
          name: key,
          items: listDataMap[key],
        });
      }
      const sortArr = _.sortBy(mapArr, 'name');
      let list = [];
      sortArr.forEach((ele) => {
        list = list.concat(ele.items);
      });
      return list;
    },
    pageFn() {
      let data = {
        user_id: getLocalStorage('user_id'),
        pageNum: this.pageNum,
        pageSize: 20,
        physician_name: this.searchKey,
        product_id: this.selectedProduct,
        result: this.selectedParting,
        institution_id: this.institutionId || this.$route.query.institution_id,
        isSales: this.isSales
      };
      if (
        data.pageNum - 1 === 0 ||
        (data.pageNum - 1) * data.pageSize < this.physician_count
      ) {
        this.loading = true;
        py.getVUserAlignmentDepartmentKols(data)
          .then((res) => {
            this.loadList(res);
          })
          .catch((e) => {
            this.claerData();
          });
      } else {
        this.finished = true;
        this.listLoading = false;
      }
    },
    loadList(res) {
      if (res && res.value && res.value.length > 0) {
        if (res && res['@odata.count']) {
          this.physician_count = res['@odata.count'];
        }
        let resArr = res.value;
        if (res && resArr.length > 0) {
          resArr.forEach((ele) => {
            this.list.push(ele);
          });
        }
        this.initList = JSON.parse(JSON.stringify(this.list));
      } else {
        this.list = [];
        // this.loading = false;
        if (!this.isSearch) {
          // 非搜索
          this.finished = true;
        }
        this.isSearch = false;
      }
      this.loading = false;
      this.listLoading = false;
      this.searchDisable = false;
      this.resetDisable = false;
    },
    claerData() {
      this.listLoading = false;
      this.loading = false;
      this.isSearch = false;
      this.searchDisable = false;
      this.resetDisable = false;
      this.list = [];
    },

    onLoad() {
      if (!this.loading) {
        this.pageNum++;
        this.listLoading = true;
        this.pageFn();
      }
    },
    refreshFn() {
      this.list = [];
      this.pageFn();
    },
    selectItem(item) {
      this.$router.push({
        path: '/kol/list',
        query: {
          user_id: getLocalStorage('user_id_key'),
          institution_id: item.institution_id,
          tab_state: this.$route.query.tab_state,
          routeTxt: this.$route.query.routeTxt,
          department_name: item.department_name,
          institution_name: this.$route.query.institution_name,
        },
      });
    },
    searchDoctor() {
      this.list = [];
      this.pageNum = 1;
      this.finished = false;
      this.listLoading = false;
      this.isSearch = true;
      this.searchKey = this.keyword;
      this.resetDisable = true;
      this.pageFn();
    },
    reset() {
      this.sortOrder = 'physician_name';
      this.list = [];
      this.pageNum = 1;
      this.finished = false;
      this.keyword = '';
      this.searchKey = '';
      this.selectedProduct = '';
      this.selectedParting = '';
      this.searchDisable = true;
      this.pageFn();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/follow-list.scss';
.view-wrapper ::v-deep .cube-index-list-fixed,
.view-wrapper ::v-deep .cube-index-list-anchor {
  text-align: left;
}
.search-bar-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12px;
  // padding-bottom: 10px;
  background: #fff;
  &.pd-btm0 {
    padding-bottom: 0;
  }
  .search-button {
    font-size: 14px;
    color: #333333;
    width: 50px;
    text-align: center;
    color: #2961a7;
    // position: absolute;
    // right: 20px;
    // top: 10px;
    i {
      font-size: 18px;
      color: #ccc;
    }
  }
}
.search-filter {
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  align-items: center;
  padding: 0 10px;
  &.j-center {
    justify-content: flex-start;
    padding: 0.166667rem 0 0.166667rem 15px;
  }
  .search-filter-drop {
    flex: 1;
    max-width: 50%;
  }
  .search-filter-reset {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    font-size: 15px;
    font-family: Arial;
    font-weight: 400;
    color: #1b75bc;
  }
  .search-filter-button {
    width: 1.8rem;
    height: 0.8rem;
    & + .search-filter-button {
      margin-left: 5px;
    }
  }
}
.search-filter1 {
  white-space: nowrap;
}
::v-deep .van-dropdown-menu__bar {
  box-shadow: none;
}
.hcp-list-explain {
  font-size: 11px;
  background-color: #e0e6ed;
  padding: 15px;
  text-align: center;
}
.view-wrapper {
  width: 100%;
  height: 100%;
  // overflow-y: auto;
  // position: relative;
  .add-fav-explain {
    font-size: 14px;
    background-color: #e1f3da;
    padding: 15px 0;
    text-align: center;
  }
  .bac-be {
    height: 30px;
    background-color: #ebebee;
  }
}
.bac-be {
  height: 30px;
  background-color: #ebebee;
}
.show-more {
  font-size: 12px;
  text-align: center;
  color: #2961a7;
  margin-top: 40px;
  padding-bottom: 60px;
}
.radius-btn {
  border-radius: 20px;

  padding-left: 25px;
  padding-right: 25px;
}
.dimension-icon {
  margin-right: 4px;
}
.custom ::v-deep .name {
  display: flex;
  .name-w {
    min-width: 60px;
  }
}
.summary {
  font-size: 12px;
  .number {
    font-size: 14px;
    color: #0061d0;
    margin-left: 4px;
    margin-right: 6px;
  }
}
.no-list {
  ::v-deep .van-list__finished-text,
  ::v-deep .van-list__placeholder {
    display: none;
  }
}
.online-tab-box {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  .online-tab {
    width: 43%;
    padding: 10px;
    text-align: center;
    border-radius: 4px;
    background-color: #f0f0f0;
    &.active {
      color: #ffffff;
      background-color: #0061bf;
    }
  }
}
.hospital-box {
  .mg-to {
    margin-top: -5px;
  }
  img {
    max-width: 100%;
  }
}
.summary-container {
  display: flex;
  align-items: center;
  justify-content: space-around;

  .summary-item {
    flex: 1;
    padding: 15px 0 0;
    .label {
      font-size: 10px;
      color: #666;
      display: block;
    }
    .value {
      padding: 10px 0 5px;
      display: block;
      font-size: 28px;

      color: #3caa45;
      .v-bd {
        // border-bottom: 1px solid #3caa45;
        font-weight: initial;
      }
    }
  }
}
.van-cps ::v-deep .van-cell__title {
  font-size: 12px !important;
}
.van-cps ::v-deep .van-cell {
  padding-top: 0.166667rem;
  padding-bottom: 0.166667rem;
}
.hospital-count-div {
  display: flex;
  font-size: 12px;
  .f-blod {
    // color: #1989fa;
    font-weight: bold;
  }
}
.f-quest {
  display: flex;
  &.f-quest-mg {
    margin: 0 5px;
  }
  .question-icon {
    color: #1362ad;
    margin-left: 3px;
    // margin-right: 5px;
  }
}
</style>
