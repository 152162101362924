export default {
  data() {
    return {
      activityDetailObj: {},
      activityDetailStatus: false,
    };
  },
  methods: {
    lookActivityDetail(item) {
      // type1 论文发表 type2 临床试验
      let typeArr = ['type1', 'type2'];
      if (typeArr.includes(item.type)) {
        this.activityDetailObj = item;
        this.$store.dispatch('setDialogStatus', true);
        this.activityDetailStatus = true;
      }
    },
    closeDetailFn(status) {
      this.$store.dispatch('setDialogStatus', false);
      this.activityDetailStatus = status;
    },
  },
};
