<template>
  <div class="slider-box">
    <div class="slider-title">{{ $t('show_relaxion_count') }}</div>
    <Slider
      v-model="value"
      :step="10"
      :min="10"
      :max="50"
      @change="sliderChange"
    >
      <template #button>
        <div class="custom-button">{{ sliderNumber }}</div>
      </template>
    </Slider>
  </div>
</template>
<script>
import { Slider } from 'vant';
export default {
  components: {
    Slider
  },
  props: {
    sliderNumber: {
      type: Number,
      default() {
        return 10;
      }
    }
  },
  data() {
    return {
      value: this.sliderNumber
    };
  },
  methods: {
    sliderChange(value) {
      this.$emit('sliderFn', value);
    }
  }
};
</script>
<style lang="scss" scoped>
.slider-box {
  display: flex;
  padding: 20px;
  .slider-title {
    width: 160px;
    font-size: 12px;
    text-align: left;
    margin-top: -4px;
    // margin-bottom: 20px;
  }
  .custom-button {
    font-size: 12px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    color: #999;
    background-color: #fff;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 1px 2px rgb(0 0 0 / 50%);
  }
}
</style>
