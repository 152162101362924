<template>
  <div class="home-content">
    <div class="home-tab">
      <div v-for="(tb, index) in tabLabels" :key="index" class="home-tab-item" :class="{'active': activeTab == index}" @click="changeFn(index)">{{ tb.label }}</div>
      <!-- <div class="home-tab-item">医院列表</div> -->
    </div>
    <div class="home-container">
      <!-- 医生列表 -->
      <list v-show="(activeTab == 0)" />
      <!-- 医院列表 -->
      <hospitaLlist v-if="(activeTab == 1)" />
    </div>
  </div>
</template>
<script>
import hospitaLlist from "./hospitaLlist.vue";
import list from "./list.vue";

export default {
  components: {
    hospitaLlist,
    list
  },
  data() {
    return {
      active: '医生列表',
      tabLabels: [
        { label: '医生列表', active: 0 },
        { label: '医院列表', active: 1 }
      ],
      activeTab: 0
    };
  },
  created() {
    if (this.$route.query.tab_state === 'hospital') { // 设置返回时 tab
      this.activeTab = 1;
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (window.history && window.history.pushState) {
        window.history.pushState(null, null, document.URL);
        window.addEventListener('popstate', this.closeWXWindow, false);
      }
    });
  },
  destroyed() {
    window.removeEventListener('popstate', this.closeWXWindow, false);
  },
  methods: {
    changeFn(index) {
      if (index === 0) {
        this.$router.push({
          query: { user_id: this.$route.query.user_id, routeTxt: 'home' }
        });
      }
      this.activeTab = index;
    }
  }
};
</script>
<style scoped lang="scss">
.home-content {
  width: 100%;
  height: 100%;
  .home-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    overflow: hidden;
    .home-tab-item {
      width: 50%;
      font-size: 12px;
      padding: 10px 0;
      color: #999;
      background-color: #eee;
      // border: 1px solid rgb(0, 98, 185);
      // &:nth-child(1) {
      //   border-top-left-radius: 4px;
      //   border-bottom-left-radius: 4px;
      // }
      // &:nth-child(2) {
      //   border-top-right-radius: 4px;
      //   border-bottom-right-radius: 4px;
      // }
      &.active {
        color: #1989fa;
        font-weight: 700;
        font-size: 14px;
        background-color: #ffffff;
      }
    }
  }
  .home-container {
    width: 100%;
    height: calc(100% - 44px);
  }
}
</style>
