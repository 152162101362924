<template>
  <div class="box">
    <!-- <div class="select-flex clinial-select">
      <label class="txt">{{ $t('is_mm') }}</label>
      <cube-select
        v-model="isMm"
        :title="$t('please_placeholder')"
        :cancel-txt="$t('cancel_txt')"
        :confirm-txt="$t('confirm_txt')"
        :options="mmArr"
        @change="change"
      />
    </div> -->
    <Nodata v-if="!pageShowArr.length" :loading="loading" :empty-txt="$t('no_data')" />
    <ul v-else class="activity-list">
      <li v-for="(item,index) in pageShowArr" :key="index" @click="lookActivityDetail(item)">
        <template v-if="item.type == 'type2'">
          <span class="type type2">{{ $t('professional') }}</span>
          {{ item.professional_subject }}<br />
          <div class="profess-status flex-detail">
            <span>{{ item.physician_name }}</span>
            <span>{{ item.status }}</span>
            <span>{{ item.period }}</span>
          </div>
          <!-- <div class="profess-status">注册时间: {{ item.registration_date | formatterDate }}</div> -->
          <div class="info-details flex-detail">
            <span>{{ item.applier_name }}</span>
            <span class="time">{{ item.registration_date | formatterDate }}</span>
          </div>
        </template>
      </li>
    </ul>
    <div v-if="netWorkObj.pageNumber < netWorkObj.netWorkPageTotalArr.length - 1" class="show-more">
      <div class="show-more-center" @click="netWorkpagingFn">
        {{ $t('show_more_txt') }}
        <span class="score-more">
          <i class="cubeic-select"></i>
        </span>
      </div>
    </div>
    <transition name="page-move">
      <cardActivityDetail v-if="activityDetailStatus" backtxt="返回" :detail-obj="activityDetailObj" @closeDetailFn="closeDetailFn" />
    </transition>
  </div>
</template>
<script>

import * as py from '@/api/Physicians';
import { settPageData } from '@/utils/index';
import Nodata from '@/components/Nodata';
import cardActivityDetail from '@/components/cardActivityDetail';
import Publications from '@/mixins/Publications';

export default {
  components: {
    Nodata,
    cardActivityDetail
  },
  mixins: [Publications],
  props: {
    institutionName: {
      type: String,
      default() {
        return '';
      }
    },
    departmentName: {
      type: String,
      default() {
        return '';
      }
    }
  },
  data() {
    return {
      loading: false,
      filterclinicalTrialsList: [],
      clinicalTrialsList: [],
      netWorkObj: {
        netWorkPageTotalArr: [],
        pageNumber: 0
      },
      pageShowArr: [],
      isMm: '',
      mmArr: [
        { value: '1', text: '是' },
        { value: '0', text: '否' }
      ]
    };
  },
  mounted() {
    this.getCommonClinicalTrials(this.institutionName, this.departmentName);
  },
  methods: {
    back() {
      this.$emit('officeStatusFn', false);
    },
    getCommonClinicalTrials(institution_name, department_name) { // 查询同科室临床试验
      this.filterclinicalTrialsList = [];
      this.clinicalTrialsList = [];
      this.loading = true;
      if (institution_name && department_name) {
        py.getCommonPhysicianClinicalTrialsByPhysicianId(this.$route.query.physician_id, institution_name, department_name).then(res => {
          if (res.value.length) {
            res.value.forEach(ele => {
              ele.type = 'type2';
              this.filterclinicalTrialsList.push(ele);
              this.clinicalTrialsList.push(ele);
            });
            this.pageFn(this.clinicalTrialsList);
          }
          this.loading = false;
        }).catch(e => {
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    },
    pageFn(clinicalTrialsList) {
      if (clinicalTrialsList.length) {
        this.netWorkObj.netWorkPageTotalArr = settPageData(clinicalTrialsList, 50);
        this.pageShowArr = this.netWorkObj.netWorkPageTotalArr[this.netWorkObj.pageNumber];
      } else {
        this.netWorkObj.netWorkPageTotalArr = [];
        this.pageShowArr = [];
      }
    },
    netWorkpagingFn() {
      ++this.netWorkObj.pageNumber;
      if (this.netWorkObj.pageNumber < this.netWorkObj.netWorkPageTotalArr.length) {
        this.netWorkObj.netWorkPageTotalArr[this.netWorkObj.pageNumber].forEach(ele => {
          this.pageShowArr.push(ele);
        });
      }
    },
    // change(val) {
    //   let arr = this.clinicalTrialsList.filter(ele => ele.is_mm === parseInt(this.isMm));
    //   this.netWorkObj.pageNumber = 0;
    //   this.pageFn(arr);
    // }
  }
};
</script>
<style lang="scss" scoped>
@import '@/styles/var.scss';
@include show-more();
.flex-detail {
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
  justify-content: space-between;
  span {
    padding-right: 10px;
  }
}
.clinial-select {
  text-align: left;
}
</style>
