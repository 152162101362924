<template>
  <div class="tag-container bac-white">
    <div class="tag-title box-right">
      <CommonHeader
        :title="$t('assessment')"
        :icon="'user-tag'"
        :no-feedback="true"
      />
    </div>
    <van-tabs
      v-if="tabLabels.length > 1"
      type="card"
      color="#0062b9"
      class="product-tab"
    >
      <van-tab
        v-for="(item, index) in tabLabels"
        :key="index"
        :title="item.label"
      >
        <RadarChart
          v-if="item.radarBarChart"
          :chart-data="item.radarBarChart"
          @selectRadarDetail="selectRadarDetail"
        />
        <Nodata
          v-if="item.radarBarChart == null"
          :loading="radarLoading"
          :empty-txt="$t('no_data')"
        />
      </van-tab>
    </van-tabs>
    <div v-else-if="tabLabels.length == 1">
      <div class="product-one">{{ tabLabels[0].label }}</div>
      <RadarChart
        v-if="tabLabels[0].radarBarChart"
        :chart-data="tabLabels[0].radarBarChart"
        @selectRadarDetail="selectRadarDetail"
      />
    </div>
    <Nodata v-else :loading="radarLoading" :empty-txt="$t('no_data')" />
    <popup
      v-if="showQuestStatus"
      title="评估维度描述"
      @closeFn="showQuestDialog"
    >
      <div class="box" style="max-height: 400px; overflow-y: auto">
        <ListDesc :desc-list="redarDescActiveList" />
      </div>
    </popup>
  </div>
</template>
<script>
import { Tabs, Tab } from "vant";
import RadarChart from "@/components/RadarChart";
import Nodata from "@/components/Nodata";
import * as py from "@/api/Physicians";
import CommonHeader from "@/components/CommonHeader";
import popup from "@/views/layout/popup";
import ListDesc from "@/components/ListDesc";
import { formatterDate } from "@/filters/index";
import {
  assessmentListDesc,
  redarDescList,
  colorlist,
} from "@/utils/defaultConstant";
export default {
  name: "ProductRadar",
  components: {
    RadarChart,
    Nodata,
    vanTabs: Tabs,
    vanTab: Tab,
    CommonHeader,
    popup,
    ListDesc
  },
  props: {
    showProduct: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      redarDescList,
      redarDescActiveList: [],
      showQuestStatus: false,
      radarBarChart: null,
      radarData: {},
      selectedLabel: "",
      radarLoading: false,
      disabled: false,
      tabLabels: [],
      loop: false,
      autoPlay: false,
      showDots: false,
      slideOptions: {
        listenScroll: true,
        probeType: 3,
        /* lock y-direction when scrolling horizontally and  vertically at the same time */
        directionLockThreshold: 0,
      },
      scrollOptions: {
        /* lock x-direction when scrolling horizontally and  vertically at the same time */
        directionLockThreshold: 0,
      },
    };
  },
  computed: {
    initialIndex() {
      let index = 0;
      index = this.findIndex(
        this.tabLabels,
        (item) => item.label === this.selectedLabel
      );
      return index;
    },
  },
  mounted() {
    this.getShowProduct();
    // this.getRadarData();
  },

  methods: {
    getShowProduct() {
      this.tabLabels = [];
      this.selectedLabel =
        this.showProduct[0].product_name || this.showProduct[0].product_id;
      this.showProduct.map((item) => {
        this.tabLabels.push({
          label: item.product_name ? item.product_name : item.product_id,
          id: item.product_id,
          radarBarChart: null,
        });
      });
      this.getEffectData();
    },
    getEffectData() {
      // 影响力
      py.getEffectByPhysicianId(this.$route.query.physician_id)
        .then((res) => {
          if (res.value.length) {
            res.value.map((item) => {
              this.tabLabels.map((el) => {
                if (item.product_id === el.id) {
                  el.radarBarChart = this.settingScore(item);
                }
              });
            });
          }
        })
        .catch((e) => {
          console.info("error");
        });
    },
    // getRadarData() {
    //   this.radarData = {};
    //   this.settingScore();
    // },
    settingScore(item) {
      const scoreObj = item;
      // this.redarUpdateTime = '更新日期：' + formatterDate(scoreObj.update_date);
      let value1 = [];
      let value2 = [];
      let value3 = [];
      if (scoreObj) {
        value1 = [
          scoreObj.a_1_index || 0,
          scoreObj.a_2_index || 0,
          scoreObj.a_3_index || 0,
          scoreObj.a_4_index || 0,
          scoreObj.a_5_index || 0,
          scoreObj.a_6_index || 0,
        ];
        value2 = [
          // 平均值1  同省市
          scoreObj.a_1_score_avg_1_index || 0,
          scoreObj.a_2_score_avg_1_index || 0,
          scoreObj.a_3_score_avg_1_index || 0,
          scoreObj.a_4_score_avg_1_index || 0,
          scoreObj.a_5_score_avg_1_index || 0,
          scoreObj.a_6_score_avg_1_index || 0,
        ];
        value3 = [
          // 平均值2  同医院
          scoreObj.a_1_score_avg_3_index || 0,
          scoreObj.a_2_score_avg_3_index || 0,
          scoreObj.a_3_score_avg_3_index || 0,
          scoreObj.a_4_score_avg_3_index || 0,
          scoreObj.a_5_score_avg_3_index || 0,
          scoreObj.a_6_score_avg_3_index || 0,
        ];
      }
      console.log('value1', value1);
      let radarBarChart = {
        // 雷达图
        value1: value1,
        value2: value2,
        value3: value3,
        legendData: ["本人", "同省市", "同医院"],
        customData: {
          综合影响力: {
            score: scoreObj && scoreObj.a_1_score ? scoreObj.a_1_score : 0,
            rank: 90,
          },
          学术能力: {
            score: scoreObj && scoreObj.a_2_score ? scoreObj.a_2_score : 0,
            rank: 153,
          },
          医生认可度: {
            score: scoreObj && scoreObj.a_3_score ? scoreObj.a_3_score : 0,
            rank: 153,
          },
          学术活跃度: {
            score: scoreObj && scoreObj.a_4_score ? scoreObj.a_4_score : 0,
            rank: 266,
          },
          在线活跃度: {
            score: scoreObj && scoreObj.a_5_score ? scoreObj.a_5_score : 0,
            rank: 266,
          },
          学术互动: {
            score: scoreObj && scoreObj.a_6_score ? scoreObj.a_6_score : 0,
            rank: 153,
          },
        },
        // 手术技能：a_3_index
        // 综合影响力：a_1_index
        // 学术能力：a_2_index
        // 学术互动：a_4_index
        // 企业互动：a_5_index
        // 在线活跃度：a_6_index

        indicator: [
          // { text: "综合影响力", max: 100 },
          // { text: "在线活跃度", max: 100 },
          // { text: "医生认同度", max: 100 },
          // { text: "企业互动", max: 100 },
          // { text: "学术互动", max: 100 },
          // { text: "学术能力", max: 100 },
          { text: "综合影响力", max: 5 },
          { text: "学术能力", max: 5 },
          { text: "医生认可度", max: 5 },
          { text: "学术活跃度", max: 5 },
          { text: "在线活跃度", max: 5 },
          { text: "学术互动", max: 5 },
        ],
      };
      return radarBarChart;
      // console.info(this.radarBarChart);
    },
    showQuestDialog(state) {
      this.showQuestStatus = state;
    },
    selectRadarDetail(name) {
      this.redarDescActiveList = [];
      const obj = this.redarDescList.find((ele) => name.includes(ele.title));
      this.redarDescActiveList.push(obj);
      this.showQuestDialog(true);
    },
    scroll(pos) {
      const x = Math.abs(pos.x);
      const tabItemWidth = this.$refs.tabNav.$el.clientWidth;
      const slideScrollerWidth = this.$refs.slide.slide.scrollerWidth;
      const deltaX = (x / slideScrollerWidth) * tabItemWidth;
      this.$refs.tabNav.setSliderTransform(deltaX);
    },
    findIndex(ary, fn) {
      if (ary.findIndex) {
        return ary.findIndex(fn);
      }
      let index = -1;
      ary.some((item, i, ary) => {
        const ret = fn.call(this, item, i, ary);
        if (ret) {
          index = i;
          return ret;
        }
      });
      return index;
    },
  },
};
</script>
<style lang="scss">
.van-tabs__nav--card {
  border-radius: 1rem;
}
.van-tab:first-child {
  border-radius: 1rem 0px 0px 1rem;
}
.van-tab:last-child {
  border-radius: 0px 1rem 1rem 0px;
}
.product-one {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 28px;
  border-radius: 1rem;
  font-size: 14px;
  border: 1px solid #0062b9;
  background-color: #0062b9;
  color: #fff;
  margin: 0 auto;
}
</style>
<style lang="scss" scoped>
.tag-title {
  padding: 10px 10px 0 10px;
}

.tab-composite-view {
  .cube-tab-bar {
    background-color: white;
  }

  .cube-tab,
  .cube-tab_active {
    color: black;
  }

  .cube-tab-bar-slider {
    background-color: black;
  }

  .tab-slide-container {
    position: fixed;
    top: 74px;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .list-wrapper {
    overflow: hidden;

    li {
      padding: 15px 10px;
      margin-top: 10px;
      text-align: left;
      background-color: white;
      font-size: 14px;
      color: #999;
      white-space: normal;

      .line-height {
        line-height: 1.5;
      }

      .is-black {
        color: black;
      }

      .is-grey {
        color: #999;
      }

      .is-bold {
        font-weight: bold;
      }

      .top {
        display: flex;

        .avatar {
          width: 15px;
          height: 15px;
          margin-right: 2px;
          border-radius: 100%;
        }

        .time {
          flex: 1;
        }
      }

      .middle {
        display: flex;
        margin: 10px 0;
        color: black;
      }

      .hot-title {
        display: flex;
        align-items: center;
        font-size: 12px;

        .hot-sequence {
          display: inline-block;
          margin-right: 2px;
          padding: 3px 6px;
          border-radius: 2px;
          background-color: darkgoldenrod;
          color: white;
        }
      }

      .hot-content {
        margin-top: 15px;
      }
    }
  }
}
.product-tab ::v-deep .van-tabs__wrap {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}
</style>
