import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import {
  S3Client,
  PutObjectCommand,
  GetObjectCommand
} from '@aws-sdk/client-s3';
import * as config from './config/index';

// const awsConfig = config[process.env.NODE_ENV === 'production' ? 'production' : 'dev'];
const awsConfig = config['dev'];

const albumBucketName = awsConfig['albumBucketName'];
const region = awsConfig['region'];
const accessKeyId = awsConfig['accessKeyId'];
const secretAccessKey = awsConfig['secretAccessKey'];

export function parseFileString(file) {
  const dotIndex = file.lastIndexOf('.');
  return {
    name: file.slice(0, dotIndex),
    suffix: file.slice(dotIndex)
  };
}

// s3 文件保存根目录
export const dirPrefix = 'admintool/images';
// 访问地址
// https://tkio-test-cnbej-92867-dabhcpmicroseg-001.s3.cn-north-1.amazonaws.com.cn/images/
class S3Service {
  constructor() {
    this.s3 = new S3Client({
      region,
      credentials: {
        accessKeyId,
        secretAccessKey
      }
    });
  }
  get baseUrl() {
    return `https://${albumBucketName}.s3.${region}.amazonaws.com.cn`;
  }
  /** 获取访问地址 */
  async getFileUrl(path) {
    const command = new GetObjectCommand({
      Bucket: albumBucketName,
      Key: path
    });
    // Create the presigned URL.
    const signedUrl = await getSignedUrl(this.s3, command, {
      expiresIn: 3600
    });
    return signedUrl;
  }
  /** 创建目录 */
  async createDir(dirName, dirPath = '') {
    let albumKey = encodeURIComponent(dirPath);
    if (albumKey) {
      albumKey += '/';
    }
    dirName = dirName.trim();
    try {
      const key = albumKey + dirName + '/';
      const params = {
        Bucket: albumBucketName,
        Key: key
      };
      await this.s3.send(new PutObjectCommand(params));
      console.log('Successfully created album.');
    } catch (err) {
      console.error(err);
    }
  }
  /** 上传文件 */
  async upload(file, { dirPath = '', filename }) {
    try {
      const albumKey = dirPath + '/';
      const fileName = filename
        ? `${filename}${parseFileString(file.name).suffix}`
        : file.name;
      const photoKey = albumKey + fileName;
      const uploadParams = {
        Bucket: albumBucketName,
        Key: photoKey,
        Body: file
      };
      try {
        console.log(uploadParams, 'uploadParams');
        await this.s3.send(new PutObjectCommand(uploadParams));
        console.log('Successfully uploaded photo.');
        return {
          is_success: true,
          key: uploadParams.Key,
          message: '上传成功'
        };
      } catch (err) {
        console.error(err);
        return {
          is_success: false,
          message: err
        };
      }
    } catch (err) {
      console.error(err);
      return {
        is_success: false,
        message: err
      };
    }
  }
}
export const s3Service = new S3Service();
