<template>
  <div class="">
    <!-- <div class="online-tab-box">
      <div v-for="(tb, index) in tab" :key="index" :class="{'active': tb.active}" class="online-tab" @click="changeTabFn(index)">{{ tb.title }}</div>
    </div> -->
    <!-- <online v-if="activeTxt == 'new'" /> -->
    <onlineOther v-if="activeTxt == 'other'" />
  </div>
</template>
<script>
// import online from './online';
import onlineOther from './onlineOther';

export default {
  components: {
    // online,
    onlineOther
  },
  data() {
    return {
      // tab: [
      //   {
      //     title: '新视野',
      //     active: false,
      //     label: 'new'
      //   },
      //   {
      //     title: '其他公众号',
      //     active: true,
      //     label: 'other'
      //   }
      // ],
      activeTxt: 'other'
    };
  },
  methods: {
    changeTabFn(index) {
      // this.tab.forEach((ele, Index) => {
      //   ele.active = false;
      //   if (index === Index) {
      //     ele.active = true;
      //     this.activeTxt = ele.label;
      //   }
      // });
    }
  }
};
</script>
<style lang="scss" scoped>
  .online-tab-box {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    .online-tab {
      width: 43%;
      padding: 10px;
      text-align: center;
      border-radius: 4px;
      background-color: #F0F0F0;
      &.active {
        color: #ffffff;
        background-color: #0061bf;
      }
    }
  }
</style>
