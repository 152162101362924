import request from '@/utils/request';

export function getRelationData(physician_id, data, dTop) {
  let conditions = [];
  for (let key in data) { // 搜索条件
    if (key === 'network_type_full' && data[key]) {
      conditions.push(`network_type_full ne null and contains(network_type_full, '${data[key]}')`);
    }
  }
  let searchTxt = conditions.length ? 'and (' + conditions.join(' and ') + ')' : '';
  let top = dTop && dTop > 0 ? dTop : 20;
  return request({
    url: `/api/standard/odata/physicianNetworks?$filter=(physician_id eq '${physician_id}') ${searchTxt}&$orderby=network_score desc&$top=${top}`,
    method: 'get'
  });
}
export function getRelationContents(physician_id) {
  return request({
    url: `/api/standard/OData/physicianNetworkContents?$filter=(physician_id eq '${physician_id}')
    &$select=network_content_id,network_content_text,network_type,network_year,physician_name_cooperation,physician_id_cooperation`,
    method: 'get'
  });
}
// 获取networkType
export function getNetworkType(physician_id) {
  return request({
    url: `/api/standard/OData/vPhysicianNetworkTypes?$filter=(physician_id eq '${physician_id}') &$orderby=network_type_full`,
    method: 'get'
  });
}
export function getRelationDetail(physician_id, physician_id_cooperation) {
  return request({
    url: `/api/standard/OData/physicianNetworkContents?$filter=(physician_id eq '${physician_id}') and (physician_id_cooperation eq '${physician_id_cooperation}')
    &$select=network_content_id,network_content_text,network_type,network_year,physician_name_cooperation,physician_id_cooperation,institution_name_cooperation,department_name_cooperation`,
    method: 'get'
  });
}
// 全部关系
export function getAllRelation(physician_id, top) {
  return request({
    url: `/api/standard/OData/PhysicianNetworkStats?$filter=physician_id eq '${physician_id}' &$top=${top} &$orderby=network_score desc `,
    method: 'get'
  });
}
