<template>
  <van-collapse v-model="active" style="margin: 10px 0">
    <van-collapse-item :name="'item'">
      <template #title>
        <div class="bac-white">
          <div class="custom-item clearfix">
            <img src="./../../../assets/lundbeck/hospital.png" class="avatar" />
            <div class="custom-item-right">
              <div style="position: absolute; top: 24px">
                <div class="header clearfix">
                  <div class="name" style="flex: 1">
                    <span class="name-w -e-ellipsis-l1">{{
                      item.institution_name
                    }}</span>
                  </div>
                </div>
                <p class="department">
                  <span>{{ item.institution_tier }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #value>
        <div class="summary">医生<span class="number">{{ item.physician_no }}</span> 科室<span class="number">{{ item.department_no }}</span></div>
      </template>
      <template>
        <DepartmentListSection
          :institution-id="item.institution_id"
          style="margin-right: -20px"
        />
      </template>
    </van-collapse-item>
  </van-collapse>
</template>
<script>
import beforeFavoriteMixins from '@/mixins/beforeFavoriteMixins';
import Vue from 'vue';
import {Collapse, CollapseItem} from 'vant';
import DepartmentListSection from '@/views/hcp/departmentListSection.vue';

Vue.use(Collapse).use(CollapseItem);

export default {
  components: {DepartmentListSection},
  mixins: [beforeFavoriteMixins],
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    parting: {
      type: String,
      default() {
        return '';
      },
    },
    physicianSegment: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      active: [],
    };
  },
  methods: {
    clickItem(which, item) {
      this.$emit('clickItem', {item: item, action: which});
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/var.scss';
.box {
  border-top: none;
}
.custom-item {
  display: flex;
  align-items: center;
  // background-color: #ffffff;
  padding: 5px;
  // margin-bottom: 10px;
  .avatar {
    width: 50px;
    height: 50px;
  }
  .custom-item-right {
    flex: 1;
    font-size: 12px;
    text-align: left;
    padding-left: 10px;
    @include suggest-detail-header();
    overflow: hidden;
    .count {
      // float: right;
      font-size: 19px;
      color: #b2b2b2;
      .count-color {
        color: #3caa45;
        min-width: 32px;
      }
    }
    .name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name-w {
        // flex: 1;
        max-width: calc(100vw - 120px);
        font-size: 16px;
      }
    }
    .department {
      display: flex;
      justify-content: space-between;
      // font-size: 14px;
      padding: 6px 0 4px 0;
      .count-num {
        color: #0062b9;
        font-weight: bold;
        border-bottom: 1px solid #0062b9;
      }
    }
    .address {
      color: #b2b2b2;
      display: flex;
      flex-direction: row;
      // justify-content: space-between;
      .hospital {
        flex: 1;
        min-width: 0;
        width: 0;
        line-height: 20px;
        span {
          display: block;
        }
      }
      .cube-btn {
        // width: 84px;
        padding: 4px 8px;
        font-size: 11px;
        border-radius: 20px;
      }
    }
  }
}
.institution_tier {
  width: 80px;
  font-size: 12px;
}
.wrapper-item {
  border-radius: 10px;
  padding: 10px 0;
  border-radius: 5px;
  margin-top: 10px;
  &.top {
    border: 1px solid #80d3ae;
    background: #f1f7f5;
  }
  .score-more {
    display: block;
    font-size: 10px;
    transition: transform 0.3s;
    &.selected {
      transform: rotate(180deg);
    }
    .more-icon {
      color: #d9d9d9;
      font-size: 24px;
      font-weight: bold;
    }
  }
}
.summary {
  font-size: 12px;
  .number {
    font-size: 14px;
    color: #0061d0;
    margin-left: 4px;
    margin-right: 6px;
  }
}
.title {
  font-size: 14px;
  color: #000;
  text-align: left;
  padding: 10px;
}
::v-deep .cube-popup-content {
  width: 94%;
}

::v-deep .van-collapse-item__title {
  align-items: end;
}

::v-deep .van-collapse-item__content {
  padding-right: 0px;
  padding-top: 0px;
}

::v-deep .van-hairline--top-bottom::after {
  border-top-width: 0px;
}
</style>
